import maplibregl from "maplibre-gl";
import * as turf from '@turf/turf';
import FileUtils from "@/app/util/file-utils.mjs";
import DataUtils from "@/app/util/data-utils.mjs";

/**
 * メモサービス
 */
export default class BuildingService {

    /** レイヤープレフィックス */
    static LAYER_PREFIX = 'building-layer';
    /** ポップアップ用プレースホルダー */
    static POPUP_PLACEHOLDER = '<textarea readonly style="resize:none;height:120px;width:200px;padding:4px 4px;">${desc}</textarea>';

    /** 設定 */
    config = {
        limit: -1
    };
    /** コンポーネント */
    component = null;
    /** イベント */
    events = {};

    /** 表示フラグ */
    visibleBuilding = true;
    visibleMarker = true;
    visibleInfo = false;

    /** メモリスト */
    /**
     * ＜属性＞
     * name: （名称）,
     * desc: （説明）,
     * color: （中心色）,
     * lng: （中心点の経度）,
     * lat: （中心点の緯度）
     */
    buildings = [];

    /**
     * コンストラクタ
     * @param {Object} config 設定
     */
    constructor(config={}) {
        this.config = config||[];
    }

    /**
     * コンポーネント取得
     * @returns {Object} コンポーネント
     */
    getComponent() {
        return this.component;
    }

    /**
     * コンポーネント設定
     * @param {Object} component コンポーネント
     */
    setComponent(component) {
        this.component = component;
    }

    /**
     * イベント設定（地図クリック時）
     * @param {Object} map マップ
     * @param {Function} handler ハンドラ
     */
    onMapClickEvent(map, handler) {
        if (!handler || this.events['map-click']) {
            return;
        }
        let moving = false;
        function onTouchMove(event) {
            moving = true;
            event.preventDefault();
            return false;
        };
        function onTouchEnd(event) {
            if (!moving) {
                handler(event);
            }
            moving = false;
            return false;
        };
        this.events['map-click'] = {
            'click': handler,
            'touchmove': onTouchMove,
            'touchend': onTouchEnd
        };
        map.on('click', handler);
        map.on('touchmove', onTouchMove);
        map.on('touchend', onTouchEnd);
    }

    /**
     * イベント削除（地図クリック時）
     * @param {Object} map マップ
     * @param {Function} handler ハンドラ
     */
    offMapClickEvent(map) {
        if (!this.events['map-click']) {
            return;
        }
        for (const key in this.events['map-click']) {
            map.off(key, this.events['map-click'][key]);
        }
        this.events['map-click'] = null;
    }

    /**
     * メモポイント取得
     * @param {Object} map マップ
     * @param {String} id ID
     * @returns {Object} ポイント
     */
    getBuildingPoint(map, id) {
        const prefix = BuildingService.LAYER_PREFIX;
        const labelSourceId = prefix + '-label-' + id;

        // データ取得
        const data = map.getSource(labelSourceId)._data.features[0];

        return data;
    }

    /**
     * メモポイントリスト取得
     * @param {Object} map マップ
     * @returns {Array<Object>} ポイントリスト
     */
    getBuildingPoints(map) {
        const buildings = this.buildings;

        // データ取得
        const points = [];
        for (const building of buildings) {
            points.push(this.getBuildingPoint(map, building.id));
        }

        return {
            type: 'FeatureCollection',
            features: points
        };
    }

    /**
     * メモ取得
     * @param {String} id ID
     * @returns {Object} メモ
     */
    getBuildingById(id) {
        for (const building of this.buildings||[]) {
            if (building.id === id) {
                return building;
            }
        }
        return null;
    }

    /**
     * メモリスト取得
     * @returns {Array<Object>} メモリスト
     */
    getBuildings() {
        return this.buildings;
    }

    /**
     * メモリスト（エクスポート用）取得
     * @returns {Array<Object>} メモリスト
     */
    getBuildingsForExport() {
        const me = this;
        const buildings = me.buildings;
        const datas = [];
        for (const building of buildings) {
            const data = {};
            for (const key in building) {
                if (key == 'extend' || key == 'id') {
                    continue;
                }
                data[key] = building[key];
            }
            datas.push(data);
        }
        return datas;
    }

    /**
     * メモ設定
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    setBuildings(map, buildings) {
        const me = this;
        me.removeBuildings(map, me.buildings);
        me.addBuildings(map, buildings);
    }

    /**
     * メモ追加
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    addBuilding(map, building) {
        const me = this;

        // サイズ制限制御
        if (me.config.limit >= 0 && me.buildings.length >= me.config.limit) {
            return;
        }

        // パラメータ補正
        building.id = DataUtils.generateId();

        // メモ追加
        const result = me.addBuildingLayer(map, building);

        // データ追加
        building.extend = {
            marker: result.marker
        };
        me.buildings.push(building);
    }

    /**
     * メモ追加
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    addBuildings(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // メモ追加
        for (const building of buildings) {
            this.addBuilding(map, building);
        }
    }

    /**
     * メモ削除
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    removeBuilding(map, building) {
        const records = this.buildings;

        // 空判定
        if (!building) {
            return;
        }

        // メモレイヤー削除
        this.removeBuildingLayer(map, building);

        // メモ削除
        for (let row = records.length - 1; row >= 0; row--) {
            const record = records[row];
            if (record.id == building.id) {
                records.splice(row, 1);
                break;
            }
        }
    }

    /**
     * メモ削除
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    removeBuildings(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // メモ削除
        for (let i = buildings.length - 1; i >= 0; i--) {
            const building = buildings[i];
            this.removeBuilding(map, building);
        }
    }

    /**
     * データ更新
     * @param {Object} map マップ
     * @param {String} id ID
     * @param {Object} building メモ
     */
    updateBuilding(map, id, building) {
        const prefix = BuildingService.LAYER_PREFIX;

        // 空判定
        if (!id || !building) {
            return;
        }

        // メモ更新
        const data = this.getBuildingById(id);
        for (const key in building) {
            data[key] = building[key];
        }

        // メモ地図情報更新
        if (building.name) {
            const source = map.getSource(prefix + '-label-' + id);
            if (source) {
                const feature = source._data.features[0];
                feature.properties['name'] = building.name;
                source.setData({
                    "type": "FeatureCollection",
                    "features": [feature]
                });
            }
        }

        // ポップアップ更新
        if (building.desc) {
            const popup = data.extend.marker.getPopup();
            if (popup) {
                popup.setHTML(BuildingService.POPUP_PLACEHOLDER.replace('${desc}', (building.desc||'（なし）')));
            }
        }

        // 中心マーカー更新
        if (building.color) {
            const markerElement = data.extend.marker.getElement();
            if (markerElement) {
                markerElement
                    .querySelectorAll('svg g[fill="' +  data.extend.marker._color + '"]')[0]
                    .setAttribute("fill", '#' + building.color);
                data.extend.marker._color = '#' + building.color;
                if (map.getLayer(prefix + '-symbol-' + id, 'circle-color', 'visibility')) {
                    map.setPaintProperty(prefix + '-symbol-' + id, 'circle-color', '#' + building.color);
                }
            }
        }
    }

    /**
     * メモレイヤー追加
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    addBuildingLayer(map, building) {
        const me = this;
        const prefix = BuildingService.LAYER_PREFIX;

        // 空判定
        if (!building) {
            return;
        }

        // パラメータ取得
        const id = building.id;
        const name = building.name;
        const color = building.color;
        const lng = building.lng;
        const lat = building.lat;

        // ラベルレイヤ作成
        const labelSourceId = prefix + '-label-' + id;
        const labelLayerId = labelSourceId;
        if (!map.getSource(labelSourceId)) {
            const point = me.createLabel(lng, lat, name);
            map.addSource(labelSourceId, {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": [point]
                }
            });
        }
        map.addLayer({
            'id': labelLayerId,
            'type': 'symbol',
            'source': labelSourceId,
            'paint': {
                'text-color': '#000000',
                'text-halo-color': '#ffffff',
                'text-halo-width': 2
            },
            'layout': {
                'text-field': ['get', 'name'],
                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                'text-radial-offset': 0.5,
                'text-justify': 'center',
                'text-overlap': 'always',
                'text-allow-overlap': true
            }
        });

        // 中心マーカー作成
        const symbolSourceId = prefix + '-symbol-' + id;
        const symbolLayerId = symbolSourceId;
        map.addSource(symbolSourceId, {
            type: "geojson",
            data: {
                type: "Point",
                coordinates: [lng, lat]
            }
        });
        map.addLayer({
            id: symbolLayerId,
            source: symbolSourceId,
            type: "circle",
            paint: {
                "circle-radius": 8,
                "circle-color": color ? `#${color}` : "#FF0000",
                "circle-stroke-width": 1,
                "circle-blur": 0.5
            }
        });
        const marker = me.addBuildingMarker(map, building);

        return {
            marker: marker
        };
    }

    /**
     * メモレイヤー追加
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    addBuildingLayers(map, buildings) {
        const me = this;

        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // メモ追加
        for (const building of buildings) {
            const result = me.addBuildingLayer(map, building);
            if (building.extend.marker) {
                building.extend.marker.getPopup().remove();
                building.extend.marker.remove();
            }
            building.extend = {
                marker: result.marker
            };
        }
    }

    /**
     * メモレイヤー削除
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    removeBuildingLayer(map, building) {
        const prefix = BuildingService.LAYER_PREFIX;

        // 空判定
        if (!building) {
            return;
        }

        // メモレイヤー削除
        if (map.getLayer(prefix + '-label-' + building.id)) {
            map.removeLayer(prefix + '-label-' + building.id);
        }
        if (map.getLayer(prefix + '-symbol-' + building.id)) {
            map.removeLayer(prefix + '-symbol-' + building.id);
        }
        if (map.getSource(prefix + '-label-' + building.id)) {
            map.removeSource(prefix + '-label-' + building.id);
        }
        if (map.getSource(prefix + '-symbol-' + building.id)) {
            map.removeSource(prefix + '-symbol-' + building.id);
        }

        // 補正
        if (building.extend.marker) {
            building.extend.marker.getPopup().remove();
            building.extend.marker.remove();
        }
    }

    /**
     * メモレイヤー削除
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    removeBuildingLayers(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // メモレイヤー削除
        for (let i = buildings.length - 1; i >= 0; i--) {
            const building = buildings[i];
            this.removeBuildingLayer(map, building);
        }
    }

    /**
     * マーカー追加
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    addBuildingMarker(map, building) {
        const me = this;
        const prefix = BuildingService.LAYER_PREFIX;

        // 空判定
        if (!building) {
            return;
        }

        // パラメータ取得
        const id = building.id;
        const name = building.name;
        const desc = building.desc;
        const color = building.color;
        const lng = building.lng;
        const lat = building.lat;

        // マーカー追加
        const marker = new maplibregl.Marker({
                color: color ? `#${color}` : "#FF0000",
                draggable: true
            })
            .setLngLat([lng, lat])
            .addTo(map);

        // ポップアップ設定
        const popup = new maplibregl.Popup({ closeOnClick: true })
            .setLngLat([0, 0])
            .setHTML(BuildingService.POPUP_PLACEHOLDER.replace('${desc}', (desc||'（なし）')));
        marker.setPopup(popup);

        // マーカークリック時にポップアップを表示し、地図のクリックイベントを無効化
        marker.getElement().addEventListener('click', (e) => {
            e.stopPropagation(); // イベントの伝播を停止
            popup.setLngLat(marker.getLngLat()).addTo(map);
        });

        // ドラッグ時制御
        marker.on('drag', function(event) {
            const lng = event.target._lngLat.lng;
            const lat = event.target._lngLat.lat;
            building.lng = lng;
            building.lat = lat;
            for (const key in map.style._layers) {
                if (key.startsWith(prefix + '-label' + '-' + id)) {
                    const data = map.getSource(key)._data.features[0];
                    const label = me.createLabel(lng, lat, data.properties.name);
                    map.getSource(key).setData({
                        "type": "FeatureCollection",
                        "features": [label]
                    });
                }
                if (key.startsWith(prefix + '-symbol' + '-' + id)) {
                    map.getSource(key).setData({
                        type: "Point",
                        coordinates: [lng, lat]
                    });
                }
            }
            return false;
        });

        return marker;
    }

    /**
     * マーカー追加
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    addBuildingMarkers(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // マーカー追加
        for (const building of buildings) {
            // マーカー追加
            const marker = this.addBuildingMarker(map, building);

            // 補正
            if (building.extend.marker) {
                building.extend.marker.getPopup().remove();
                building.extend.marker.remove();
            }
            building.extend = {
                marker: marker
            };
        }
    }

    /**
     * マーカー削除
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    removeBuildingMarker(map, building) {
        // 空判定
        if (!building) {
            return;
        }

        // マーカー削除
        if (building.extend.marker) {
            building.extend.marker.getPopup().remove();
            building.extend.marker.remove();
        }
    }

    /**
     * マーカー削除
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    removeBuildingMarkers(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // マーカー削除
        for (let i = buildings.length - 1; i >= 0; i--) {
            const building = buildings[i];
            this.removeBuildingMarker(map, building);
        }
    }

    /**
     * ポップアップ追加
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    addBuildingPopup(map, building) {
        // 空判定
        if (!building) {
            return;
        }

        // ポップアップ追加
        if (building.extend.marker) {
            const popup = building.extend.marker.getPopup();
            if (popup && !popup.isOpen()) {
                popup.addTo(map);
            }
        }
    }

    /**
     * ポップアップ追加
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    addBuildingPopups(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // ポップアップ追加
        for (const building of buildings) {
            this.addBuildingPopup(map, building);
        }
    }

    /**
     * ポップアップ削除
     * @param {Object} map マップ
     * @param {Object} building メモ
     */
    removeBuildingPopup(map, building) {
        // 空判定
        if (!building) {
            return;
        }

        // ポップアップ削除
        if (building.extend.marker) {
            const popup = building.extend.marker.getPopup();
            if (popup && popup.isOpen()) {
                popup.remove();
            }
        }
    }

    /**
     * ポップアップ削除
     * @param {Object} map マップ
     * @param {Array<Object>} buildings メモリスト
     */
    removeBuildingPopups(map, buildings) {
        // 空判定
        if (!buildings || buildings.length == 0) {
            return;
        }

        // ポップアップ削除
        for (let i = buildings.length - 1; i >= 0; i--) {
            const building = buildings[i];
            this.removeBuildingPopup(map, building);
        }
    }

    /**
     * メモ表示変更
     * @param {Object} map マップ
     * @param {Boolean} visible 表示フラグ
     */
    changeVisibleBuilding(map, visible) {
        const me = this,
            buildings = me.buildings;
        if (visible) {
            me.addBuildingLayers(map, buildings);
            me.changeVisibleMarker(map, true);
            me.changeVisibleInfo(map, false);
        } else {
            me.changeVisibleInfo(map, false);
            me.changeVisibleMarker(map, false);
            me.removeBuildingLayers(map, buildings);
        }
        me.visibleBuilding = visible;
    }

    /**
     * マーカー表示変更
     * @param {Object} map マップ
     * @param {Boolean} visible 表示フラグ
     */
    changeVisibleMarker(map, visible) {
        const me = this,
            buildings = me.buildings;
        if (visible) {
            me.addBuildingMarkers(map, buildings);
            me.changeVisibleInfo(map, false);
        } else {
            me.changeVisibleInfo(map, false);
            me.removeBuildingMarkers(map, buildings);
        }
        me.visibleMarker = visible;
    }

    /**
     * 情報表示変更
     * @param {Object} map マップ
     * @param {Boolean} visible 表示フラグ
     */
    changeVisibleInfo(map, visible) {
        const me = this,
            buildings = me.buildings;
        if (visible) {
            me.addBuildingPopups(map, buildings);
        } else {
            me.removeBuildingPopups(map, buildings);
        }
        me.visibleInfo = visible;
    }

    /**
     * インポート処理
     * @param {Object} map マップ
     * @param {Object} file ファイル
     * @param {Function} handler ハンドラ
     */
    importFile(map, file, handler) {
        const me = this;

        FileUtils.importFileText(file, function(content) {
            const datas = JSON.parse(content);
            for (const data of datas) {
                me.addBuilding(map, data);
            }

            // ハンドラ処理
            if (handler) {
                handler(me.buildings);
            }
        });
    }

    /**
     * エクスポート処理
     * @param {Object} map マップ
     */
    exportFile(map) {
        const datas = this.getBuildingsForExport();
        FileUtils.exportFileText(JSON.stringify(datas), 'buildings.json');
    }

    /**
     * @private
     * ラベル作成
     * @param {Number} lon 経度
     * @param {Number} lat 緯度
     * @param {String} name 名称
     */
    createLabel(lng, lat, name) {
        const center = [lng, lat];
        const point = turf.point(center, { lng: lng, lat: lat, name: name });
        return point;
    }
};
