<template>
    <UxWorkspace :visible="visible">
        <template #content>
            <DataPanel v-if="menuSelectedMode === 'data'" ref="dataPanel"/>
            <LayerPanel v-else-if="menuSelectedMode === 'layer'" ref="layerPanel"/>
            <ZonePanel v-else-if="menuSelectedMode === 'zone'" ref="zonePanel"/>
            <BuildingPanel v-else-if="menuSelectedMode === 'building'" ref="buildingPanel"/>
            <MeasurePanel v-else-if="menuSelectedMode === 'measure'" ref="measurePanel"/>
            <PrintPanel v-else-if="menuSelectedMode === 'print'" ref="printPanel"/>
            <div v-else>無し</div>
        </template>
    </UxWorkspace>
</template>

<script>
    import UxWorkspace from "@/ux/frame/UxWorkspace";

    import DataPanel from "@/views/workspace/DataPanel";
    import LayerPanel from "@/views/workspace/LayerPanel";
    import ZonePanel from "@/views/workspace/ZonePanel";
    import BuildingPanel from "@/views/workspace/BuildingPanel";
    import MeasurePanel from "@/views/workspace/MeasurePanel";
    import PrintPanel from "@/views/workspace/PrintPanel";

    export default {
        components: {
            UxWorkspace,
            DataPanel,
            LayerPanel,
            ZonePanel,
            BuildingPanel,
            MeasurePanel,
            PrintPanel
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            menuSelectedMode: {
                type: String,
                default: null
            }
        },
        data() {
            return {
            };
        },
        watch: {
            menuSelectedMode(newValue) {
                const me = this;
                // 領域
                if (!newValue || newValue != 'zone') {
                    me.$Service.ZoneService.offMapClickEvent(me.$MapConfig.map);
                }
                // メモ
                if (!newValue || newValue != 'building') {
                    me.$Service.BuildingService.offMapClickEvent(me.$MapConfig.map);
                }
                // 計測
                if (!newValue || newValue != 'measure') {
                    me.$Service.MeasureService.offMapDistanceAction(me.$MapConfig.map);
                    me.$Service.MeasureService.offMapAreaAction(me.$MapConfig.map);
                }
            }
        },
        computed: {
        },
        methods: {
        }
    };
</script>

<style scoped>
</style>