
<template>
    <Dialog modal v-bind:visible="visible" header="ファイル選択" :style="{ width: '50vw', height: '70vh' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div class="card" style="height:58vh">
            <FileUpload
                v-bind:multiple="multiple"
                v-bind:accept="accept"
                v-bind:maxFileSize="maxFileSize"
                :auto="true"
                @select="onSelectedFiles"
                >
                <template #header="{ chooseCallback }">
                    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="flex gap-2">
                            <Button type="button" label="選択" icon="pi pi-file" @click="chooseCallback" severity="primary" outlined />
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="flex align-items-center justify-content-center flex-column">
                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                        <p class="mt-4 mb-0">画像ファイルをドラッグ＆ドロップまたは選択してください。</p>
                    </div>
                </template>
            </FileUpload>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';
    import FileUpload from 'primevue/fileupload';

    export default {
        components: {
            Dialog,
            Button,
            FileUpload
        },
        data() {
            return {
                files: [],
                totalSize: 0,
                totalSizePercent: 0,
            };
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            accept: {
                type: String,
                default: "application/json"
            },
            maxFileSize: {
                type: Number,
                default: 104857600
            }
        },
        emits: ['change-visible','upload-file'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "ファイル選択"イベント時処理
             */
            onSelectedFiles(event) {
                this.files = event.files;
                this['$emit']('upload-file', this.files);
            },
        }
    };
</script>


<style scoped>
</style>