/**
 * ファイルユーティリティ
 */
export default class FileUtils {

    /**
     * ファイルインポート（テキスト）
     * @param {File} file ファイル
     * @param {Function} handler ハンドラ
     */
    static importFileText(file, handler) {
        const reader  = new FileReader();
        reader.addEventListener('load', function(event) {
            const content = event.target.result;
            handler(content);
        });
        reader.readAsText(file);
    }

    /**
     * ファイルエクスポート（テキスト）
     * @param {String} contents 内容
     * @param {String} filename ファイル名
     */
    static exportFileText(contents, filename=null) {
        const blob = new Blob([contents], {type: 'text/plain'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        if (filename) {
            a.download = filename;
        }
        a.href = url;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    }
};
