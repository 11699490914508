import maplibregl from "maplibre-gl";
import * as turf from '@turf/turf';
import FileUtils from "@/app/util/file-utils.mjs";
import DataUtils from "@/app/util/data-utils.mjs";
import GeometryUtils from "@/app/util/geometry-utils.mjs";

/**
 * データソースサービス
 */
export default class DataSourceService {

    /** レイヤープレフィックス */
    static LAYER_PREFIX = 'datasource-layer';

    /**
     * ジオメトリ種別
     */
    /** ポリゴン */
    static GT_POLYGON = 'polygon';
    /** ポイント */
    static GT_POINT = 'point';
    /** ライン */
    static GT_LINE = 'line';

    /**
     * データソース種別
     */
    /** 自由入力 */
    static DT_FREE = 'free';
    /** Geojson */
    static DT_GEOJSON = 'geojson';
    /** Geojson（API） */
    static DT_GEOJSON_API = 'geojson-api';
    /** MVT形式 */
    static DT_MVT = 'mvt';


    /** 設定 */
    config = {
        limit: -1
    };
    /** コンポーネント */
    component = null;
    /** イベント */
    events = {};

    /** データソースリスト */
    /**
     * ＜属性＞
     * id: （ID）,
     * name: （名称）,
     * desc: （説明）,
     * geomeType: （ジオメトリ種別：polygon, point, line）,
     * dataType: （データ種別：geojsonのみ）,
     * dataSourceType: （データソース種別：:geojson, mvt, ...）,
     * attributes: （データ属性：[{type:'string', key:'title'}, {type:'number', key:'data1'}]）,
     * data: （※データ内容）
     */
    dataSources = [];

    /**
     * コンストラクタ
     * @param {Object} config 設定
     */
    constructor(config={}) {
        this.config = config||[];
    }

    /**
     * コンポーネント取得
     * @returns {Object} コンポーネント
     */
    getComponent() {
        return this.component;
    }

    /**
     * コンポーネント設定
     * @param {Object} component コンポーネント
     */
    setComponent(component) {
        this.component = component;
    }

    /**
     * データソース取得
     * @param {String} id ID
     * @returns {Object} データソース
     */
    getDataSourceById(id) {
        for (const dataSource of this.dataSources||[]) {
            if (dataSource.id === id) {
                return dataSource;
            }
        }
        return null;
    }

    /**
     * データソースリスト取得
     * @returns {Array<Object>} データソースリスト
     */
    getDataSources() {
        return this.dataSources;
    }

    /**
     * データソースリスト（エクスポート用）取得
     * @returns {Array<Object>} データソースリスト
     */
    getDataSourcesForExport() {
        const me = this;
        const dataSources = me.dataSources;
        const datas = [];
        for (const dataSource of dataSources) {
            const data = {};
            for (const key in dataSource) {
                data[key] = dataSource[key];
            }
            datas.push(data);
        }
        return datas;
    }

    /**
     * データソース設定
     * @param {Object} map マップ
     * @param {Array<Object>} dataSources データソースリスト
     */
    setDataSources(map, dataSources) {
        this.removeDataSources(map, this.dataSources);
        this.addDataSources(map, dataSources);
    }

    /**
     * データソース追加
     * @param {Object} map マップ
     * @param {Object} dataSource データソース
     */
    addDataSource(map, dataSource) {
        // サイズ制限制御
        if (this.config.limit >= 0 && this.dataSources.length >= this.config.limit) {
            return;
        }

        // パラメータ補正
        dataSource.id = dataSource.id||DataUtils.generateId();

        // データ追加
        this.dataSources.push(dataSource);

        // 地図データ追加
        this.setMapDataSource(map, dataSource);
    }

    /**
     * データソース追加
     * @param {Object} map マップ
     * @param {Array<Object>} dataSources データソースリスト
     */
    addDataSources(map, dataSources) {
        // 空判定
        if (!dataSources || dataSources.length == 0) {
            return;
        }

        // データソース追加
        for (const dataSource of dataSources) {
            this.addDataSource(map, dataSource);
        }
    }

    /**
     * データソース削除
     * @param {Object} map マップ
     * @param {Object} dataSource データソース
     */
    removeDataSource(map, dataSource) {
        const records = this.dataSources;

        // 空判定
        if (!dataSource) {
            return;
        }

        // データソース削除
        for (let row = records.length - 1; row >= 0; row--) {
            const record = records[row];
            if (record.id == dataSource.id) {
                records.splice(row, 1);
                break;
            }
        }

        // 地図データ削除
        this.removeMapDataSource(map, dataSource);
    }

    /**
     * データソース削除
     * @param {Object} map マップ
     * @param {Array<Object>} dataSources データソースリスト
     */
    removeDataSources(map, dataSources) {
        // 空判定
        if (!dataSources || dataSources.length == 0) {
            return;
        }

        // データソース削除
        for (let i = dataSources.length - 1; i >= 0; i--) {
            const dataSource = dataSources[i];
            this.removeDataSource(map, dataSource);
        }
    }

    /**
     * データ更新
     * @param {Object} map マップ
     * @param {String} id ID
     * @param {Object} dataSource データソース
     */
    updateDataSource(map, id, dataSource) {
        // 空判定
        if (!id || !dataSource) {
            return;
        }

        // データソース更新
        const data = this.getDataSourceById(id);
        for (const key in dataSource) {
            data[key] = dataSource[key];
        }

        // 地図データ設定
        this.setMapDataSource(map, dataSource);
    }

    /**
     * データ取得
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {String} id ID
     * @returns {Object} データ
     */
    getData(map, sourceId, id) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return [];
        }

        // データ取得
        for (const data of dataSource.data.features) {
            if (data.properties.id === id) {
                return data;
            }
        }
        return null;
    }

    /**
     * データ取得
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @returns {Array<Object>} データ
     */
    getDatas(map, sourceId) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return [];
        }

        return dataSource.data.features;
    }

    /**
     * データ追加
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Object} data データ
     */
    addData(map, sourceId, data) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return;
        }

        // 編集可能か判定
        if (!this.isEditableDataSource(dataSource)) {
            return;
        }

        // フィーチャーリスト取得
        const features = dataSource.data.features;

        // サイズ制限制御
        if (this.config.limit >= 0 && (features||[]).length >= this.config.limit) {
            return;
        }

        // パラメータ補正
        data.properties.id = data.properties.id||(DataUtils.generateId());
        this.castData(map, dataSource.attributes, data);

        // データ追加
        features.push(data);

        // 地図データ設定
        this.setMapDataSource(map, dataSource);
    }

    /**
     * データ追加
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Array<Object>} datas データリスト
     */
    addDatas(map, sourceId, datas) {
        // 空判定
        if (!datas || datas.length == 0) {
            return;
        }

        // データ追加
        for (const data of datas) {
            this.addData(map, sourceId, data);
        }
    }

    /**
     * データ削除
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Object} data データ
     */
    removeData(map, sourceId, data) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return;
        }

        // 編集可能か判定
        if (!this.isEditableDataSource(dataSource)) {
            return;
        }

        // データ取得
        const features = dataSource.data.features;

        // 空判定
        if (!data) {
            return;
        }

        // データ削除
        for (let row = features.length - 1; row >= 0; row--) {
            const feature = features[row];
            if (feature.properties.id == data.properties.id) {
                features.splice(row, 1);
                break;
            }
        }

        // 地図データ設定
        this.setMapDataSource(map, dataSource);
    }

    /**
     * データ削除
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Array<Object>} datas データリスト
     */
    removeDatas(map, sourceId, datas) {
        // 空判定
        if (!datas || datas.length == 0) {
            return;
        }

        // データ削除
        for (let i = datas.length - 1; i >= 0; i--) {
            const data = datas[i];
            this.removeData(map, sourceId, data);
        }
    }

    /**
     * データ更新
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Object} data データ
     */
    updateData(map, sourceId, data) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return;
        }

        // 編集可能か判定
        if (!this.isEditableDataSource(dataSource)) {
            return;
        }

        // データ取得
        const features = dataSource.data.features;

        // 空判定
        if (!data) {
            return;
        }

        // データ更新
        this.castData(map, dataSource.attributes, data);
        for (const feature of features) {
            if (feature.properties.id == data.properties.id) {
                for (const key in data.properties) {
                    feature.properties[key] = data.properties[key];
                }
                break;
            }
        }

        // 地図データ設定
        this.setMapDataSource(map, dataSource);
    }

    /**
     * データの地図位置に移動
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Object} data データ
     */
    panToData(map, sourceId, data) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return;
        }

        // 座標存在判定
        if (!data.geometry.coordinates || data.geometry.coordinates.length == 0) {
            return;
        }

        // GeoJSONから境界ボックスを計算する
        const bbox = GeometryUtils.getBbox(GeometryUtils.geojsonToGeometry(JSON.stringify(data)));

        // 地図を境界ボックスにフィットさせる
        map.fitBounds([
                [bbox.minX, bbox.minY], // 南西の座標 (longitude, latitude)
                [bbox.maxX, bbox.maxY]  // 北東の座標 (longitude, latitude)
            ], {
            padding: {top: 25, bottom: 25, left: 15, right: 15}, // ビューポートのパディングを設定
            animate: true // アニメーション効果を有効にする
        });
    }

    /**
     * データレイヤー表示
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Boolean} visible 表示
     */
    changeVisibleDataSourceLayer(map, sourceId, visible) {
        // データソース取得
        const dataSource = this.getDataSourceById(sourceId);

        // データソース判定
        if (!dataSource) {
            return;
        }

        // レイヤー設定
        this.setMapDataSource(map, dataSource);

        // レイヤー表示
        map.setLayoutProperty(`${DataSourceService.LAYER_PREFIX}-${sourceId}`, 'visibility', visible ? 'visible' : 'none');
    }

    /**
     * 地図データソース設定
     * @param {Object} map マップ
     * @param {Object} dataSource データソース
     */
    setMapDataSource(map, dataSource) {
        const layerId = `${DataSourceService.LAYER_PREFIX}-${dataSource.id}`;

        // データソース設定
        const mapSource = map.getSource(layerId);
        if (!mapSource) {
            // 追加
            switch (dataSource.dataSourceType) {
                case DataSourceService.DT_FREE:
                    map.addSource(layerId, {
                        'type': 'geojson',
                        'data': dataSource.data
                    });
                    break;
                case DataSourceService.DT_GEOJSON:
                    map.addSource(layerId, {
                        'type': 'geojson',
                        'data': dataSource.data
                    });
                    break;
                case DataSourceService.DT_GEOJSON_API:
                    map.addSource(layerId, {
                        'type': 'geojson',
                        'data': dataSource.data.requestUrl
                    });
                    break;
                default:
                    break;
            }
        } else {
            // 更新
            switch (dataSource.dataSourceType) {
                case DataSourceService.DT_FREE:
                    mapSource.setData({
                        "type": "FeatureCollection",
                        "features": dataSource.data.features
                    });
                    break;
                case DataSourceService.DT_GEOJSON:
                    mapSource.setData({
                        "type": "FeatureCollection",
                        "features": dataSource.data.features
                    });
                    break;
                case DataSourceService.DT_GEOJSON_API:
                    mapSource.setData({
                        'type': 'geojson',
                        'data': dataSource.data.requestUrl
                    });
                    break;
                default:
                    break;
            }
        }

        // レイヤ設定
        this.setMapDataLayer(map, dataSource);
    }

    /**
     * 地図データソース削除
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     */
    removeMapDataSource(map, sourceId) {
        const layerId = `${DataSourceService.LAYER_PREFIX}-${sourceId}`;

        // レイヤ削除
        this.remoeveMapDataLayer(map, sourceId);

        // データソース削除
        const mapSource = map.getSource(layerId);
        if (mapSource) {
            map.removeSource(layerId);
        }
    }

    /**
     * 地図データレイヤ設定
     * @param {Object} map マップ
     * @param {Object} dataSource データソース
     */
    setMapDataLayer(map, dataSource) {
        const layerId = `${DataSourceService.LAYER_PREFIX}-${dataSource.id}`;
        this.remoeveMapDataLayer(map, dataSource.id);
        switch (dataSource.geomType) {
            case DataSourceService.GT_POLYGON:
                {
                    map.addLayer({
                        'id': layerId,
                        'source': layerId,
                        "type": "fill",
                        "paint": {
                            "fill-color": "#fbb03b",
                            "fill-outline-color": "#fbb03b",
                            "fill-opacity": 0.5
                        },
                        "layout": {
                            "visibility": "none"
                        }
                    });
                }
                break;
            case DataSourceService.GT_POINT:
                {
                    map.addLayer({
                        'id': layerId,
                        'source': layerId,
                        "type": "circle",
                        "paint": {
                            "circle-radius": 3,
                            "circle-color": "#fbb03b"
                        },
                        "layout": {
                            "visibility": "none"
                        }
                    });
                }
                break;
            case DataSourceService.GT_LINE:
                {
                    map.addLayer({
                        'id': layerId,
                        'source': layerId,
                        "type": "line",
                        "paint": {
                            "line-color": "#fbb03b",
                            "line-opacity": 1.0,
                            "line-dasharray": [1, 1],
                            "line-width": 2
                        },
                        "layout": {
                            "visibility": "none"
                        }
                    });
                }
                break;
            default:
                break;
        }
    }

    /**
     * 地図データレイヤ削除
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     */
    remoeveMapDataLayer(map, sourceId) {
        const layerId = `${DataSourceService.LAYER_PREFIX}-${sourceId}`;
        if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
        }
    }

    /**
     * インポート処理
     * @param {Object} map マップ
     * @param {Object} file ファイル
     * @param {Function} handler ハンドラ
     */
    importFile(map, file, handler) {
        const me = this;

        FileUtils.importFileText(file, function(content) {
            const datas = JSON.parse(content);
            for (const data of datas) {
                me.addDataSource(map, data);
            }

            // ハンドラ処理
            if (handler) {
                handler(me.dataSources);
            }
        });
    }

    /**
     * データインポート処理
     * @param {Object} map マップ
     * @param {String} sourceId データソースID
     * @param {Object} file ファイル
     * @param {Function} handler ハンドラ
     */
    importDataFile(map, sourceId, file, handler) {
        const me = this;
        const dataSource = this.getDataSourceById(sourceId);

        FileUtils.importFileText(file, function(content) {
            const datas = JSON.parse(content);
            if (datas.geomType != dataSource.geomType) {
                return;
            }
            me.addDatas(map, sourceId, datas.features);

            // ハンドラ処理
            if (handler) {
                handler(dataSource);
            }
        });
    }

    /**
     * エクスポート処理
     */
    exportFile() {
        const dataSources = this.getDataSourcesForExport();
        FileUtils.exportFileText(JSON.stringify(dataSources), 'dataSources.json');
    }

    /**
     * データエクスポート処理
     * @param {String} sourceId データソースID
     */
    exportDataFile(sourceId) {
        const dataSource = this.getDataSourceById(sourceId);
        FileUtils.exportFileText(JSON.stringify(dataSource.data), 'data.json');
    }

    /**
     * FeatureCollection作成
     * @param {Array<Object>} features フィーチャーリスト
     * @returns {Object} FeatureCollection
     */
    createFeatureCollection(features=[]) {
        return {
            "type": "FeatureCollection",
            "features": features
        }
    }

    /**
     * Feature作成
     * @param {String} geomType ジオメトリ種別
     * @param {Array<Object>} properties プロパティ
     * @param {Array<Object>} features フィーチャーリスト
     * @returns {Object} Feature
     */
    createFeature(geomType, properties={}, coordinates=[]) {
        let type = null;
        switch (geomType) {
            case DataSourceService.GT_POLYGON: type = 'Polygon'; break;
            case DataSourceService.GT_POINT: type = 'Point'; break;
            case DataSourceService.GT_LINE: type = 'LineString'; break;
            default: break;
        }
        return {
            "type": "Feature",
            "properties": properties,
            "geometry": {
                "type": type,
                "coordinates": coordinates
            }
        };
    }

    /**
     * 編集可能なデータソースか判定
     * @param {Object} dataSource データソース
     * @returns {Boolean} true or false
     */
    isEditableDataSource(dataSource) {
        return dataSource.dataSourceType === DataSourceService.DT_FREE;
    }

    /**
     * データキャスト
     * @param {Object} map マップ
     * @param {Array<Object>} attributes 属性
     * @param {Object} data データ
     */
    castData(map, attributes, data) {
        for (const attribute of attributes) {
            let value = data.properties[attribute.key];
            if (value === undefined) {
                continue;
            }
            switch (attribute.type) {
                case 'string':
                    value = String(value);
                    break;
                case 'number':
                    value = Number(value);
                    break;
                default:
                    break;
            }
            data.properties[attribute.key] = value;
        }
    }

    /**
     * ジオメトリ種別ラベル取得
     * @param {String} geomType ジオメトリ種別
     * @returns {String} ジオメトリ種別ラベル
     */
    getGeomTypeLabel(geomType) {
        switch (geomType) {
            case DataSourceService.GT_POLYGON: return 'ポリゴン';
            case DataSourceService.GT_POINT: return 'ポイント';
            case DataSourceService.GT_LINE: return 'ライン';
            default: break;
        }
        return null;
    }

    /**
     * データソース種別ラベル取得
     * @param {String} dataSourceType データソース種別
     * @returns {String} データソース種別ラベル
     */
    getDataSourceTypeLabel(dataSourceType) {
        switch (dataSourceType) {
            case DataSourceService.DT_FREE: return '自由入力';
            case DataSourceService.DT_GEOJSON: return 'Geojson';
            case DataSourceService.DT_GEOJSON_API: return 'Geojson（API）';
            case DataSourceService.DT_MVT: return 'MVT形式';
            default: break;
        }
        return null;
    }
};
