<script>
    import TabView from "primevue/tabview"

    export default {
        extends: TabView,
        name: 'ex-tabview',
        render: TabView.render,
        props: {
        }
    };
</script>

<style scoped>
    .p-tabview-panels {
        margin: 0px !important;
        padding: 0px !important;
    }
</style>
