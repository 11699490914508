<template>
    <UxNavigation :visible="visible" :navigationItems="navigationItems" :expandedKeys="expandedKeys">
        <template #header>
            <div class="card flex justify-content-center gap-2">
                <Button v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onCloseNavigation" severity="secondary" outlined />
                <UxLogo :src="'/assets/image/logo.png'" :height="'40'" class="p-1" />
            </div>
        </template>
    </UxNavigation>

    <UxFileWindow :visible="importFileWindowVisible" v-on:change-visible="onImportFileWindowVisible" v-on:upload-file="onLoadDocument" />
    <UxBaseMapWindow :visible="basemapWindowVisible" v-on:change-visible="onBaseMapWindowVisible" />
    <UxThemeWindow :visible="themeWindowVisible" v-on:change-visible="onThemeWindowVisible" />
    <UxTermsWindow :visible="termsWindowVisible" v-on:change-visible="onTermsWindowVisible" />
    <UxProfileWindow :visible="profileWindowVisible" v-on:change-visible="onProfileWindowVisible" />
    <UxContactWindow :visible="contactWindowVisible" v-on:change-visible="onContactWindowVisible" />
    <UxVersionWindow :visible="versionWindowVisible" v-on:change-visible="onVersionWindowVisible" />
    <UxApplicationWindow :visible="applicationWindowVisible" v-on:change-visible="onApplicationWindowVisible" />
</template>

<script>
    import FileUtils from "@/app/util/file-utils.mjs";

    import Button from "primevue/button"

    import UxNavigation from "@/ux/frame/UxNavigation"
    import UxLogo from "@/ux/field/UxLogo"

    import UxFileWindow from "@/ux/window/UxFileWindow";
    import UxBaseMapWindow from "@/ux/window/UxBaseMapWindow";
    import UxThemeWindow from "@/ux/window/UxThemeWindow";
    import UxTermsWindow from "@/ux/window/UxTermsWindow";
    import UxProfileWindow from "@/ux/window/UxProfileWindow";
    import UxContactWindow from "@/ux/window/UxContactWindow";
    import UxApplicationWindow from "@/ux/window/UxApplicationWindow";
    import UxVersionWindow from "@/ux/window/UxVersionWindow";

    export default {
        components: {
            UxNavigation,
            Button,
            UxLogo,
            UxFileWindow,
            UxBaseMapWindow,
            UxThemeWindow,
            UxTermsWindow,
            UxProfileWindow,
            UxContactWindow,
            UxApplicationWindow,
            UxVersionWindow
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        emits: ['close', 'select-item'],
        data() {
            return {
                importFileWindowVisible: false,
                basemapWindowVisible: false,
                themeWindowVisible: false,
                termsWindowVisible: false,
                profileWindowVisible: false,
                contactWindowVisible: false,
                applicationWindowVisible: false,
                versionWindowVisible: false,
                expandedKeys: {
                    '0': true,
                    '1': true,
                    '2': true,
                    '3': true
                },
                navigationItems: [
                    {
                        key: '0',
                        label: 'ドキュメント',
                        icon: 'pi pi-fw pi-file',
                        items: [
                            {
                                key: '0_0',
                                label: '開く',
                                icon: 'pi pi-fw pi-folder-open',
                                command: () => { this.importFileWindowVisible = true; }
                            },
                            {
                                key: '0_1',
                                label: '保存',
                                icon: 'pi pi-fw pi-file-edit',
                                command: () => { this.onSaveDocument(); }
                            }
                        ]
                    },
                    {
                        key: '1',
                        label: '設定',
                        icon: 'pi pi-fw pi-cog',
                        items: [
                            {
                                key: '1_0',
                                label: '地図',
                                icon: 'pi pi-fw pi-map',
                                command: () => { this.basemapWindowVisible = true; }
                            },
                            {
                                key: '1_1',
                                label: 'テーマ',
                                icon: 'pi pi-fw pi-wrench',
                                command: () => { this.themeWindowVisible = true; }
                            }
                        ]
                    },
                    // {
                    //     key: '2',
                    //     label: 'SNS',
                    //     icon: 'pi pi-fw pi-comments',
                    //     items: [
                    //         {
                    //             key: '2_0',
                    //             label: 'Twitter',
                    //             icon: 'pi pi-fw pi-twitter',
                    //             command: () => { this['$emit']('select-item', 'twitter'); }
                    //         },
                    //         {
                    //             key: '2_1',
                    //             label: 'Facebook',
                    //             icon: 'pi pi-fw pi-facebook',
                    //             command: () => { this['$emit']('select-item', 'facebook'); }
                    //         }
                    //     ]
                    // },
                    {
                        key: '3',
                        label: 'About',
                        icon: 'pi pi-fw pi-question',
                        items: [
                            {
                                key: '3_0',
                                label: 'アプリケーション情報',
                                icon: 'pi pi-fw pi-info-circle',
                                command: () => { this.applicationWindowVisible = true; }
                            },
                            {
                                key: '3_1',
                                label: 'バージョン',
                                icon: 'pi pi-fw pi-wrench',
                                command: () => { this.versionWindowVisible = true; }
                            },
                            // {
                            //     key: '3_2',
                            //     label: 'ヘルプ',
                            //     icon: 'pi pi-fw pi-question',
                            //     command: () => {
                            //         HtmlUtils.movePage('/help', '_blank');
                            //     }
                            // },
                            {
                                key: '3_3',
                                label: '利用規約',
                                icon: 'pi pi-fw pi-file',
                                command: () => { this.termsWindowVisible = true; }
                            },
                            {
                                key: '3_4',
                                label: 'ご意見／ご要望',
                                icon: 'pi pi-fw pi-envelope',
                                command: () => { this.contactWindowVisible = true; }
                            },
                            // {
                            //     key: '3_5',
                            //     label: 'ご支援',
                            //     icon: 'pi pi-fw pi-users',
                            //     command: () => {
                            //         HtmlUtils.movePage('/donation', '_blank');
                            //     }
                            // },
                            {
                                key: '3_6',
                                label: '管理人情報',
                                icon: 'pi pi-fw pi-user',
                                command: () => { this.profileWindowVisible = true; }
                            }
                        ]
                    }
                ]
            };
        },
        computed: {
        },
        methods: {
            /**
             * "ナビゲーション閉じる"イベント時処理
             */
            onCloseNavigation() {
                this['$emit']('close');
            },

            /**
             * "インポートファイル選択ウィンドウ表示切替"イベント時処理
             */
            onImportFileWindowVisible(visible) {
                this.importFileWindowVisible = visible;
            },

            /**
             * ドキュメント読込時処理
             */
            onLoadDocument(files) {
                const me = this;
                FileUtils.importFileText(files[0], function(content) {
                    const data = JSON.parse(content);
                    // 地図
                    if (data.map) {
                        me.$MapConfig.map.setCenter(data.map.center);
                        me.$MapConfig.map.setZoom(data.map.zoom);
                    }
                    // データソース
                    me.$Service.DataSourceService.setDataSources(me.$MapConfig.map, data.dataSources||[]);
                    if (me.$Service.DataSourceService.getComponent()) {
                        me.$Service.DataSourceService.getComponent().redrawDataSourceComponent();
                    }
                    // レイヤー
                    me.$Service.LayerService.setLayers(me.$MapConfig.map, data.layers||[]);
                    if (me.$Service.LayerService.getComponent()) {
                        me.$Service.LayerService.getComponent().redrawLayerComponent();
                    }
                    // 領域
                    me.$Service.ZoneService.setZones(me.$MapConfig.map, data.zones||[]);
                    if (me.$Service.ZoneService.getComponent()) {
                        me.$Service.ZoneService.getComponent().redrawZoneComponent();
                    }
                    // メモ
                    me.$Service.BuildingService.setBuildings(me.$MapConfig.map, data.buildings||[]);
                    if (me.$Service.BuildingService.getComponent()) {
                        me.$Service.BuildingService.getComponent().redrawBuildingComponent();
                    }
                });
            },

            /**
             * ドキュメント保存時処理
             */
            onSaveDocument() {
                const me = this;
                FileUtils.exportFileText(JSON.stringify({
                    map: {
                        center: me.$MapConfig.map.getCenter(),
                        zoom: me.$MapConfig.map.getZoom()
                    },
                    dataSources: me.$Service.DataSourceService.getDataSourcesForExport(),
                    layers: me.$Service.LayerService.getLayersForExport(),
                    zones: me.$Service.ZoneService.getZonesForExport(),
                    buildings: me.$Service.BuildingService.getBuildingsForExport()
                }), 'document.json');
            },

            /**
             * "地図ウィンドウ表示切替"イベント時処理
             */
            onBaseMapWindowVisible(visible) {
                this.basemapWindowVisible = visible;
            },

            /**
             * "テーマウィンドウ表示切替"イベント時処理
             */
            onThemeWindowVisible(visible) {
                this.themeWindowVisible = visible;
            },

            /**
             * "利用規約ウィンドウ表示切替"イベント時処理
             */
            onTermsWindowVisible(visible) {
                this.termsWindowVisible = visible;
            },

            /**
             * "プロフィールウィンドウ表示切替"イベント時処理
             */
            onProfileWindowVisible(visible) {
                this.profileWindowVisible = visible;
            },

            /**
             * "ご意見／ご要望ウィンドウ表示切替"イベント時処理
             */
            onContactWindowVisible(visible) {
                this.contactWindowVisible = visible;
            },

            /**
             * "アプリケーションウィンドウ表示切替"イベント時処理
             */
            onApplicationWindowVisible(visible) {
                this.applicationWindowVisible = visible;
            },

            /**
             * "バージョンウィンドウ表示切替"イベント時処理
             */
            onVersionWindowVisible(visible) {
                this.versionWindowVisible = visible;
            }
        }
    };
</script>

<style scoped>
</style>