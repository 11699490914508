<template>
    <div>
        <ExFieldset legend="アイコン" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">画像：</span>
                <Dropdown v-model="selectedIconImage" :options="iconImages" optionLabel="name" optionValue="id"
                    placeholder="画像" class="w-12rem" @change="this.$emit('update:iconImage', this.selectedIconImage)" >
                    <template #option="slotProps">
                        <div>
                            <img :src="slotProps.option.src" alt="" style="width: 16px; height: 16px;">
                            <span class="px-2">{{ slotProps.option.name }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">サイズ：</span>
                <Dropdown v-model="selectedIconSize" :options="iconSizes" optionLabel="value" optionValue="value"
                    placeholder="Ｘ位置" class="w-8rem" @change="this.$emit('update:iconSize', this.selectedIconSize)" />
            </div>
        </ExFieldset>
        <ExFieldset legend="位置" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">Ｘ位置：</span>
                <Dropdown v-model="selectedXOffset" :options="offsets" optionLabel="value" optionValue="value"
                    placeholder="Ｘ位置" class="w-8rem" @change="this.$emit('update:xOffset', this.selectedXOffset)" />
            </div>
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">Ｙ位置：</span>
                <Dropdown v-model="selectedYOffset" :options="offsets" optionLabel="value" optionValue="value"
                    placeholder="Ｙ位置" class="w-8rem" @change="this.$emit('update:yOffset', this.selectedYOffset)" />
            </div>
        </ExFieldset>
    </div>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import Dropdown from 'primevue/dropdown';

    export default {
        components: {
            ExFieldset,
            Dropdown
        },
        props: {
            iconImage: {
                type: String,
                default: null
            },
            iconSize: {
                type: Number,
                default: 1
            },
            xOffset: {
                type: Number,
                default: 0
            },
            yOffset: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {
                selectedIconImage: this.iconImage,
                selectedIconSize: this.iconSize,
                selectedXOffset: this.xOffset,
                selectedYOffset: this.yOffset
            };
        },
        computed: {
            iconImages() {
                const items = [];
                for (const icon of this.$MapConfig.icons) {
                    items.push({ id: icon.id, name: icon.name, src: icon.src });
                }
                return items;
            },
            iconSizes() {
                const items = [];
                for (let i = 1; i <= 10; i++) {
                    items.push({ value: i });
                }
                return items;
            },
            offsets() {
                const items = [];
                for (let i = 1; i <= 10; i++) {
                    items.push({ value: i });
                }
                return items;
            }
        },
        emits: [
            'update:iconImage',
            'update:iconSize',
            'update:xOffset',
            'update:yOffset'
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>