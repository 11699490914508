<template>
    <div class="card">
        <ExTabView>
            <TabPanel header="データ">
                <div v-if="!editMode">
                    <div class="card flex justify-content-between">
                        <div class="card flex justify-content-start gap-2 py-2 px-2">
                            <Dropdown v-model="selectedDataSource" :options="dataSources" optionLabel="name" @change="onDataSourceChange" placeholder="データソース選択" class="w-full md:w-16rem" />
                            <Button type="button" v-tooltip.bottom="'データソース'" icon="pi pi-database" @click="onDataSource" severity="secondary" outlined/>
                        </div>
                    </div>
                    <div class="card flex justify-content-between">
                        <div class="card flex justify-content-start gap-2 py-2 px-2">
                            <Button type="button" v-tooltip.bottom="'追加'" icon="pi pi-plus" @click="onAdd" severity="secondary" outlined :disabled="disabledDataSource || !selectedDataSource" />
                            <Button type="button" v-tooltip.bottom="'削除'" icon="pi pi-minus" @click="onDelete" severity="secondary" outlined :disabled="disabledDataSource || !selectedDataSource"/>
                        </div>
                        <div class="card flex justify-content-end gap-2 py-2 px-2">
                            <ToggleButton v-model="visibleDataLayer" v-tooltip.bottom="'参照モード'" onIcon="pi pi-eye" offIcon="pi pi-eye-slash" onLabel="" offLabel="" :disabled="disabledDataSource || !selectedDataSource" v-on:change="onChangeVisibleDataLayer" />
                            <Button type="button" v-tooltip.bottom="'リスト表示'" icon="pi pi-list" @click="onDataList" severity="secondary" outlined :disabled="disabledDataSource || !selectedDataSource" />
                        </div>
                    </div>
                    <div class="card h-full">
                        <div v-if="disabledDataSource">
                            <div class="card flex justify-content-center gap-2 py-2 px-2">
                                <h1 class="t-5">編集不可</h1>
                            </div>
                        </div>
                        <div v-if="!disabledDataSource">
                            <DataTable :key="renderDatas" v-model:selection="selectedDatas" :value="datas" dataKey="properties.id"
                                class="data-panel-datatable" scrollable scrollHeight="auto"
                                @rowSelect="onDataRowSelect" @rowUnselect="onDataRowUnselect">
                                <Column selectionMode="multiple" headerStyle="width: 40px"></Column>
                                <Column field="properties.name" header="名称"></Column>
                                <Column header="" headerStyle="width: 40px">
                                    <template #body="slotProps">
                                        <Button type="button" v-tooltip.bottom="'編集'" icon="pi pi-pencil" @click="onEditMode(`${slotProps.data.properties.id}`)" severity="secondary" outlined/>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="card flex justify-content-between">
                        <div class="card flex justify-content-start gap-2 py-2 px-2">
                            <Button type="button" v-tooltip.bottom="'戻る'" icon="pi pi-angle-double-left" @click="onBackEditMode" severity="secondary" outlined/>
                        </div>
                        <div class="card flex justify-content-end gap-2 py-2 px-2">
                            <div class="py-2 px-2">
                                ※地図上で座標を編集できます
                            </div>
                        </div>
                    </div>
                    <div class="card h-full">
                        <DataTable :key="renderDatas" :value="properties"
                            editMode="cell" @cell-edit-complete="onPropertyCellEditComplete"
                            scrollable scrollHeight="auto">
                            <Column field="key" header="属性"></Column>
                            <Column field="value" header="値">
                                <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="説明">
                <div class="card py-2 px-2">
                    <br>
                    「地理的データ」を登録／確認／編集できます。<br>
                    <br>
                    <span style="font-weight: bold">【 データソース 】</span><br>
                    <br>
                    データソースボタン押下<br>
                    データソース画面が表示されます<br>
                    <br>
                    データソースの登録／編集／削除ができます<br>
                    <br>
                    <br>
                    <span style="font-weight: bold">【 データ 】</span><br>
                    <br>
                    データソースを選択し、データを編集します<br>
                    <br>
                    <span style="font-weight: bold">＜データ追加＞</span><br>
                    <br>
                    追加ボタン押下<br>
                    <br>
                    <span style="font-weight: bold">＜データ編集＞</span><br>
                    <br>
                    リスト上でデータを選択し、編集ボタン押下<br>
                    <br>
                    <span style="font-weight: bold">＜データ削除＞</span><br>
                    <br>
                    リスト上でデータを選択し、削除ボタン押下<br>
                    ※複数選択することで一括削除可能です<br>
                    <br>
                    <span style="font-weight: bold">＜リスト表示＞</span><br>
                    <br>
                    リスト表示ボタン押下<br>
                    データの詳細を確認／編集できます<br>
                </div>
            </TabPanel>
        </ExTabView>
    </div>
    <UxDataSourceWindow :visible="dataSourceWindowVisible" v-on:change-visible="onDataSourceWindowVisible" />
    <UxDataListWindow :visible="dataListWindowVisible" v-on:change-visible="onDataListWindowVisible" v-model:dataSource="selectedDataSource" />
</template>

<script>
    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import Button from 'primevue/button';
    import ToggleButton from 'primevue/togglebutton';
    import Dropdown from 'primevue/dropdown';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';

    import UxDataSourceWindow from "@/ux/window/UxDataSourceWindow";
    import UxDataListWindow from "@/ux/window/UxDataListWindow";

    import DataSourceService from '@/app/service/DataSourceService';

    import MapDrawPolygonAction from "@/app/core/mapaction/MapDrawPolygonAction";
    import MapDrawPointAction from "@/app/core/mapaction/MapDrawPointAction";
    import MapDrawLineAction from "@/app/core/mapaction/MapDrawLineAction";
    import MapDrawEditAction from "@/app/core/mapaction/MapDrawEditAction";

    export default {
        components: {
            ExTabView,
            TabPanel,
            Button,
            ToggleButton,
            Dropdown,
            DataTable,
            Column,
            InputText,
            UxDataSourceWindow,
            UxDataListWindow
        },
        props: {
        },
        data() {
            return {
                selectedDataSource: null,
                selectedDatas: null,
                properties: [],
                editMode: false,
                disabledDataSource: false,
                dataSourceWindowVisible: false,
                dataListWindowVisible: false,
                visibleDataLayer: false,
                renderDatas: 0,

                mapDrawEditAction: null
            }
        },
        created() {
        },
        computed: {
            dataSources() {
                return this.$Service.DataSourceService.getDataSources()||[];
            },
            datas() {
                if (!this.selectedDataSource) {
                    return [];
                }
                return this.$Service.DataSourceService.getDatas(this.$MapConfig.map, this.selectedDataSource.id)||[];
            }
        },
        mounted() {
        },
        unmounted() {
            // レイヤ非表示
            this.visibleDataLayer = false;
            if (this.selectedDataSource) {
                this.$Service.DataSourceService.changeVisibleDataSourceLayer(this.$MapConfig.map, this.selectedDataSource.id, this.visibleDataLayer);
            }
            // 編集モードOFF
            if (this.mapDrawEditAction) {
                this.mapDrawEditAction.off();
            }
        },
        watch: {
        },
        methods: {
            /**
             * "データソースウィンドウ表示切替"イベント時処理
             */
            onDataSourceWindowVisible(visible) {
                this.dataSourceWindowVisible = visible;
                this.redrawDataComponent();
            },

            /**
             * "データリストウィンドウ表示切替"イベント時処理
             */
            onDataListWindowVisible(visible) {
                this.dataListWindowVisible = visible;
                this.redrawDataComponent();
            },

            /**
             * データソース時処理
             */
            onDataSource() {
                this.dataSourceWindowVisible = true;
            },

            /**
             * データソース切り替え時処理
             */
            onDataSourceChange() {
                if (!this.selectedDataSource) {
                    return;
                }
                this.disabledDataSource = !this.$Service.DataSourceService.isEditableDataSource(this.selectedDataSource);
                this.redrawDataComponent();
            },

            /**
             * データレイヤ表示切替時処理
             */
            onChangeVisibleDataLayer() {
                if (!this.selectedDataSource) {
                    return;
                }
                this.$Service.DataSourceService.changeVisibleDataSourceLayer(this.$MapConfig.map, this.selectedDataSource.id, this.visibleDataLayer);
                this.redrawDataComponent();
            },

            /**
             * データ追加時処理
             */
            onAdd() {
                if (!this.selectedDataSource) {
                    return;
                }
                this.$Service.DataSourceService.addData(this.$MapConfig.map, this.selectedDataSource.id,
                    this.$Service.DataSourceService.createFeature(this.selectedDataSource.geomType, {
                    name: 'データ'
                }));
                this.redrawDataComponent();
            },

            /**
             * データ削除時処理
             */
            onDelete() {
                if (!this.selectedDataSource) {
                    return;
                }
                if (!this.selectedDatas) {
                    return;
                }
                this.$Service.DataSourceService.removeDatas(this.$MapConfig.map, this.selectedDataSource.id, this.selectedDatas);
                this.redrawDataComponent();
            },

            /**
             * データリスト時処理
             */
            onDataList() {
                if (!this.selectedDataSource) {
                    return;
                }
                this.dataListWindowVisible = true;
            },

            /**
             * 編集モード時処理
             */
            onEditMode(id) {
                const me = this;

                // 編集モード有効化
                me.editMode = true;

                // データソース判定
                if (!me.selectedDataSource) {
                    return;
                }

                // 属性表示
                const sourceId = me.selectedDataSource.id;
                const geomType = me.selectedDataSource.geomType;
                const attributes = me.selectedDataSource.attributes;
                const data = me.$Service.DataSourceService.getData(me.$MapConfig.map, sourceId, id);
                const properties = [];
                for (const attribute of attributes) {
                    properties.push({
                        key: attribute.key,
                        value: data.properties[attribute.key]
                    })
                }
                me.properties = properties;

                // 座標編集モードON
                function getMapDrawEditAction() {
                    return new MapDrawEditAction({
                        map: me.$MapConfig.map,
                        drawControl: me.$MapConfig.drawControl,
                        geojson: data,
                        edited: function(e) {
                            if (e.type == 'draw.create' || e.type == 'draw.update') {
                                let geojson = e.features[0].geometry;
                                // ポリゴン編集
                                data.geometry.coordinates = geojson.coordinates;
                                me.$Service.DataSourceService.updateData(me.$MapConfig.map, sourceId, data);
                            }
                        }
                    });
                }
                if (data.geometry.coordinates.length > 0) {
                    // 編集
                    me.mapDrawEditAction = getMapDrawEditAction();
                    // 地図移動
                    this.$Service.DataSourceService.panToData(this.$MapConfig.map, this.selectedDataSource.id, data);
                } else {
                    // 追加
                    switch (geomType) {
                        case DataSourceService.GT_POLYGON:
                            {
                                me.mapDrawEditAction = new MapDrawPolygonAction({
                                    map: me.$MapConfig.map,
                                    drawControl: me.$MapConfig.drawControl,
                                    drawn: function(e) {
                                        if (e.type == 'draw.create' || e.type == 'draw.update') {
                                            let geojson = e.features[0].geometry;
                                            data.geometry.coordinates = geojson.coordinates;

                                            // ポリゴン作成
                                            me.$Service.DataSourceService.updateData(me.$MapConfig.map, sourceId, data);

                                            // 編集モード切替
                                            me.mapDrawEditAction.off();
                                            me.mapDrawEditAction = getMapDrawEditAction();
                                            me.mapDrawEditAction.on();
                                        }
                                    }
                                });
                            }
                            break;
                        case DataSourceService.GT_POINT:
                            {
                                me.mapDrawEditAction = new MapDrawPointAction({
                                    map: me.$MapConfig.map,
                                    drawControl: me.$MapConfig.drawControl,
                                    drawn: function(e) {
                                        if (e.type == 'draw.create' || e.type == 'draw.update') {
                                            let geojson = e.features[0].geometry;
                                            data.geometry.coordinates = geojson.coordinates;

                                            // ポリゴン作成
                                            me.$Service.DataSourceService.updateData(me.$MapConfig.map, sourceId, data);

                                            // 編集モード切替
                                            me.mapDrawEditAction.off();
                                            me.mapDrawEditAction = getMapDrawEditAction();
                                            me.mapDrawEditAction.on();
                                        }
                                    }
                                });
                            }
                            break;
                        case DataSourceService.GT_LINE:
                            {
                                me.mapDrawEditAction = new MapDrawLineAction({
                                    map: me.$MapConfig.map,
                                    drawControl: me.$MapConfig.drawControl,
                                    drawn: function(e) {
                                        if (e.type == 'draw.create' || e.type == 'draw.update') {
                                            let geojson = e.features[0].geometry;
                                            data.geometry.coordinates = geojson.coordinates;

                                            // ポリゴン作成
                                            me.$Service.DataSourceService.updateData(me.$MapConfig.map, sourceId, data);

                                            // 編集モード切替
                                            me.mapDrawEditAction.off();
                                            me.mapDrawEditAction = getMapDrawEditAction();
                                            me.mapDrawEditAction.on();
                                        }
                                    }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                }
                me.mapDrawEditAction.on();

                me.redrawDataComponent();
            },

            /**
             * データ行選択時処理
             */
            onDataRowSelect(event) {
                // データソース判定
                if (!this.selectedDataSource) {
                    return;
                }
                // 地図移動
                this.$Service.DataSourceService.panToData(this.$MapConfig.map, this.selectedDataSource.id, event.data);
            },

            /**
             * データ行選択解除時処理
             */
            onDataRowUnselect() {
            },

            /**
             * 編集モード戻る時処理
             */
            onBackEditMode() {
                this.editMode = false;

                // 座標編集モードOFF
                if (this.mapDrawEditAction) {
                    this.mapDrawEditAction.off();
                }

                this.redrawDataComponent();
            },

            /**
             * プロパティセル編集時処理
             */
            onPropertyCellEditComplete(event) {
                const me = this;
                const { data, newValue, field } = event;

                // データソース判定
                if (!this.selectedDataSource) {
                    return;
                }

                // IDは変更不可
                if (data['key'] == 'id') {
                    return;
                }

                // 値変更
                const sourceId = this.selectedDataSource.id;
                let dataId = null;
                for (const property of me.properties) {
                    if (property.key == 'id') {
                        dataId = property.value;
                        break;
                    }
                }
                switch (field) {
                case 'value':
                    // 値変更
                    {
                        const properties = {};
                        properties['id'] = dataId;
                        properties[data['key']] = newValue;
                        me.$Service.DataSourceService.updateData(this.$MapConfig.map, sourceId, {
                            properties: properties
                        });
                        data[field] = newValue;
                    }
                    break;
                default:
                    // その他
                    event.preventDefault();
                    break;
                }
                me.redrawDataComponent();
            },

            /**
             * データコンポーネント再描画
             */
            redrawDataComponent() {
                this.renderDatas = this.renderDatas + 1;
                if (this.visibleDataLayer) {
                    this.$Service.DataSourceService.changeVisibleDataSourceLayer(this.$MapConfig.map, this.selectedDataSource.id, this.visibleDataLayer);
                }
            }
        }
    };
</script>

<style scoped>
</style>
<style>
    .data-panel-datatable .p-datatable-wrapper {
        height: calc(100vh - (60px * 4));
    }
    .data-panel-datatable .p-datatable-tbody {
        max-height: 100%;
        overflow-y: auto;
    }
</style>
