<template>
    <div class="card h-full">
        <ExTabView>
            <TabPanel header="領域">
                <div class="card flex justify-content-between">
                    <div class="card flex justify-content-start gap-2 py-2 px-2">
                        <Button type="button" v-tooltip.bottom="'編集'" icon="pi pi-file-edit" @click="onEdit" severity="secondary" outlined :disabled="!selectedZones || selectedZones.length==0"/>
                        <Button type="button" v-tooltip.bottom="'削除'" icon="pi pi-trash" @click="onDelete" severity="secondary" outlined :disabled="!selectedZones || selectedZones.length==0"/>
                    </div>
                    <div class="card flex justify-content-end gap-2 py-2 px-2">
                        <Button type="button" v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onMenu" aria-haspopup="true" aria-controls="overlay_menu" severity="secondary" outlined/>
                        <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
                    </div>
                </div>
                <div class="card h-full">
                    <DataTable :key="renderZones" v-model:selection="selectedZones" :value="zones" dataKey="id" editMode="cell"
                        @cell-edit-complete="onCellEditComplete" class="zone-panel-datatable" tableClass="editable-cells-table"
                        scrollable scrollHeight="auto" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                        <Column selectionMode="multiple" headerStyle="width: 40px"></Column>
                        <Column field="name" header="名称" bodyStyle="text-align:left">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                        <Column header="" headerStyle="width: 40px">
                            <template #body="{ data }">
                                <Button type="button" v-tooltip.bottom="'集計'" icon="pi pi-calculator" @click="onSummary(data)" severity="secondary" outlined/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="card flex justify-content-end gap-2 py-2 px-2">
                    <ToggleButton v-model="visibleZone" onLabel="表示" offLabel="表示" class="w-8rem" v-on:change="onChangeVisibleZone" />
                    <ToggleButton v-model="visibleMarker" onLabel="マーク" offLabel="マーク" class="w-8rem" v-on:change="onChangeVisibleMarker" :disabled="!visibleZone" />
                    <ToggleButton v-model="visibleInfo" onLabel="情報" offLabel="情報" class="w-8rem" v-on:change="onChangeVisibleInfo" :disabled="!visibleZone || !visibleMarker" />
                </div>
            </TabPanel>
            <TabPanel header="説明">
                <div class="card py-2 px-2">
                    <br>
                    地図をクリックすることで、「領域」を登録できます。<br>
                    <br>
                    <span style="font-weight: bold">＜追加＞</span><br>
                    <br>
                    地図上で領域を配置したい地点をクリック<br>
                    <br>
                    <span style="font-weight: bold">＜編集＞</span><br>
                    <br>
                    リスト上で領域を選択し、編集ボタン押下<br>
                    ※複数選択することで一括編集可能です<br>
                    <br>
                    <span style="font-weight: bold">＜削除＞</span><br>
                    <br>
                    リスト上で領域を選択し、削除ボタン押下<br>
                    ※複数選択することで一括削除可能です<br>
                    <br>
                    <span style="font-weight: bold">＜集計＞</span><br>
                    <br>
                    データソースを選択し、作成した領域で集計値を算出できます。<br>
                </div>
            </TabPanel>
        </ExTabView>
    </div>

    <UxZoneEditWindow :visible="zoneEditWindowVisible" v-on:change-visible="onZoneEditWindowVisible" :selectedZones="selectedZones" v-on:edited="onEditedZone" />
    <UxZoneAggregateWindow :visible="zoneAggregateWindowVisible" v-on:change-visible="onZoneAggregateWindowVisible" :zones="aggregateZones" />
    <UxFileWindow :visible="importFileWindowVisible" v-on:change-visible="onImportFileWindowVisible" v-on:upload-file="onImportFile" />
</template>

<script>
    import UxZoneEditWindow from "@/ux/window/UxZoneEditWindow";
    import UxZoneAggregateWindow from "@/ux/window/UxZoneAggregateWindow";
    import UxFileWindow from "@/ux/window/UxFileWindow";

    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import Button from 'primevue/button';
    import ToggleButton from 'primevue/togglebutton';
    import Menu from 'primevue/menu';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';

    export default {
        components: {
            UxZoneEditWindow,
            UxZoneAggregateWindow,
            UxFileWindow,
            ExTabView,
            TabPanel,
            Button,
            ToggleButton,
            Menu,
            DataTable,
            Column,
            InputText
        },
        data() {
            const zoneTypes = [];
            for (let i = 0; i <= 30; i++) {
                zoneTypes.push({
                    name: `${i}km`,
                    code: `${i}`
                });
            }
            return {
                menuItems: [
                    {
                        label: 'インポート',
                        icon: 'pi pi-file-import',
                        command: () => { this.onImport(); }
                    },
                    {
                        label: 'エクスポート',
                        icon: 'pi pi-file-export',
                        command: () => { this.onExport(); }
                    }
                ],
                zoneEditWindowVisible: false,
                zoneAggregateWindowVisible: false,
                importFileWindowVisible: false,
                visibleZone: this.$Service.ZoneService.visibleZone,
                visibleMarker: this.$Service.ZoneService.visibleMarker,
                visibleInfo: this.$Service.ZoneService.visibleInfo,
                zoneTypes: zoneTypes,
                selectedZones: null,
                aggregateZones: [],
                renderZones: 0
            };
        },
        created() {
            this.$Service.ZoneService.setComponent(this);
        },
        computed: {
            zones() {
                return this.$Service.ZoneService.getZones()||[];
            }
        },
        mounted() {
            this.$Service.ZoneService.onMapClickEvent(this.$MapConfig.map, this.onClickMap);
        },
        unmounted() {
        },
        methods: {
            /**
             * 地図クリック時処理
             */
            onClickMap(event) {
                // パラメータ取得
                const data = {
                    name: '領域',
                    desc: '',
                    zoneParams: [
                        { color: 'ff0000', radius: '1' },
                        { color: '00ff00', radius: '3' },
                        { color: '0000ff', radius: '5' },
                    ],
                    lng: event.lngLat.lng,
                    lat: event.lngLat.lat
                };

                // データ追加
                this.$Service.ZoneService.addZone(this.$MapConfig.map, data);
                this.redrawZoneComponent();
            },

            /**
             * "編集ウィンドウ表示切替"イベント時処理
             */
            onZoneEditWindowVisible(visible) {
                this.zoneEditWindowVisible = visible;
            },

            /**
             * "集計ウィンドウ表示切替"イベント時処理
             */
            onZoneAggregateWindowVisible(visible) {
                this.zoneAggregateWindowVisible = visible;
            },

            /**
             * "インポートファイル選択ウィンドウ表示切替"イベント時処理
             */
            onImportFileWindowVisible(visible) {
                this.importFileWindowVisible = visible;
            },

            /**
             * "ファイルアップロード"イベント時処理
             */
            onImportFile(files) {
                const me = this;

                // データ追加
                this.$Service.ZoneService.importFile(this.$MapConfig.map, files[0], function() {
                    // 画面クローズ
                    setTimeout(() => {
                        me.importFileWindowVisible = false;
                    }, 1000);
                    me.redrawZoneComponent();
                });
            },

            /**
             * "編集完了"イベント時処理
             */
            onEditedZone(data) {
                // データ更新
                for (const zone of this.selectedZones||[]) {
                    this.$Service.ZoneService.updateZone(this.$MapConfig.map, zone.id, data);
                    for (const key in data) {
                        zone[key] = data[key];
                    }
                }
                this.redrawZoneComponent();
            },

            /**
             * 行選択時処理
             */
            onRowSelect(event) {
                this.$MapConfig.map.panTo([event.data.lng, event.data.lat]);
            },

            /**
             * 行選択解除時処理
             */
            onRowUnselect() {
            },

            /**
             * セル編集時処理
             */
            onCellEditComplete(event) {
                const me = this;
                const { data, newValue, field } = event;

                switch (field) {
                case 'name':
                    // ラベル変更
                    {
                        me.$Service.ZoneService.updateZone(this.$MapConfig.map, data.id, {
                            name: newValue
                        });
                        data[field] = newValue;
                    }
                    break;
                default:
                    // その他
                    event.preventDefault();
                    break;
                }
                me.redrawZoneComponent();
            },

            /**
             * 集計時処理
             */
            onSummary(zone) {
                const zones = [];
                for (let i = 0; i < zone.zoneParams.length; i++) {
                    const zoneParam = zone.zoneParams[i];
                    zones.push({
                        name: `円形${zoneParam.radius}km`,
                        polygon: this.$Service.ZoneService.getZonePolygon(this.$MapConfig.map, zone.id, i)
                    })
                }
                this.aggregateZones = zones;
                this.zoneAggregateWindowVisible = true;
            },

            /**
             * メニュー選択時処理
             */
            onMenu(event) {
                this.$refs.menu.toggle(event);
            },

            /**
             * 編集時処理
             */
            onEdit() {
                if (!this.selectedZones || this.selectedZones.length == 0) {
                    return;
                }
                this.zoneEditWindowVisible = true;
            },

            /**
             * 削除時処理
             */
            onDelete() {
                this.$Service.ZoneService.removeZones(this.$MapConfig.map, this.selectedZones);
                this.redrawZoneComponent();
            },

            /**
             * インポート時処理
             */
            onImport() {
                this.importFileWindowVisible = true;
            },

            /**
             * エクスポート時処理
             */
            onExport() {
                this.$Service.ZoneService.exportFile(this.$MapConfig.map);
                this.redrawZoneComponent();
            },

            /**
             * 領域表示変更時処理
             */
            onChangeVisibleZone() {
                this.$Service.ZoneService.changeVisibleZone(this.$MapConfig.map, this.visibleZone);
                this.redrawZoneComponent();
                this.visibleZone = this.$Service.ZoneService.visibleZone;
                this.visibleMarker = this.$Service.ZoneService.visibleMarker;
                this.visibleInfo = this.$Service.ZoneService.visibleInfo;
            },

            /**
             * マーカー表示変更時処理
             */
            onChangeVisibleMarker() {
                this.$Service.ZoneService.changeVisibleMarker(this.$MapConfig.map, this.visibleMarker);
                this.redrawZoneComponent();
                this.visibleZone = this.$Service.ZoneService.visibleZone;
                this.visibleMarker = this.$Service.ZoneService.visibleMarker;
                this.visibleInfo = this.$Service.ZoneService.visibleInfo;
            },

            /**
             * 情報表示変更時処理
             */
            onChangeVisibleInfo() {
                this.$Service.ZoneService.changeVisibleInfo(this.$MapConfig.map, this.visibleInfo);
                this.redrawZoneComponent();
                this.visibleZone = this.$Service.ZoneService.visibleZone;
                this.visibleMarker = this.$Service.ZoneService.visibleMarker;
                this.visibleInfo = this.$Service.ZoneService.visibleInfo;
            },

            /**
             * 領域コンポーネント再描画
             */
            redrawZoneComponent() {
                this.renderZones = this.renderZones + 1;
            }
        }
    };
</script>

<style scoped>
</style>
<style>
    .zone-panel-datatable .p-datatable-wrapper {
        height: calc(100vh - (60px * 4));
    }
    .zone-panel-datatable .p-datatable-tbody {
        max-height: 100%;
        overflow-y: auto;
    }
</style>
