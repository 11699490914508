<template>
    <div class="card">
        <ExTabView>
            <TabPanel header="印刷">
                <div class="m-4">
                    <div class="card flex justify-content-left gap-2 py-2">
                        <Button type="button" label="印刷" icon="pi pi-print" @click="onOK" severity="secondary" outlined />
                    </div>

                    <UxSeparator />

                    <div class="card flex justify-content-left gap-2 py-2">
                        <span class="w-4rem mt-2">用紙：</span>
                        <Dropdown v-model="selectedSize" :options="size"
                            optionLabel="id" optionValue="id" placeholder="用紙" class="w-full md:w-8rem"
                            @change="onValuesChanged" />
                    </div>
                    <div class="card flex justify-content-left gap-2 py-2">
                        <span class="w-4rem mt-2">向き：</span>
                        <Dropdown v-model="selectedOrientation" :options="orientation"
                            optionLabel="name" optionValue="id" placeholder="向き" class="w-full md:w-8rem"
                            @change="onValuesChanged" />
                    </div>

                    <UxSeparator />

                    <div class="card flex justify-content-left gap-2 py-2">
                        <Button type="button" v-tooltip.bottom="'再描画'" icon="pi pi-refresh" @click="onRedraw" severity="secondary" outlined/>
                    </div>
                    <div class="card flex justify-content-center gap-2 py-2 px-2 border-1">
                        <div :style="{ width: this.previewWidth + 'px', height: this.previewHeight + 'px' }">
                            <img :src="previewImage" style="width: 100%; height: 100%;" />
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="説明">
                <div class="card py-2 px-2">
                    <br>
                    現在表示中の地図の印刷が行えます。<br>
                    <br>
                    １．「用紙」と「向き」を設定。<br>
                    <br>
                    ２．「Preview」を確認。<br>
                    <br>
                    ３．問題なければ「印刷」ボタンを押下。<br>
                    <br>
                    <span class="mx-3"/>印刷が開始されます。<br>
                </div>
            </TabPanel>
        </ExTabView>

        <div style="position:absolute;top:-9999px;z-index:-999">
            <div id="print-preview" :style="{ width: this.width + 'px', height: this.height + 'px' }"></div>
        </div>
    </div>
</template>

<script>
    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import UxSeparator from '@/ux/field/UxSeparator';
    import Dropdown from 'primevue/dropdown';
    import Button from 'primevue/button';

    import maplibregl from "maplibre-gl";

    export default {
        components: {
            ExTabView,
            TabPanel,
            UxSeparator,
            Dropdown,
            Button
        },
        props: {
        },
        data() {
            const size = [
                { id: 'A3', width: 297, height: 420 },
                { id: 'A4', width: 210, height: 297 },
                { id: 'A5', width: 148, height: 210 },
                { id: 'B4', width: 257, height: 364 },
                { id: 'B5', width: 182, height: 257 }
            ];
            const orientation = [
                { id: 'PORTRAIT', name: '縦' },
                { id: 'LANDSCAPE', name: '横' }
            ];
            return {
                previewMap: null,
                previewImage: null,

                width: size[0].width,
                height: size[0].height,
                previewWidth: this.adjustPreviewSize(size[0].width, size[0].height)[0],
                previewHeight: this.adjustPreviewSize(size[0].width, size[0].height)[1],

                selectedDpi: 96,
                selectedSize: size[1].id,
                selectedOrientation: orientation[1].id,

                size: size,
                orientation: orientation,

                getSize: function(id) {
                    for (const data of this.size) {
                        if (data.id == id) {
                            return data;
                        }
                    }
                    return null;
                },
                getOrientation: function(id) {
                    for (const data of this.orientation) {
                        if (data.id == id) {
                            return data;
                        }
                    }
                    return null;
                }
            };
        },
        mounted() {
            const me = this;
            setTimeout(() => {
                me.onOpen();
            }, 500);
        },
        unmounted() {
            this.onClose();
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "オープン"時処理
             */
            onOpen() {
                // プレビュー生成
                const me = this,
                    map = this.$MapConfig.map,
                    mapElement = document.getElementById('print-preview');

                // 印刷用マップ生成
                this.previewMap = new maplibregl.Map({
                    container: mapElement,
                    style: map.getStyle(),
                    bearing: map.getBearing(),
                    pitch: map.getPitch(),
                    interactive: false,
                    preserveDrawingBuffer: true,
                    fadeDuration: 0,
                    attributionControl: false,
                    itemId: 'canvas',
                    center: map.getCenter(),
                    zoom: map.getZoom(),
                    width: this.width,
                    height: this.height
                });

                // イメージ追加
                const images = (map.style.imageManager || {}).images || [];
                for (const key in images) {
                    if (images[key].data) {
                        me.previewMap.addImage(key, images[key].data);
                    }
                }

                // 初期値
                let size = [0, 0];
                let dpi = 96;

                // dpi
                if (me.selectedDpi) dpi = me.selectedDpi;

                // サイズの設定
                if (me.getSize(me.selectedSize)) {
                    const mmWidth = me.getSize(me.selectedSize).width;
                    const mmHeight = me.getSize(me.selectedSize).height;

                    // mmからpixelサイズを求める
                    if (mmWidth) size = [mmWidth * dpi / 25.4, mmHeight * dpi / 25.4];
                }

                // 横向きなら入れ替える
                if (me.selectedOrientation === 'LANDSCAPE') {
                    [size[0], size[1]] = [size[1], size[0]];
                }

                // マップ表示可能な上限を超えていないか
                if (size[0] > 8000 || size[1] > 8000) {
                    return;
                }

                // ボーダーの幅を忘れないように
                me.width = (size[0]);
                me.height = (size[1]);

                // プレビュー更新
                this.previewMap.on('idle', me.onPreviewUpdate);

                // 正しく表示されないため、遅延でリサイズを実行する
                // 印刷用のマップを正しく表示する
                setTimeout(() => {
                    me.previewMap.resize();
                }, 500);

                // イベント登録
                this.$MapConfig.map.on('idle', this.onMapUpdate);
                this.$MapConfig.map.on('movestart', this.onMapMoveStart);
            },

            /**
             * "クローズ"時処理
             */
            onClose() {
                // イベント解除
                this.$MapConfig.map.off('idle', this.onMapUpdate);
                this.$MapConfig.map.off('movestart', this.onMapMoveStart);

                // 印刷用コンテナ削除
                if (this.previewMap) {
                    this.previewMap.off('idle', this.onPreviewUpdate);
                    this.previewMap.remove();
                    this.previewMap = null;
                }
            },

            /**
             * メインマップ更新時の処理
             */
            onMapUpdate: function () {
                const me = this;

                // プレビューのマップを更新する
                me.previewMap.setBearing(me.$MapConfig.map.getBearing());
                me.previewMap.setCenter(me.$MapConfig.map.getCenter());
                me.previewMap.setPitch(me.$MapConfig.map.getPitch());
                me.previewMap.setZoom(me.$MapConfig.map.getZoom());
            },

            /**
             * メインマップ操作開始時の処理
             */
            onMapMoveStart: function () {
                this.previewImage = null;
            },

            /**
             * プレヴュー更新時の処理
             */
            onPreviewUpdate: function () {
                const me = this;

                // 大きなキャンバスでは非常に大きな負荷がかかるため、JPEGで品質を落として生成する
                const image = me.previewMap.getCanvas().toDataURL('image/png');
                // const image = me.previewMap.getCanvas().toDataURL("image/jpeg", 0.1);

                let width = me.width;
                let height = me.height;

                // プレビューサイズ計算
                const recalc_size = me.adjustPreviewSize(width, height);

                // サイズ設定
                if (recalc_size[0] != null) me.previewWidth = recalc_size[0];
                if (recalc_size[1] != null) me.previewHeight = recalc_size[1];

                // プレビュー画像更新
                me.previewImage = image;
            },

            /**
             * 印刷範囲が変更された時の処理
             */
            onValuesChanged: function () {
                this.onRedraw();
            },

            /**
             * 再描画時処理
             */
            onRedraw() {
                this.onClose();
                this.onOpen();
            },

            /**
             * OK時処理
             */
            onOK() {
                const png = this.previewImage;
                const a = document.createElement('a');
                a.href = png;
                a.download = "画像.png";
                a.click();

                // a要素を削除
                a.remove();
            },

            /**
             * @private
             * プレビューサイズ調整
             */
            adjustPreviewSize(width, height) {
                const MIN_WIDTH = 232;
                const MIN_HEIGHT = 0;
                const MAX_WIDTH = 232;
                const MAX_HEIGHT = 999;

                const data = {
                    width: width,
                    height: height
                };

                // 下限調整
                if (data.width < MIN_WIDTH || data.height < MIN_HEIGHT) {
                    const ratio = Math.max(MIN_WIDTH / width, MIN_HEIGHT / height);
                    data.width *= ratio;
                    data.height *= ratio;
                }

                // 上限調整
                if (data.width > MAX_WIDTH || data.height > MAX_HEIGHT) {
                    const ratio = Math.max(MIN_WIDTH / width, MIN_HEIGHT / height);
                    data.width *= ratio;
                    data.height *= ratio;
                }

                return [data.width, data.height];
            }
        }
    };
</script>

<style scoped>
</style>