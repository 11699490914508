<template>
    <ExFieldset legend="塗りつぶし" :toggleable="false" class="m-2">
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">塗色：</span>
            <ColorPicker class="mt-1" v-model="selectedFillColor" inputId="color" format="hex" @change="this.$emit('update:fillColor', this.selectedFillColor)" />
        </div>
    </ExFieldset>
    <ExFieldset legend="枠線" :toggleable="false" class="m-2">
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">枠色：</span>
            <ColorPicker class="mt-1" v-model="selectedOutlineColor" inputId="color" format="hex" @change="this.$emit('update:outlineColor', this.selectedOutlineColor)" />
        </div>
    </ExFieldset>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import ColorPicker from 'primevue/colorpicker';

    export default {
        components: {
            ExFieldset,
            ColorPicker
        },
        props: {
            fillColor: {
                type: String,
                default: "ff0000"
            },
            outlineColor: {
                type: String,
                default: "ff0000"
            },
        },
        data() {
            return {
                selectedFillColor: this.fillColor,
                selectedOutlineColor: this.outlineColor
            };
        },
        emits: [
            'update:fillColor',
            'update:outlineColor'
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>