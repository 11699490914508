<template>
    <Dialog modal v-bind:visible="visible" header="データソース" :style="{ width: '50vw', height: '80vh' }" :breakpoints="{ '960px': '60vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div style="height:55vh">
            <ExTabView>
                <TabPanel header="基本情報">
                    <UxDataSourceBaseEditPanel
                        :createMode="false"
                        v-model:name="name"
                        v-model:desc="desc"
                        v-model:geomType="geomType"
                        v-model:dataSourceType="dataSourceType" />
                </TabPanel>
                <TabPanel header="属性">
                    <UxDataSourcePropertyEditPanel
                        :createMode="false"
                        v-model:attributes="attributes" />
                </TabPanel>
                <TabPanel header="API連携" v-if="isDataSourceTypeWebApi">
                    <UxDataSourceWebApiEditPanel
                        :createMode="false"
                        v-model:requestUrl="data.requestUrl"
                        v-model:requestMethod="data.requestMethod"
                        v-model:requestHeader="data.requestHeader"
                        v-model:requestBody="data.requestBody"
                        v-model:responseProperty="data.responseProperty" />
                </TabPanel>
            </ExTabView>
        </div>
        <template #footer>
            <UxSeparator />
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="OK" @click="onOK" severity="secondary" outlined />
                <Button type="button" label="キャンセル" @click="onCancel" severity="secondary" outlined />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import Button from 'primevue/button';
    import UxSeparator from '@/ux/field/UxSeparator';

    import UxDataSourceBaseEditPanel from "@/ux/panel/UxDataSourceBaseEditPanel";
    import UxDataSourcePropertyEditPanel from "@/ux/panel/UxDataSourcePropertyEditPanel";
    import UxDataSourceWebApiEditPanel from "@/ux/panel/UxDataSourceWebApiEditPanel";

    import DataSourceService from '@/app/service/DataSourceService';

    export default {
        components: {
            Dialog,
            TabPanel,
            ExTabView,
            Button,
            UxSeparator,
            UxDataSourceBaseEditPanel,
            UxDataSourcePropertyEditPanel,
            UxDataSourceWebApiEditPanel
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },
            selectedDataSource: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                name: 'データソース',
                desc: '',
                geomType: null,
                dataSourceType: null,
                attributes: [],
                data: {}
            };
        },
        computed: {
            isDataSourceTypeWebApi() {
                return this.dataSourceType == DataSourceService.DT_GEOJSON_API;
            }
        },
        watch: {
            visible(newValue) {
                const me = this;
                if (newValue) {
                    if (me.selectedDataSource) {
                        const dataSource = me.selectedDataSource;
                        me.name = dataSource.name;
                        me.desc = dataSource.desc;
                        me.geomType = dataSource.geomType;
                        me.dataSourceType = dataSource.dataSourceType;
                        me.attributes = dataSource.attributes;
                        me.data = dataSource.data;
                    }
                }
            }
        },
        emits: ['change-visible', 'edited'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * OK時処理
             */
            onOK() {
                // 更新内容通知
                this['$emit']('edited', {
                    name: this.name,
                    desc: this.desc,
                    geomType: this.geomType,
                    dataSourceType: this.dataSourceType,
                    attributes: this.attributes.map(function(value) { delete value.id; return value; }),
                    data: this.data
                });
                // クローズ
                this.onVisible(false);
            },

            /**
             * キャンセル時処理
             */
            onCancel() {
                // クローズ
                this.onVisible(false);
            }
        }
    };
</script>

<style scoped>
</style>