<template>
    <div ref="mapContainer" class="mapContainer"></div>
</template>

<script>
import "maplibre-gl/dist/maplibre-gl.css";
import maplibregl from "maplibre-gl";
import 'maplibre-gl-draw/dist/mapbox-gl-draw.css';
import MapLibreDraw from "maplibre-gl-draw";
import '@watergis/maplibre-gl-export/css/styles.css';
import { MaplibreExportControl, Size, PageOrientation, Format, DPI} from "@watergis/maplibre-gl-export";

export default {
    name: 'ux-map',
    mounted() {
        const me = this;
        // 初期スタイル取得
        let initStyle = null;
        for (const baseLayer of this.$MapConfig.baseLayers) {
            if (baseLayer.id === this.$MapConfig.selectBaseLayerId) {
                initStyle = baseLayer.style;
                break;
            }
        }

        // 地図初期化
        const map = new maplibregl.Map({
            container: this.$refs.mapContainer,
            style: initStyle,
			maxBounds: this.$MapConfig.maxBounds||[[100.00, 14.00], [162.00, 52.00]],
            center: this.$MapConfig.center||[139.68786, 35.68355], // 中心座標
            zoom: this.$MapConfig.zoom||12, // ズームレベル
			boxZoom: true,
        });

        // 地図コントロール設定
        const drawControl = new MapLibreDraw();
        map.addControl(new maplibregl.NavigationControl(), 'top-left');
        map.addControl(new maplibregl.ScaleControl(), 'bottom-left');
        map.addControl(drawControl, 'top-right');
        map.addControl(new MaplibreExportControl({
            PageSize: Size.A4,
            PageOrientation: PageOrientation.Portrait,
            Format: Format.PNG,
            DPI: DPI[96],
            Crosshair: true,
            PrintableArea: true
        }), 'top-right');

        // 地図読込
        map.on('load', function() {
            // カーソル制御
            map.getCanvas().style.cursor = "pointer";
            map.on('dragstart', function() {
                map.getCanvas().style.cursor = "";
            });
            map.on('dragend', function() {
                map.getCanvas().style.cursor = "pointer";
            });

            // アイコン設定
            for (const icon of me.$MapConfig.icons) {
                map.loadImage(icon.src, function(error, image) {
                    if (error) throw error;
                    map.addImage(icon.id, image);
                });
            }

            // パラメーター設定
            map.scrollZoom.setWheelZoomRate(1.0);
        });

        // グローバル変数設定
        this.$MapConfig.map = map;
        this.$MapConfig.drawControl = drawControl;

        // 不要なコントロール非表示
        const hideControlClasses = document.getElementsByClassName('maplibregl-ctrl-top-right mapboxgl-ctrl-top-right');
        for (const hideControlClass of hideControlClasses) {
            hideControlClass.style.display = 'none';
        }
    }
};
</script>

<style scoped>
    .mapContainer {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
</style>