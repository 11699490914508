import { createApp } from 'vue';
import { Vue3Mq } from "vue3-mq";

import App from './App.vue'
import Router from './router';
import PrimeVue from "primevue/config";

// 日本語設定
import ja from "@/i18n/locale/ja";

// テーマ
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/themes/vela-green/theme.css";

// スタイル
import "primevue/resources/primevue.min.css"; // ベース
import "primeicons/primeicons.css"; // アイコン
import "primeflex/primeflex.css"; // primeflex

// アプリケーション
import AppService from "@/app/service/AppService.mjs";
import DataSourceService from "@/app/service/DataSourceService.mjs";
import LayerService from "@/app/service/LayerService.mjs";
import ZoneService from "@/app/service/ZoneService.mjs";
import BuildingService from "@/app/service/BuildingService.mjs";
import MeasureService from "@/app/service/MeasureService.mjs";
import MapDataService from "@/app/service/MapDataService.mjs";

// スタイル
const app = createApp(App);

// ライブラリ登録
app.use(Router);
app.use(PrimeVue, { locale: ja });
app.use(Vue3Mq, { preset: "vuetify" });

// ツールチップ有効化
import Tooltip from 'primevue/tooltip';
app.directive('tooltip', Tooltip);

// 設定ファイル読込
import Settings from "@/assets/Settings.json";
import MapConfig from "@/assets/MapConfig.json";

// グローバル変数を定義
app.config.globalProperties.$Settings = Settings;
app.config.globalProperties.$MapConfig = MapConfig;
app.config.globalProperties.$Service = {
    AppService: new AppService(),
    DataSourceService: new DataSourceService({ limit: Settings.dataSourceLimit }),
    LayerService: new LayerService({ limit: Settings.layerLimit }),
    ZoneService: new ZoneService({ limit: Settings.zoneLimit }),
    BuildingService: new BuildingService({ limit: Settings.buildingLimit }),
    MeasureService: new MeasureService(),
    MapDataService: new MapDataService()
};

// マウント
app.mount('#app');