import MapDistanceAction from "@/app/core/mapaction/MapDistanceAction";
import MapAreaAction from "@/app/core/mapaction/MapAreaAction";

/**
 * 計測サービス
 */
export default class MeasureService {

    /** 設定 */
    config = {
    };

    /** イベント */
    events = {};

    /** 距離計測アクション */
    _mapDistanceAction = null;
    /** 面積計測アクション */
    _mapAreaAction = null;

    /**
     * コンストラクタ
     * @param {Object} config 設定
     */
    constructor(config={}) {
        this.config = config||[];
    }

    /**
     * 距離計測アクションON
     * @param {Object} map マップ
     * @param {Function} callback コールバック
     */
    onMapDistanceAction(map, callback) {
        const me = this;
        if (me._mapDistanceAction) {
            me._mapDistanceAction.off();
        }
        me._mapDistanceAction = new MapDistanceAction({
            map: map,
            drawn: function(e) {
                me._mapDistanceAction.off();
                if (callback) {
                    callback(e);
                }
            }
        });
        me._mapDistanceAction.on();
    }

    /**
     * 面積計測アクションON
     * @param {Object} map マップ
     * @param {Function} callback コールバック
     */
    onMapAreaAction(map, callback) {
        const me = this;
        if (me._mapAreaAction) {
            me._mapAreaAction.off();
        }
        me._mapAreaAction = new MapAreaAction({
            map: map,
            drawn: function(e) {
                me._mapAreaAction.off();
                if (callback) {
                    callback(e);
                }
            }
        });
        me._mapAreaAction.on();
    }

    /**
     * 距離計測アクションOFF
     * @param {Object} map マップ
     */
    offMapDistanceAction(map) {
        const me = this;
        if (!me._mapDistanceAction) {
            return;
        }
        me._mapDistanceAction.off();
    }

    /**
     * 面積計測アクションOFF
     * @param {Object} map マップ
     */
    offMapAreaAction(map) {
        const me = this;
        if (!me._mapAreaAction) {
            return;
        }
        me._mapAreaAction.off();
    }

};
