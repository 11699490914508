<template>
    <Dialog modal v-bind:visible="visible" header="レイヤー作成" :style="{ width: '50vw', height: '80vh' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div style="height:55vh">
            <div v-if="mode === 'data-source'">
                <UxLayerDataSourceEditPanel
                    :createMode="true"
                    v-model:dataSource="dataSource" />
            </div>
            <div v-if="mode === 'layer-type'">
                <UxLayerBaseEditPanel
                    :createMode="true"
                    v-model:name="name"
                    v-model:desc="desc"
                    v-model:layerType="layerType"
                    v-model:zoomMin="zoomMin"
                    v-model:zoomMax="zoomMax"
                    v-model:geomType="dataSource.geomType" />
            </div>
            <div v-if="mode === 'option-polygon'">
                <UxLayerPolygonEditPanel
                    v-model:fillColor="options.fillColor"
                    v-model:fillOpacity="options.fillOpacity"
                    v-model:outlineColor="options.outlineColor" />
            </div>
            <div v-if="mode === 'option-point'">
                <UxLayerPointEditPanel
                    v-model:fillColor="options.fillColor"
                    v-model:outlineColor="options.outlineColor" />
            </div>
            <div v-if="mode === 'option-line'">
                <UxLayerLineEditPanel
                    v-model:lineColor="options.lineColor"
                    v-model:lineWidth="options.lineWidth" />
            </div>
            <div v-if="mode === 'option-label'">
                <UxLayerLabelEditPanel
                    v-model:dataSource="dataSource"
                    v-model:property="options.property"
                    v-model:labelColor="options.labelColor"
                    v-model:labelSize="options.labelSize"
                    v-model:labelOutlineColor="options.labelOutlineColor"
                    v-model:labelOutlineWidth="options.labelOutlineWidth"
                    v-model:xOffset="options.xOffset"
                    v-model:yOffset="options.yOffset" />
            </div>
            <div v-if="mode === 'option-icon'">
                <UxLayerIconEditPanel
                    v-model:iconImage="options.iconImage"
                    v-model:iconSize="options.iconSize"
                    v-model:xOffset="options.xOffset"
                    v-model:yOffset="options.yOffset" />
            </div>
            <div v-if="mode === 'option-choropleth'">
                <UxLayerChoroplethEditPanel
                    v-model:dataSource="dataSource"
                    v-model:property="options.property"
                    v-model:fillOpacity="options.fillOpacity"
                    v-model:ranks="options.ranks" />
            </div>
        </div>
        <template #footer>
            <UxSeparator />
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="前へ" @click="onPrev" severity="secondary" outlined :disabled="disabledPrev" />
                <Button type="button" label="次へ" @click="onNext" severity="secondary" outlined :disabled="disabledNext" />
                <Button type="button" label="OK" @click="onOK" severity="secondary" outlined :disabled="disabledOK" />
                <Button type="button" label="キャンセル" @click="onCancel" severity="secondary" outlined />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import UxSeparator from '@/ux/field/UxSeparator';
    import Button from 'primevue/button';

    import UxLayerBaseEditPanel from "@/ux/panel/UxLayerBaseEditPanel";
    import UxLayerDataSourceEditPanel from "@/ux/panel/UxLayerDataSourceEditPanel";
    import UxLayerPolygonEditPanel from "@/ux/panel/UxLayerPolygonEditPanel";
    import UxLayerPointEditPanel from "@/ux/panel/UxLayerPointEditPanel";
    import UxLayerLineEditPanel from "@/ux/panel/UxLayerLineEditPanel";
    import UxLayerLabelEditPanel from "@/ux/panel/UxLayerLabelEditPanel";
    import UxLayerIconEditPanel from "@/ux/panel/UxLayerIconEditPanel";
    import UxLayerChoroplethEditPanel from "@/ux/panel/UxLayerChoroplethEditPanel";

    import DataSourceService from '@/app/service/DataSourceService';
    import LayerService from '@/app/service/LayerService';

    export default {
        components: {
            Dialog,
            UxSeparator,
            Button,
            UxLayerDataSourceEditPanel,
            UxLayerBaseEditPanel,
            UxLayerPolygonEditPanel,
            UxLayerPointEditPanel,
            UxLayerLineEditPanel,
            UxLayerLabelEditPanel,
            UxLayerIconEditPanel,
            UxLayerChoroplethEditPanel
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                mode: 'data-source',

                name: 'レイヤ',
                desc: '',
                dataSource: null,
                layerType: null,
                zoomMin: null,
                zoomMax: null,
                options: {},
            };
        },
        watch: {
            visible(newValue) {
                const me = this;
                if (newValue) {
                    me.dataSource = null;
                    me.layerType = null;
                    me.zoomMin = null;
                    me.zoomMax = null;
                    me.options = {};
                    me.mode = 'data-source';
                }
            }
        },
        computed: {
            dataSources() {
                return this.$Service.DataSourceService.getDataSources()||[];
            },
            layerTypes() {
                const layerTypes = [];
                const geomType = this.dataSource.geomType;
                switch (geomType) {
                    case DataSourceService.GT_POLYGON:
                        layerTypes.push({ type: LayerService.LT_POLYGON, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_POLYGON) });
                        layerTypes.push({ type: LayerService.LT_LBAEL, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_LBAEL) });
                        layerTypes.push({ type: LayerService.LT_CHOROPLETH, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_CHOROPLETH) });
                        break;
                    case DataSourceService.GT_POINT:
                        layerTypes.push({ type: LayerService.LT_POINT, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_POINT) });
                        layerTypes.push({ type: LayerService.LT_LBAEL, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_LBAEL) });
                        layerTypes.push({ type: LayerService.LT_ICON, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_ICON) });
                        break;
                    case DataSourceService.GT_LINE:
                        layerTypes.push({ type: LayerService.LT_LINE, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_LINE) });
                        break;
                    default:
                        break;
                }
                return layerTypes;
            },
            disabledPrev() {
                return this.mode == 'data-source';
            },
            disabledNext() {
                if (this.mode == 'data-source' && !this.dataSource) {
                    return true;
                }
                if (this.mode == 'layer-type' && !this.layerType) {
                    return true;
                }
                return !(this.mode == 'data-source' || this.mode == 'layer-type');
            },
            disabledOK() {
                return (this.mode == 'data-source' || this.mode == 'layer-type');
            }
        },
        emits: ['change-visible', 'created'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "前へ"ボタン押下時処理
             */
            onPrev() {
                const me = this;
                switch (me.mode) {
                    case 'layer-type':
                        {
                            me.name = 'レイヤ';
                            me.desc = null;
                            me.dataSource = null;
                            me.layerType = null;
                            me.zoomMin = null;
                            me.zoomMax = null;
                            me.mode = 'data-source';
                        }
                        break;
                    default:
                        {
                            me.options = null;
                            me.mode = 'layer-type';
                        }
                        break;
                }
            },

            /**
             * "次へ"ボタン押下時処理
             */
            onNext() {
                const me = this;
                switch (me.mode) {
                    case 'data-source':
                        {
                            if (!me.dataSource) {
                                break;
                            }
                            me.mode = 'layer-type';
                        }
                        break;
                    case 'layer-type':
                        {
                            if (!me.layerType) {
                                break;
                            }
                            switch (me.layerType) {
                                case LayerService.LT_POLYGON:
                                    me.options = {
                                        fillColor:"ff0000",
                                        fillOpacity: 0.4,
                                        outlineColor: "ff0000"
                                    }
                                    me.mode = 'option-polygon';
                                    break;
                                case LayerService.LT_POINT:
                                    me.options = {
                                        fillColor:"ff0000",
                                        outlineColor: "ff0000"
                                    }
                                    me.mode = 'option-point';
                                    break;
                                case LayerService.LT_LINE:
                                    me.options = {
                                        lineColor:"ff0000",
                                        lineWidth: 1
                                    }
                                    me.mode = 'option-line';
                                    break;
                                case LayerService.LT_LABEL:
                                    me.options = {
                                        property: 'name',
                                        labelColor: "ff0000",
                                        labelSize: 10,
                                        labelOutlineColor: "ff0000",
                                        labelOutlineWidth: 1,
                                        xOffset: 0,
                                        yOffset: 0
                                    };
                                    me.mode = 'option-label';
                                    break;
                                case LayerService.LT_ICON:
                                    me.options = {
                                        iconImage: null,
                                        iconSize: 1,
                                        xOffset: 0,
                                        yOffset: 0
                                    };
                                    me.mode = 'option-icon';
                                    break;
                                case LayerService.LT_CHOROPLETH:
                                    me.options = {
                                        property: 'name',
                                        fillOpacity: 0.4,
                                        ranks: []
                                    }
                                    me.mode = 'option-choropleth';
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        break;
                }
            },

            /**
             * "OK"ボタン押下時処理
             */
            onOK() {
                // 作成内容通知
                const options = this.options;
                options.visibility = true;
                this['$emit']('created', {
                    name: this.name,
                    desc: this.desc,
                    dataSourceId: this.dataSource.id,
                    layerType: this.layerType,
                    zoomMin: this.zoomMin,
                    zoomMax: this.zoomMax,
                    options: options
                });
                // クローズ
                this.onVisible(false);
            },

            /**
             * "キャンセル"ボタン押下時処理
             */
            onCancel() {
                // クローズ
                this.onVisible(false);
            }
        }
    };
</script>

<style scoped>
</style>