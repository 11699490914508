<template>
    <Dialog modal v-bind:visible="visible" header="データリスト" :style="{ width: '90vw', height: '90vh' }" :breakpoints="{ '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div class="card flex justify-content-between">
            <div class="card flex justify-content-start gap-2 py-2 px-2">
                <Button type="button" v-tooltip.bottom="'追加'" icon="pi pi-plus" @click="onAdd" severity="secondary" outlined/>
                <Button type="button" v-tooltip.bottom="'削除'" icon="pi pi-minus" @click="onDelete" severity="secondary" outlined/>
            </div>
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onMenu" aria-haspopup="true" aria-controls="overlay_menu" severity="secondary" outlined/>
                <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
            </div>
        </div>
        <div style="height:72vh">
            <!-- <DataTable :key="renderDatas" v-model:selection="selectedDatas" :value="datas" dataKey="id" editMode="cell"
                tableClass="datalisttable-cells-table" :filters="filters" filterDisplay="row"
                scrollable scrollHeight="auto" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                <Column selectionMode="multiple" headerStyle="width: 40px">
                </Column>
                <Column v-for="(property) of properties" :key="property.id" :field="property.key" :header="property.name" sortable>
                    <template #body="slotProps">
                        {{slotProps.data.properties[property.key]}}
                    </template>
                    <template #editor="slotProps" v-if="property.key != 'id'">
                        <InputText v-if="property.type == 'string'" v-model="slotProps.data.properties[property.key]" />
                        <InputNumber v-if="property.type == 'number'" v-model="slotProps.data.properties[property.key]" />
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search" />
                    </template>
                </Column>
            </DataTable> -->
            <DataTable :key="renderDatas" v-model:selection="selectedDatas" :value="datas" dataKey="id" editMode="cell"
                tableClass="datalisttable-cells-table"
                scrollable scrollHeight="auto" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                <Column selectionMode="multiple" headerStyle="width: 40px">
                </Column>
                <Column v-for="(property) of properties" :key="property.id" :field="property.key" :header="property.name">
                    <template #body="slotProps">
                        {{slotProps.data.properties[property.key]}}
                    </template>
                    <template #editor="slotProps" v-if="property.key != 'id'">
                        <InputText v-if="property.type == 'string'" v-model="slotProps.data.properties[property.key]" />
                        <InputNumber v-if="property.type == 'number'" v-model="slotProps.data.properties[property.key]" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>
    <UxFileWindow :visible="importFileWindowVisible" v-on:change-visible="onImportFileWindowVisible" v-on:upload-file="onImportFile" />
</template>

<script>
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';
    import Menu from 'primevue/menu';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';

    import UxFileWindow from "@/ux/window/UxFileWindow";

    import { FilterMatchMode } from 'primevue/api';

    export default {
        components: {
            Dialog,
            Button,
            Menu,
            DataTable,
            Column,
            InputText,
            InputNumber,
            UxFileWindow
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },

            dataSource: {
                type: Object,
                default: function() { return {}; }
            },
        },
        data() {
            return {
                menuItems: [
                    {
                        label: 'インポート',
                        icon: 'pi pi-file-import',
                        command: () => { this.onImport(); }
                    },
                    {
                        label: 'エクスポート',
                        icon: 'pi pi-file-export',
                        command: () => { this.onExport(); }
                    }
                ],
                selectedDatas: null,
                importFileWindowVisible: false,
                renderDatas: 0
            };
        },
        created() {
        },
        computed: {
            datas() {
                return this.$Service.DataSourceService.getDatas(this.$MapConfig.map, this.dataSource.id)||[];
            },
            properties() {
                return (this.dataSource||{}).attributes||[];
            },
            filters() {
                const filters = {};
                for (const attribute of (this.dataSource||{}).attributes||[]) {
                    if (attribute.type == 'string') {
                        filters[attribute.key] = { value: null, matchMode: FilterMatchMode.CONTAINS }
                    }
                    if (attribute.type == 'number') {
                        filters[attribute.key] = { value: null, matchMode: FilterMatchMode.EQUALS }
                    }
                }
                return filters;
            },
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "インポートファイル選択ウィンドウ表示切替"イベント時処理
             */
            onImportFileWindowVisible(visible) {
                this.importFileWindowVisible = visible;
            },

            /**
             * "ファイルアップロード"イベント時処理
             */
            onImportFile(files) {
                const me = this;

                // データ追加
                this.$Service.DataSourceService.importDataFile(this.$MapConfig.map, this.dataSource.id, files[0], function() {
                    // 画面クローズ
                    setTimeout(() => {
                        me.importFileWindowVisible = false;
                    }, 1000);
                    me.redrawDataComponent();
                });
            },

            /**
             * 行選択時処理
             */
            onRowSelect() {
            },

            /**
             * 行選択解除時処理
             */
            onRowUnselect() {
            },

            /**
             * メニュー選択時処理
             */
            onMenu(event) {
                this.$refs.menu.toggle(event);
            },

            /**
             * データ追加時処理
             */
            onAdd() {
                this.$Service.DataSourceService.addData(this.$MapConfig.map, this.dataSource.id,
                    this.$Service.DataSourceService.createFeature(this.dataSource.geomType, {
                        name: 'データ'
                    })
                );
                this.redrawDataComponent();
            },

            /**
             * データ削除時処理
             */
            onDelete() {
                if (!this.selectedDatas) {
                    return;
                }
                this.$Service.DataSourceService.removeDatas(this.$MapConfig.map, this.dataSource.id, this.selectedDatas);
                this.redrawDataComponent();
            },

            /**
             * インポート時処理
             */
            onImport() {
                this.importFileWindowVisible = true;
            },

            /**
             * エクスポート時処理
             */
            onExport() {
                this.$Service.DataSourceService.exportDataFile(this.dataSource.id);
            },

            /**
             * データコンポーネント再描画
             */
            redrawDataComponent() {
                this.renderDatas = this.renderDatas + 1;
            }
        }
    };
</script>

<style scoped>
</style>