<template>
    <main>
        <div class="main-panel card flex justify-content-center">
            <slot></slot>
        </div>
    </main>
</template>

<script>
    export default {
        components: {
        }
    };
</script>

<style scoped>
    .main-panel {
        width: 100%;
        height: calc(100vh - 60px);
        box-sizing: border-box;
    }
</style>
