/**
 * HTMLユーティリティ
 */
export default class HtmlUtils {

    /**
     * ページ移動
     * @param {String} url URL
     * @param {String} target 対象
     */
    static movePage(url, target='_blank', rel='nofollow noopener noreferrer') {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.target = target;
        a.rel = rel;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    }
};
