<template>
    <div>
        <ExFieldset legend="URL" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-7rem mt-2">ＵＲＬ：</span>
                <InputText type="text" v-model="selectedRequestUrl" placeholder="URL" class="w-full" @input="this.$emit('update:requestUrl', this.selectedRequestUrl)" />
            </div>
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-6rem mt-2">手法：</span>
                <Dropdown v-model="selectedRequestMethod" :options="[{type:'GET'},{type:'POST'},{type:'PUT'},{type:'DELETE'}]"
                    optionLabel="type" placeholder="METHOD" class="w-8rem" @change="this.$emit('update:requestMethod', this.selectedRequestMethod.type)" />
            </div>
        </ExFieldset>
        <ExFieldset legend="HEADER" :toggleable="true" :collapsed="true" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-6rem mt-2">パラメータ：</span>
                <Textarea v-model="selectedRequestHeader" rows="5" cols="30" maxlength="100" placeholder="最大100文字まで入力できます"
                    @input="this.$emit('update:requestHeader', this.selectedRequestHeader)" />
            </div>
            <div class="px-8 mx-5 py-2">{{ (this.selectedRequestHeader||'').length }} / 100</div>
        </ExFieldset>
        <ExFieldset legend="BODY" :toggleable="true" :collapsed="true" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-6rem mt-2">パラメータ：</span>
                <Textarea v-model="selectedRequestBody" rows="5" cols="30" maxlength="100" placeholder="最大100文字まで入力できます"
                    @input="this.$emit('update:requestBody', this.selectedRequestBody)" />
            </div>
            <div class="px-8 mx-5 py-2">{{ (this.selectedRequestBody||'').length }} / 100</div>
        </ExFieldset>
        <ExFieldset legend="レスポンス" :toggleable="true" :collapsed="true" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-6rem mt-2">プロパティ：</span>
                <InputText type="text" v-model="selectedResponseProperty" placeholder="プロパティ" class="w-full md:w-20rem"
                    @input="this.$emit('update:responseProperty', this.selectedResponseProperty)" />
            </div>
            <div class="card flex justify-content-left gap-2 py-2 px-3 mx-8">
                ※Geojson形式のプロパティを指定（指定なしはそのままの値を使用）
            </div>
        </ExFieldset>
        <div class="card flex justify-content-end gap-2 py-2">
            ※作成後はデータの追加／編集／削除ができません
        </div>
    </div>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import Dropdown from 'primevue/dropdown';
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';

    export default {
        components: {
            ExFieldset,
            Dropdown,
            InputText,
            Textarea
        },
        props: {
            requestUrl: {
                type: String,
                default: null
            },
            requestMethod: {
                type: String,
                default: null
            },
            requestHeader: {
                type: String,
                default: null
            },
            requestBody: {
                type: String,
                default: null
            },
            responseProperty: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                selectedRequestUrl: this.requestUrl,
                selectedRequestMethod: {type:this.requestMethod||'GET'},
                selectedRequestHeader: this.requestHeader,
                selectedRequestBody: this.requestBody,
                selectedResponseProperty: this.responseProperty
            };
        },
        emits: [
            'update:requestUrl',
            'update:requestMethod',
            'update:requestHeader',
            'update:requestBody',
            'update:responseProperty',
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>