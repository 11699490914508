<template>
    <div class="card">
        <ExTabView>
            <TabPanel header="計測">
                <div class="card flex justify-content-left m-4">
                    <span class="w-8rem mt-2">距離：</span>
                    <ToggleButton v-model="distanceMode" onLabel="ON" offLabel="OFF" class="w-8rem" v-on:change="onChangeDistanceMode" />
                </div>
                <div class="card flex justify-content-left m-4">
                    <span class="w-8rem mt-2">面積：</span>
                    <ToggleButton v-model="areaMode" onLabel="ON" offLabel="OFF" class="w-8rem" v-on:change="onChangeAreaMode" />
                </div>
            </TabPanel>
            <TabPanel header="説明">
                <div class="card py-2 px-2">
                    <br>
                    地図上で「距離」や「面積」の計測が行えます。<br>
                    <br>
                    <span style="font-weight: bold">＜距離＞</span><br>
                    <br>
                    １．「ON」にします。<br>
                    <br>
                    ２．地図上で開始地点をクリックします。<br>
                    <br>
                    ３．次点をクリックすると距離が表示されます。<br>
                    <br>
                    <span style="font-weight: bold">＜面積＞</span><br>
                    <br>
                    １．「ON」にします。<br>
                    <br>
                    ２．地図上で開始地点をクリックします。<br>
                    <br>
                    ３．次点をクリックしていくと面積が表示されます。<br>
                </div>
            </TabPanel>
        </ExTabView>
    </div>
</template>

<script>
    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import ToggleButton from 'primevue/togglebutton';

    export default {
        components: {
            ExTabView,
            TabPanel,
            ToggleButton
        },
        props: {
        },
        data() {
            return {
                distanceMode: false,
                areaMode: false
            }
        },
        created() {
        },
        computed: {
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        methods: {

            /**
             * "距離モード切替"イベント時処理
             */
            onChangeDistanceMode() {
                const me = this;
                me.areaMode = false;
                me.$Service.MeasureService.offMapDistanceAction(me.$MapConfig.map);
                me.$Service.MeasureService.offMapAreaAction(me.$MapConfig.map);
                if (me.distanceMode) {
                    me.$Service.MeasureService.onMapDistanceAction(me.$MapConfig.map, function(/*e*/) {
                        me.onChangeDistanceMode(true);
                    });
                }
            },

            /**
             * "面積モード切替"イベント時処理
             */
            onChangeAreaMode() {
                const me = this;
                me.distanceMode = false;
                me.$Service.MeasureService.offMapDistanceAction(me.$MapConfig.map);
                me.$Service.MeasureService.offMapAreaAction(me.$MapConfig.map);
                if (me.areaMode) {
                    me.$Service.MeasureService.onMapAreaAction(me.$MapConfig.map, function(/*e*/) {
                        me.onChangeAreaMode(true);
                    });
                }
            }
        }
    };
</script>

<style scoped>
</style>
