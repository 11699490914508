import ValidateUtils from '@/app/util/validate-utils.mjs';

/**
 * オブジェクトユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class ObjectUtils {

	/**
	 * オブジェクトのプロパティを再帰適用（オブジェクトのプロパティに存在するもののみ結合）
	 * @param {Object} obj オブジェクト
	 * @param {Object} target 対象
	 * @returns {Object} オブジェクト
	 */
    static apply(obj, target) {
		if (ObjectUtils.isNotNone(obj) && ObjectUtils.isNotNone(target) && ObjectUtils.isObject(target)) {
			let p = null;
			for (p in target) {
				if (!(p in obj)) {
					continue;
				}
				if (ObjectUtils.isNotNone(target[p]) && ObjectUtils.isObject(target[p])) {
					if (obj[p]) {
						ObjectUtils.apply(obj[p], target[p]);
					} else {
						obj[p] = target[p];
					}
				} else {
					obj[p] = target[p];
				}
			}
		}
		return obj;
	}

	/**
	 * オブジェクトのプロパティを再帰適用（オブジェクトのプロパティに存在しないものも結合）
	 * @param {Object} obj オブジェクト
	 * @param {Object} target 対象
	 * @returns {Object} オブジェクト
	 */
    static merge(obj, target) {
		if (ObjectUtils.isNotNone(obj) && ObjectUtils.isNotNone(target) && ObjectUtils.isObject(target)) {
			let p = null;
			for (p in target) {
				if (ObjectUtils.isNotNone(target[p]) && ObjectUtils.isObject(target[p])) {
					if (obj[p]) {
						ObjectUtils.merge(obj[p], target[p]);
					} else {
						obj[p] = target[p];
					}
				} else {
					obj[p] = target[p];
				}
			}
		}
		return obj;
	}

	/**
	 * オブジェクトの再帰検索
	 * @param {Object} obj オブジェクト
	 * @param {Function} callback コールバック関数
	 */
    static find(obj, callback, key=null, index=-1) {
		if (ObjectUtils.isNotNone(obj)) {
			if (ObjectUtils.isObject(obj)) {
				for (const p in obj) {
					ObjectUtils.find(obj[p], callback, p, index);
				}
			} else if (ObjectUtils.isArray(obj)) {
				for (let i = 0; i < obj.length; i++) {
					ObjectUtils.find(obj[i], callback, key, i);
				}
			} else {
				callback(key, obj, index);
			}
		}
	}

	/**
	 * 文字列変換
	 * @param {Object} v オブジェクト
	 * @param {String} noneStr 値なしの際の文字列
	 * @returns {String} 文字列
	 */
	static toString(v, noneStr) {
		if (ObjectUtils.isNone(v)) {
			return ObjectUtils.isNone(noneStr) ? '' : noneStr;
		}
		if (ObjectUtils.isString(v)) {
			return v;
		}
		if (ObjectUtils.isNumber(v)) {
			return String(v);
		}
		if (ObjectUtils.isBool(v)) {
			return v ? 'true' : 'false';
		}
		if (ObjectUtils.isBlob(v)) {
			return v.text();
		}
		if (ObjectUtils.isObject(v) || ObjectUtils.isArray(v)) {
			return ObjectUtils.toJson(v);
		}
		return String(v);
	}

	/**
	 * オブジェクトの値がなしの場合デフォルト値を使用する
	 * @param {Object} v オブジェクト
	 * @returns {Object} 値
	 */
	static defautIfNone(v, def) {
		return ObjectUtils.isNone(v) ? def : v;
	}

	/**
	 * オブジェクトの値が空の場合デフォルト値を使用する
	 * @param {Object} v オブジェクト
	 * @returns {Object} 値
	 */
	static defautIfEmpty(v, def) {
		return ObjectUtils.isEmpty(v) ? def : v;
	}

	/**
	 * オブジェクトの値がなしか判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isNone(v) {
		return v === null || v === undefined;
	}

	/**
	 * オブジェクトの値がなしか判定（NOT）
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isNotNone(v) {
		return !ObjectUtils.isNone(v);
	}

	/**
	 * オブジェクトが空か判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isEmpty(v) {
		if (ObjectUtils.isNone(v)) {
			return true;
		}
		if (ObjectUtils.isString(v)) {
			return (v === '' || v.length == 0);
		}
		if (ObjectUtils.isNumber(v)) {
			return (v === 0);
		}
		if (ObjectUtils.isBool(v)) {
			return (v === false);
		}
		if (ObjectUtils.isBlob(v)) {
			return (v.length == 0);
		}
		if (ObjectUtils.isArray(v)) {
			return (v.length == 0);
		}
		if (ObjectUtils.isObject(v)) {
			return (Object.keys(v).length == 0);
		}
		return false;
	}

	/**
	 * オブジェクトが空でないか判定（NOT）
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isNotEmpty(v) {
		return !ObjectUtils.isEmpty(v);
	}

	/**
	 * オブジェクトか判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isObject(v) {
		return ObjectUtils.isNone(v) || ((typeof v == "object" || v instanceof Object) && !(v instanceof Array));
	}

	/**
	 * オブジェクトが配列か判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isArray(v) {
		return ObjectUtils.isNone(v) || Array.isArray(v)/* || (typeof v === "object" || v instanceof Array)*/;
	}

	/**
	 * オブジェクトが文字列か判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isString(v) {
		return ObjectUtils.isNone(v) || (typeof v === "string" || v instanceof String);
	}

	/**
	 * オブジェクトが数値か判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isNumber(v) {
		return ObjectUtils.isNone(v) || (typeof v === "number" || v instanceof Number);
	}

	/**
	 * オブジェクトが真偽値か判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isBool(v) {
		return ObjectUtils.isNone(v) || (typeof v === "boolean" || v instanceof Boolean);
	}

	/**
	 * オブジェクトがバイナリか判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isBlob(v) {
		return ObjectUtils.isNone(v) || (typeof v === "blob" || v instanceof Blob);
	}

	/**
	 * オブジェクトがエラーか判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isError(v) {
		return ObjectUtils.isNone(v) || (typeof v === "error" || v instanceof Error);
	}

	/**
	 * オブジェクトが関数か判定
	 * @param {Object} v オブジェクト
	 * @returns {Boolean} true or false
	 */
	static isFunction(v) {
		return ObjectUtils.isNone(v) || (typeof v === "function" || v instanceof Function);
	}

	/**
	 * オブジェクト⇒JSON
	 * @param {Object} obj オブジェクト
	 * @param {Boolean} clean 整形フラグ
	 * @returns {String} JSON文字列
	 */
	static toJson(obj, clean=false) {
		if (ObjectUtils.isNone(obj)) {
			return obj;
		}
		return clean ? JSON.stringify(obj, null, "\t") : JSON.stringify(obj);
	}

	/**
	 * JSON⇒オブジェクト
	 * @param {String} jsonText JSON文字列
	 * @returns {Object} オブジェクト
	 */
	static fromJson(jsonText) {
		ValidateUtils.validateString(jsonText);
		if (ObjectUtils.isNone(jsonText)) {
			return jsonText;
		}
		return JSON.parse(jsonText);
	}

	/**
	 * プロパティ存在判定
	 * @param {String} key プロパティ名
	 * @returns {Boolean} true or false
	 */
	static existProperty(obj, name) {
		if (ObjectUtils.isNone(obj)) {
			return false;
		}
		return (name in obj);
	}

}
export default ObjectUtils;