<template>
    <div class="card">
        <DataTable :value="data" selectionMode="single" dataKey="properties.name" :metaKeySelection="false"
            @rowSelect="onRowSelect" @rowUnselect="onRowUnselect" scrollable scrollHeight="auto" tableStyle="">
            <Column field="properties.name" header="市区町村"></Column>
        </DataTable>
    </div>
</template>

<script>
    import axios from 'axios';
    import * as turf from '@turf/turf';

    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    export default {
        components: {
            DataTable,
            Column
        },
        props: {
            pref: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                data: []
            }
        },
        created() {
            if (!this.pref) {
                return;
            }
            axios.get('./data/map/' + this.pref + '.geojson')
            .then(response => {
                this.data = response.data.features;
            })
            .catch(error => {
                console.log(error);
            });
        },
        methods: {
            onRowSelect(event) {
                const bbox = turf.bbox(event.data);
                this.$MapConfig.map.fitBounds(bbox);
                this['$emit']('select', event.data.properties.name);
            },
            onRowUnselect(event) {
                alert('Name: ' + event.data.properties.name);
            }
        }
    };
</script>

<style scoped>
</style>
