import ObjectUtils from '@/app/util/object-utils.mjs';
import StringUtils from '@/app/util/string-utils.mjs';
import ValidateUtils from '@/app/util/validate-utils.mjs';

/**
 * バイナリユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class BinaryUtils {

	/**
	 * 配列が空か判定
	 * @param {Buffer} buffer バイナリ
	 * @returns {Boolean} true or false
	 */
	static isEmpty(buffer) {
		ValidateUtils.validateObject(buffer);
		return ObjectUtils.isNone(buffer);
	}

	/**
	 * 配列が空でないか判定
	 * @param {Buffer} buffer バイナリ
	 * @returns {Boolean} true or false
	 */
	static isNotEmpty(buffer) {
		return !BinaryUtils.isEmpty(buffer);
	}

	/**
	 * コピー
	 * @param {Buffer} buffer バイナリ
	 * @returns {Buffer} バイナリ
	 */
    static copy(buffer) {
		const targetBuffer = Buffer.alloc(buffer.length);
		buffer.copy(targetBuffer);
		return buffer;
	}

	/**
	 * 初期化
	 * @param {Number} length データ長
	 * @returns {Buffer} バイナリ
	 */
    static alloc(length=256) {
		return Buffer.alloc(length);
	}

	/**
	 * テキスト⇒バイナリ
	 * @param {String} text テキスト
	 * @param {String} encoding 文字コード
	 * @returns {Buffer} バイナリ
	 */
    static textToBinary(text, encoding='utf-8') {
		if (StringUtils.isEmpty(text)) {
			return Buffer.alloc(0);
		}
		return Buffer.from(text, encoding);
	}

	/**
	 * BASE64テキスト⇒バイナリ
	 * @param {String} text BASE64テキスト
	 * @returns {Buffer} バイナリ
	 */
    static base64TextToBinary(text) {
		return BinaryUtils.textToBinary(text, 'base64');
	}

	/**
	 * バイナリ⇒テキスト
	 * @param {Buffer} buffer バイナリ
	 * @param {String} encoding 文字コード
	 * @returns {String} テキスト
	 */
    static binaryToText(buffer, encoding='utf-8') {
		if (BinaryUtils.isEmpty(buffer)) {
			return '';
		}
		return buffer.toString(encoding);
	}

	/**
	 * バイナリ⇒BASE64テキスト
	 * @param {Buffer} buffer バイナリ
	 * @returns {String} BASE64テキスト
	 */
    static binaryToBase64Text(buffer) {
		return BinaryUtils.binaryToText(buffer, 'base64');
	}

	/**
	 * バイナリ抽出
	 * @param {Buffer} buffer バイナリ
	 * @param {Number} offset オフセット
	 * @param {Number} length データ長
	 * @returns {Buffer} バイナリ
	 */
    static slice(buffer, offset, length) {
		if (BinaryUtils.isEmpty(buffer)) {
			return buffer;
		}
		return buffer.subarray(offset, offset + length);
	}

	/**
	 * バイナリ結合
	 * @param {Buffer} buffer1 バイナリ１
	 * @param {Buffer} buffer2 バイナリ２
	 * @returns {Buffer} バイナリ
	 */
    static concat(buffer1, buffer2) {
		if (BinaryUtils.isEmpty(buffer1)) {
			return buffer2;
		}
		if (BinaryUtils.isEmpty(buffer2)) {
			return buffer1;
		}
		return Buffer.concat([buffer1, buffer2]);
	}

}
export default BinaryUtils;