import AbstractMapDrawAction from "@/app/core/mapaction/AbstractMapDrawAction.js";

/**
 * 地図作図（ポイント）アクションクラス
 * @author t.kimu
 * @since 1.0
 * @version 1.0
 * Copyright(c) 2022, Nippon Statistics Center Corp.
 */
export default class MapDrawPointAction extends AbstractMapDrawAction {

	/** 描画コールバック */
	drawn = null;

	/**
	 * コンストラクタ
	 * @param {Object} config コンフィグ
	 */
	constructor(config={}) {
		super(config);
		if (config.drawn !== undefined) {
			this.drawn = config.drawn;
		}
	}

	/**
	 * モードON
	 */
	on() {
		const me = this;
		if (me.equalMode(me.mode.ON)) {
			return;
		}
		me.setDrawMode(me.drawmode.DRAW_POINT, me.mode.ON, me.drawn);
	}

	/**
	 * モードOFF
	 */
	off() {
		const me = this;
		if (me.equalMode(me.mode.OFF)) {
			return;
		}
		me.setDrawMode(me.drawmode.DRAW_POINT, me.mode.OFF);
	}
}