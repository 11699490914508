import ObjectUtils from '@/app/util/object-utils.mjs';
import ValidateUtils from '@/app/util/validate-utils.mjs';

/**
 * 配列ユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class ArrayUtils {

	/**
	 * 配列が空か判定
	 * @param {Array<Object>} arr 配列
	 * @returns {Boolean} true or false
	 */
	static isEmpty(arr) {
		ValidateUtils.validateArray(arr);
		return ObjectUtils.isNone(arr) || arr.length === 0;
	}

	/**
	 * 配列が空でないか判定
	 * @param {Array<Object>} arr 配列
	 * @returns {Boolean} true or false
	 */
	static isNotEmpty(arr) {
		return !ArrayUtils.isEmpty(arr);
	}

	/**
	 * ループ処理
	 * @param {Array<Object>} arr 配列
	 * @param {Function} callback コールバック関数
	 */
    static forEach(arr, callback) {
		ValidateUtils.validateArray(arr);
		for (let i = 0; i < (arr||[]).length; i++) {
			if (!callback(arr[i])) {
				return;
			}
		}
	}

	/**
	 * 変換処理
	 * @param {Array<Object>} arr 配列
	 * @param {Function} callback コールバック関数
	 */
    static convert(arr, callback) {
		ValidateUtils.validateArray(arr);
		return (arr||[]).map(callback);
	}

	/**
	 * 検索
	 * @param {Array<Object>} arr 配列
	 * @param {Function} comparator 比較関数
	 * @returns {Number} 検索位置のインデックス番号
	 */
    static find(arr, comparator) {
		ValidateUtils.validateArray(arr);
		for (let i = 0; i < (arr||[]).length; i++) {
			if (ObjectUtils.isNotNone(comparator) && ObjectUtils.isFunction(comparator)) {
				if (comparator(arr[i])) {
					return i;
				}
			} else {
				if (comparator == arr[i]) {
					return i;
				}
			}
		}
		return -1;
	}


	/**
	 * 存在判定
	 * @param {Array<Object>} arr 配列
	 * @param {Function} comparator 比較関数
	 * @returns {Boolean} true or false
	 */
    static contains(arr, comparator) {
		ValidateUtils.validateArray(arr);
		for (let i = 0; i < (arr||[]).length; i++) {
			if (ObjectUtils.isNotNone(comparator) && ObjectUtils.isFunction(comparator)) {
				if (comparator(arr[i])) {
					return true;
				}
			} else {
				if (comparator == arr[i]) {
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * 配列追加
	 * @param {Array<Object>} base ベース配列
	 * @param {Array<Array<Object>>} arr 配列リスト
	 * @returns {Array<Object>} 処理後配列
	 */
    static add(base, ...arr) {
		for (const items of arr||[]) {
			if (ObjectUtils.isArray(items)) {
				for (const item of items) {
					base.push(item);
				}
			} else {
				base.push(items);
			}
		}
		return base;
	}

	/**
	 * 配列結合
	 * @param {Array<Array<Object>>} arr 配列リスト
	 * @returns {Array<Object>} 処理後配列
	 */
    static union(...arr) {
		let all = [];
		for (const a of arr||[]) {
			if (ObjectUtils.isArray(a)) {
				ArrayUtils.add(all, a||[]);
			} else {
				all.push(a);
			}
		}
		return all;
	}

	/**
	 * 重複除去
	 * @param {Array<Object>} arr 配列
	 * @returns {Array<Object>} 処理後配列
	 */
    static toUniq(arr) {
		ValidateUtils.validateArray(arr);
		return [...new Set(arr||[])];
	}

	/**
	 * 空を除外
	 * @param {Array<Object>} arr 配列
	 * @returns {Array<Object>} 処理後配列
	 */
    static excludeEmpty(arr) {
		ValidateUtils.validateArray(arr);
		const items = [];
		for (let i = 0; i < (arr||[]).length; i++) {
			if (ObjectUtils.isEmpty(arr[i])) {
				continue;
			}
			items.push(arr[i]);
		}
		return items;
	}

	/**
	 * 反転
	 * @param {Array<Object>} arr 配列
	 * @returns {Array<Object>} 処理後配列
	 */
    static reverse(arr) {
		ValidateUtils.validateArray(arr);
		arr.reverse();
		return arr;
	}

	/**
	 * 並替
	 * @param {Array<Object>} arr 配列
	 * @param {Function} comparator 比較関数
	 * @returns {Array<Object>} 処理後配列
	 */
    static sort(arr, comparator) {
		ValidateUtils.validateArray(arr);
		if (ObjectUtils.isNotNone(comparator)) {
			arr.sort(comparator);
		} else {
			arr.sort();
		}
		return arr;
	}

	/**
	 * 並替（昇順）
	 * @param {Array<Object>} arr 配列
	 * @returns {Array<Object>} 処理後配列
	 */
    static sortAsc(arr) {
		ValidateUtils.validateArray(arr);
		arr.sort(function (a, b) {
			if (a > b) return 1;
			else if (b > a) return -1;
			else return 0;
		});
		return arr;
	}

	/**
	 * 並替（降順）
	 * @param {Array<Object>} arr 配列
	 * @returns {Array<Object>} 処理後配列
	 */
    static sortDesc(arr) {
		ValidateUtils.validateArray(arr);
		arr.sort(function (a, b) {
			if (a > b) return -1;
			else if (b > a) return 1;
			else return 0;
		});
		return arr;
	}
}
export default ArrayUtils;