<template>
    <ExFieldset legend="ライン" :toggleable="false" class="m-2">
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">線色：</span>
            <ColorPicker class="mt-1" v-model="selectedLineColor" inputId="color" format="hex" @change="this.$emit('update:lineColor', this.selectedLineColor)" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">線幅：</span>
            <InputNumber v-model="selectedLineWidth" inputId="minmax" :min="0" :max="10" mode="decimal" showButtons
                placeholder="線幅（0 ～ 10 px）" @input="onInputLineWidth" />
        </div>
    </ExFieldset>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import ColorPicker from 'primevue/colorpicker';
    import InputNumber from 'primevue/inputnumber';

    export default {
        components: {
            ExFieldset,
            ColorPicker,
            InputNumber
        },
        props: {
            lineColor: {
                type: String,
                default: "ff0000"
            },
            lineWidth: {
                type: Number,
                default: 1
            },
        },
        data() {
            return {
                selectedLineColor: this.lineColor,
                selectedLineWidth: this.lineWidth
            };
        },
        emits: [
            'update:lineColor',
            'update:lineWidth'
        ],
        methods: {
            /**
             * "線幅"イベント時処理
             */
            onInputLineWidth(event) {
                this.selectedLineWidth = event.value;
                this.$emit('update:lineWidth', this.selectedLineWidth);
            }
        }
    };
</script>

<style scoped>
</style>