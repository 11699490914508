<template>
    <Dialog modal v-bind:visible="visible" header="領域集計" :style="{ width: '90vw', height: '90vh' }" :breakpoints="{ '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div class="card flex justify-content-between">
            <div class="card flex justify-content-start gap-2 py-2 px-2">
                <Dropdown v-model="selectedDataSource" :options="dataSources" optionLabel="name" placeholder="データソース選択" class="w-full md:w-16rem" />
                <Button type="button" label="集計" icon="pi pi-calculator" @click="onSummary" severity="secondary" outlined/>
            </div>
        </div>
        <div style="height:72vh">
            <DataTable :key="renderDatas" :value="datas" scrollable scrollHeight="auto">
                <Column field="name" header="項目"></Column>
                <Column v-for="(zoneData) of zoneDatas" :key="zoneData.id" :field="zoneData.id" :header="zoneData.name">
                </Column>
            </DataTable>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';
    import Dropdown from 'primevue/dropdown';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    import DataUtils from "@/app/util/data-utils.mjs";

    import DataSourceService from '@/app/service/DataSourceService';

    export default {
        components: {
            Dialog,
            Button,
            Dropdown,
            DataTable,
            Column
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },

            zones: {
                type: Object,
                default: function() { return {}; }
            },
        },
        data() {
            return {
                selectedDataSource: null,
                datas: [],
                renderDatas: 0
            };
        },
        created() {
        },
        computed: {
            dataSources() {
                return this.$Service.DataSourceService.getDataSources()||[];
            },
            zoneDatas() {
                const datas = [];
                for (let i = 0; i < this.zones.length; i++) {
                    const zone = this.zones[i];
                    datas.push({
                        id: `${DataUtils.generateId()}${i}`,
                        name: zone.name,
                        polygon: zone.polygon
                    });
                }
                return datas;
            }
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * 集計時処理
             */
            onSummary() {
                if (!this.selectedDataSource || !this.$Service.DataSourceService.isEditableDataSource(this.selectedDataSource)) {
                    return;
                }
                const dataSource = this.selectedDataSource;
                const fields = [];
                for (const attribute of dataSource.attributes) {
                    if (attribute.type != 'number') {
                        continue;
                    }
                    fields.push(attribute.key);
                }
                const dataMap = {};
                for (const zoneData of this.zoneDatas) {
                    dataMap[zoneData.id] = {};
                    const polygon = zoneData.polygon;
                    let summaries = [];
                    switch (dataSource.geomType) {
                    case DataSourceService.GT_POLYGON:
                        summaries = this.$Service.MapDataService.aggregatePolygonData(polygon, dataSource.data, fields);
                        break;
                    case DataSourceService.GT_POINT:
                        summaries = this.$Service.MapDataService.aggregatePointData(polygon, dataSource.data, fields);
                        break;
                    default:
                        break;
                    }
                    for (const key in summaries) {
                        dataMap[zoneData.id][key] = summaries[key];
                    }
                }
                const datas = [];
                for (const field of fields) {
                    const data = {};
                    data['name'] = field;
                    for (const key in dataMap) {
                        data[key] = dataMap[key][field];
                    }
                    datas.push(data);
                }
                this.datas = datas;
            },

            /**
             * データコンポーネント再描画
             */
            redrawDataComponent() {
                this.renderDatas = this.renderDatas + 1;
            }
        }
    };
</script>

<style scoped>
</style>