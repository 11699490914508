<template>
    <Dialog modal v-bind:visible="visible" header="データソース" :style="{ width: '90vw', height: '90vh' }" :breakpoints="{ '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div class="card flex justify-content-between">
            <div class="card flex justify-content-start gap-2 py-2 px-2">
                <Button type="button" v-tooltip.bottom="'追加'" icon="pi pi-plus" @click="onAdd" severity="secondary" outlined/>
                <Button type="button" v-tooltip.bottom="'削除'" icon="pi pi-minus" @click="onDelete" severity="secondary" outlined/>
                <Button type="button" v-tooltip.bottom="'編集'" icon="pi pi-pencil" @click="onEdit" severity="secondary" outlined/>
            </div>
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onMenu" aria-haspopup="true" aria-controls="overlay_menu" severity="secondary" outlined/>
                <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
            </div>
        </div>
        <div style="height:72vh">
            <DataTable :key="renderDataSources" v-model:selection="selectedDataSource" :value="dataSources" dataKey="id" editMode="cell"
                @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table"
                scrollable scrollHeight="auto" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                <Column selectionMode="single" headerStyle="width: 40px">
                </Column>
                <Column field="name" header="名称">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" />
                    </template>
                </Column>
                <Column field="geomType" header="ジオメトリ種別" headerStyle="width: 160px;" bodyStyle="text-align:center">
                    <template #body="{ data, field }">
                        {{ this.$Service.DataSourceService.getGeomTypeLabel(data[field]) }}
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>
    <UxFileWindow :visible="importFileWindowVisible" v-on:change-visible="onImportFileWindowVisible" v-on:upload-file="onImportFile" />
    <UxDataSourceCreateWizard :visible="dataSourceCreateWindowVisible" v-on:change-visible="onDataSourceCreateWindowVisible" v-on:created="onCreatedDataSource" />
    <UxDataSourceEditWindow :visible="dataSourceEditWindowVisible" v-on:change-visible="onDataSourceEditWindowVisible"
        :selectedDataSource="selectedDataSource" v-on:created="onCreatedDataSource" v-on:edited="onEditedDataSource" />
</template>

<script>
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import Menu from 'primevue/menu';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    import UxFileWindow from "@/ux/window/UxFileWindow";
    import UxDataSourceEditWindow from "@/ux/window/UxDataSourceEditWindow";
    import UxDataSourceCreateWizard from "@/ux/window/UxDataSourceCreateWizard";

    export default {
        components: {
            Dialog,
            Button,
            InputText,
            Menu,
            DataTable,
            Column,
            UxFileWindow,
            UxDataSourceEditWindow,
            UxDataSourceCreateWizard
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                menuItems: [
                    {
                        label: 'インポート',
                        icon: 'pi pi-file-import',
                        command: () => { this.onImport(); }
                    },
                    {
                        label: 'エクスポート',
                        icon: 'pi pi-file-export',
                        command: () => { this.onExport(); }
                    }
                ],
                selectedDataSource: null,
                importFileWindowVisible: false,
                dataSourceCreateWindowVisible: false,
                dataSourceEditWindowVisible: false,
                renderDataSources: 0
            };
        },
        created() {
            this.$Service.DataSourceService.setComponent(this);
        },
        computed: {
            dataSources() {
                return this.$Service.DataSourceService.getDataSources()||[];
            }
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "インポートファイル選択ウィンドウ表示切替"イベント時処理
             */
            onImportFileWindowVisible(visible) {
                this.importFileWindowVisible = visible;
            },

            /**
             * "データソース作成ウィンドウ表示切替"イベント時処理
             */
            onDataSourceCreateWindowVisible(visible) {
                this.dataSourceCreateWindowVisible = visible;
            },

            /**
             * "データソース編集ウィンドウ表示切替"イベント時処理
             */
            onDataSourceEditWindowVisible(visible) {
                this.dataSourceEditWindowVisible = visible;
            },

            /**
             * "ファイルアップロード"イベント時処理
             */
            onImportFile(files) {
                const me = this;

                // データ追加
                this.$Service.DataSourceService.importFile(this.$MapConfig.map, files[0], function() {
                    // 画面クローズ
                    setTimeout(() => {
                        me.importFileWindowVisible = false;
                    }, 1000);
                    me.redrawDataSourceComponent();
                });
            },

            /**
             * "データソース作成完了"イベント時処理
             */
            onCreatedDataSource(data) {
                // データソース作成
                this.$Service.DataSourceService.addDataSource(this.$MapConfig.map, data);
                this.redrawDataSourceComponent();
            },

            /**
             * "データソース編集完了"イベント時処理
             */
            onEditedDataSource(data) {
                // データソース更新
                const dataSource = this.selectedDataSource;
                this.$Service.DataSourceService.updateDataSource(this.$MapConfig.map, dataSource.id, data);
                for (const key in data) {
                    dataSource[key] = data[key];
                }
                this.redrawDataSourceComponent();
            },

            /**
             * 行選択時処理
             */
            onRowSelect() {
            },

            /**
             * 行選択解除時処理
             */
            onRowUnselect() {
            },

            /**
             * セル編集時処理
             */
            onCellEditComplete(event) {
                const me = this;
                const { data, newValue, field } = event;

                switch (field) {
                case 'name':
                    // ラベル変更
                    {
                        me.$Service.DataSourceService.updateDataSource(this.$MapConfig.map, data.id, {
                            name: newValue
                        });
                        data[field] = newValue;
                    }
                    break;
                default:
                    // その他
                    event.preventDefault();
                    break;
                }
                me.redrawDataSourceComponent();
            },

            /**
             * メニュー選択時処理
             */
            onMenu(event) {
                this.$refs.menu.toggle(event);
            },

            /**
             * データ追加時処理
             */
            onAdd() {
                this.dataSourceCreateWindowVisible = true;
            },

            /**
             * データ削除時処理
             */
            onDelete() {
                if (!this.selectedDataSource) {
                    return;
                }
                this.$Service.DataSourceService.removeDataSource(this.$MapConfig.map, this.selectedDataSource);
                const layers = this.$Service.LayerService.getLayersByDataSourceId(this.selectedDataSource.id);
                if (layers && layers.length > 0) {
                    this.$Service.LayerService.removeLayers(this.$MapConfig.map, layers);
                }
                this.redrawDataSourceComponent();
            },

            /**
             * データ編集時処理
             */
            onEdit() {
                if (!this.selectedDataSource) {
                    return;
                }
                this.dataSourceEditWindowVisible = true;
            },

            /**
             * インポート時処理
             */
            onImport() {
                this.importFileWindowVisible = true;
            },

            /**
             * エクスポート時処理
             */
            onExport() {
                this.$Service.DataSourceService.exportFile();
            },

            /**
             * データソースコンポーネント再描画
             */
            redrawDataSourceComponent() {
                this.renderDataSources = this.renderDataSources + 1;
            }
        }
    };
</script>

<style scoped>
</style>