<template>
    <UxMainPanel>
        <UxMap/>
    </UxMainPanel>
</template>

<script>
    import UxMainPanel from "@/ux/frame/UxMainPanel"
    import UxMap from "@/ux/map/UxMap"

    export default {
        components: {
            UxMainPanel,
            UxMap
        },
        props: {
        },
        data() {
            return {
            };
        },
        computed: {
        },
        methods: {
        }
    };
</script>

<style scoped>
</style>