import ObjectUtils from '@/app/util/object-utils.mjs';
import ValidateUtils from '@/app/util/validate-utils.mjs';

/**
 * 文字列ユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class StringUtils {

	/** TRUE文字列 */
	static STRING_TRUE = 'TRUE';
	/** FALSE文字列 */
	static STRING_FALSE = 'FALSE';
	/** 空白文字 */
	static WHITESPACE_CHARS = [' ', '　', '\r', '\n', '\t'];

	/**
	 * 空判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isEmpty(value) {
		ValidateUtils.validateString(value);
		return ObjectUtils.isNone(value) || value === '' || value.length == 0;
	}

	/**
	 * 空判定（NOT）
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isNotEmpty(value) {
		return !StringUtils.isEmpty(value);
	}

	/**
	 * ブランク判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isBlank(value) {
		if (StringUtils.isEmpty(value)) {
			return true;
		}
		for (let i = 0; i < value.length; i++) {
			if (!StringUtils.isWhitespace(value.charAt(i))) {
				return false;
			}
		}
		return true;
	}

	/**
	 * ブランク判定（NOT）
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isNotBlank(value) {
		return !StringUtils.isBlank(value);
	}

	/**
	 * 空白判定
	 * @param {String} char 文字
	 * @returns {Boolean} true or false
	 */
	static isWhitespace(char) {
		return StringUtils.WHITESPACE_CHARS.includes(char);
	}

	/**
	 * 数値判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isNumeric(value) {
		// return StringUtils.isNotEmpty(value) && !isNaN(parseFloat(input)) && isFinite(input);
		// return StringUtils.isNotEmpty(value) && /^[-+]?\d+(\.\d+)?$/.test(input);
		return StringUtils.isNotEmpty(value) && !isNaN(Number(value));
	}

	/**
	 * 真偽値判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isBool(value) {
		return StringUtils.isNotEmpty(value) && [StringUtils.STRING_TRUE, StringUtils.STRING_FALSE].includes(value.toUpperCase());
	}

	/**
	 * 配列判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isArray(value) {
		try {
			const v = ObjectUtils.fromJson(value);
			return ObjectUtils.isArray(v);
		} catch (error) {
			return false;
		}
	}

	/**
	 * オブジェクト判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isObject(value) {
		try {
			const v = ObjectUtils.fromJson(value);
			return ObjectUtils.isObject(v);
		} catch (error) {
			return false;
		}
	}

	/**
	 * 真偽値（TRUE）判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isTrue(value) {
        return (value == true || StringUtils.equalsIgnorecase(value, StringUtils.STRING_TRUE));
	}

	/**
	 * 真偽値（FALSE）判定
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isFalse(value) {
        return !StringUtils.isTrue(value);
	}

	/**
	 * 配列として取得（配列の場合は配列のまま）
	 * @param {String} value 文字列
	 * @returns {Array<Object>} 配列
	 */
	static asArray(value) {
		if (StringUtils.isEmpty(value)) {
			return [];
		}
		if (StringUtils.isArray(value)) {
			// JSON配列
			return ObjectUtils.fromJson(value);
		} else if (StringUtils.isObject(value)) {
			// JSONオブジェクト
			return [ObjectUtils.fromJson(value)];
		} else if (StringUtils.contains(value, ',')) {
			// 「,」区切り配列
			const values = StringUtils.split(value, ',');
			for (let i = 0; i < values.length; i++) {
				values[i] = StringUtils.trim(values[i]);
			}
			return values;
		}
		return [value];
	}

	/**
	 * 真偽値として取得
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static asBoolean(value) {
		return StringUtils.isTrue(value);
	}

	/**
	 * 文字列長取得
	 * @param {String} value 文字列
	 * @returns {Number} 文字列長
	 */
	static getLength(value) {
		return StringUtils.isNotEmpty(value) ? value.length : 0;
	}

	/**
	 * 文字列比較
	 * @param {String} value1 文字列１
	 * @param {String} value2 文字列２
	 * @returns {Boolean} true or false
	 */
	static equals(value1, value2) {
		return (ObjectUtils.isNone(value1) ? false : ObjectUtils.isNone(value2) ? true : value1 == value2);
	}

	/**
	 * 文字列比較（NOT）
	 * @param {String} value1 文字列１
	 * @param {String} value2 文字列２
	 * @returns {Boolean} true or false
	 */
	static equalsNot(value1, value2) {
		return !StringUtils.equals(value1, value2);
	}

	/**
	 * 文字列比較（大文字／小文字 無視）
	 * @param {String} value1 文字列１
	 * @param {String} value2 文字列２
	 * @returns {Boolean} true or false
	 */
	static equalsIgnorecase(value1, value2) {
		return (ObjectUtils.isNone(value1) ? false : ObjectUtils.isNone(value2) ? true : value1.toUpperCase() == value2.toUpperCase());
	}

	/**
	 * 文字列比較（大文字／小文字 無視）（NOT）
	 * @param {String} value1 文字列１
	 * @param {String} value2 文字列２
	 * @returns {Boolean} true or false
	 */
	static equalsIgnorecaseNot(value1, value2) {
		return !StringUtils.equalsIgnorecase(value1, value2);
	}

	/**
	 * 対象の文字列を含むか判定
	 * @param {String} value 文字列
	 * @param {String} target 検索文字列
	 * @returns {Boolean} true or false
	 */
	static contains(value, target) {
		return StringUtils.isNotEmpty(value) && value.includes(target);
	}

	/**
	 * 開始文字列が一致するか判定
	 * @param {String} value 文字列
	 * @param {String} prefix 開始文字列
	 * @returns {Boolean} true or false
	 */
	static startsWith(value, prefix) {
		return  StringUtils.isNotEmpty(value) && StringUtils.isNotEmpty(prefix) && value.startsWith(prefix);
	}

	/**
	 * 開始文字列が一致するか判定（大文字／小文字 無視）
	 * @param {String} value 文字列
	 * @param {String} prefix 開始文字列
	 * @returns {Boolean} true or false
	 */
	static startsWithIgnorecase(value, prefix) {
		return  StringUtils.isNotEmpty(value) && StringUtils.isNotEmpty(prefix) && StringUtils.toUpperCase(value).startsWith(StringUtils.toUpperCase(prefix));
	}

	/**
	 * 終了文字列が一致するか判定
	 * @param {String} value 文字列
	 * @param {String} suffix 終了文字列
	 * @returns {Boolean} true or false
	 */
	static endsWith(value, suffix) {
		return  StringUtils.isNotEmpty(value) && StringUtils.isNotEmpty(suffix) && value.endsWith(suffix);
	}

	/**
	 * 終了文字列が一致するか判定
	 * @param {String} value 文字列
	 * @param {String} suffix 終了文字列
	 * @returns {Boolean} true or false
	 */
	static endsWithIgnorecase(value, suffix) {
		return  StringUtils.isNotEmpty(value) && StringUtils.isNotEmpty(suffix) && StringUtils.toUpperCase(value).endsWith(StringUtils.toUpperCase(suffix));
	}

	/**
	 * 文字列検索
	 * @param {String} value 文字列
	 * @param {String} target 検索文字列
	 * @returns {Number} 検索位置
	 */
	static indexOf(value, target) {
		return (StringUtils.isEmpty(value) || StringUtils.isEmpty(target)) ? -1 : value.indexOf(target);
	}

	/**
	 * 文字列検索（末尾から検索）
	 * @param {String} value 文字列
	 * @param {String} target 検索文字列
	 * @returns {Number} 検索位置
	 */
	static lastIndexOf(value, target) {
		return (StringUtils.isEmpty(value) || StringUtils.isEmpty(target)) ? -1 : value.lastIndexOf(target);
	}

	/**
	 * 文字列が空の場合デフォルト値を使用する
	 * @param {String} value 文字列
	 * @param {String} def デフォルト文字列
	 * @returns {String} 文字列
	 */
	static defaultIfEmpty(value, def) {
		return StringUtils.isEmpty(value) ? def : value;
	}

	/**
	 * 文字列がブランクの場合デフォルト値を使用する
	 * @param {String} value 文字列
	 * @param {String} def デフォルト文字列
	 * @returns {String} 文字列
	 */
	static defaultIfBlank(value, def) {
		return StringUtils.isBlank(value) ? def : value;
	}

	/**
	 * 大文字変換
	 * @param {String} value 文字列
	 * @returns {String} 変換後文字列
	 */
	static toUpperCase(value) {
		return StringUtils.isEmpty(value) ? value : value.toUpperCase();
	}

	/**
	 * 小文字変換
	 * @param {String} value 文字列
	 * @returns {String} 変換後文字列
	 */
	static toLowerCase(value) {
		return StringUtils.isEmpty(value) ? value : value.toLowerCase();
	}

	/**
	 * 文字列置換
	 * @param {String} value 文字列
	 * @param {String} find 検索文字列
	 * @param {String} rep 置換文字列
	 * @returns {String} 置換後文字列
	 */
	static replace(value, find, rep) {
		if (StringUtils.isEmpty(value) || StringUtils.isEmpty(find) || ObjectUtils.isNone(rep)) {
			return value
		}
		return value.replaceAll(find, rep);
	}

	/**
	 * 文字列結合
	 * @param {String} value 文字列
	 * @param {Array<String>} target 対象文字列
	 * @returns {String} 結合文字列
	 */
	static concat(value, ...target) {
		return StringUtils.isEmpty(value) ? ''.concat(target) : value.concat(target);
	}

	/**
	 * 文字列抽出
	 * @param {String} value 文字列
	 * @param {Number} start 開始位置
	 * @param {Number} end 終了位置
	 * @returns {String} 抽出文字列
	 */
	static substring(value, start, end=undefined) {
		return StringUtils.isEmpty(value) ? value : value.substring(start, end);
	}

	/**
	 * 文字列分割
	 * @param {String} value 文字列
	 * @returns {Array<String>} 分割文字列
	 */
	static split(value, separator=',') {
		return StringUtils.isEmpty(value) ? value : value.split(separator);
	}

	/**
	 * 文字列結合
	 * @param {Array<String>} value 文字列
	 * @returns {String} 結合文字列
	 */
	static join(values, separator=',') {
		let str = '';
		for (const value of values) {
			if (StringUtils.isEmpty(value)) {
				continue;
			}
			str += StringUtils.isEmpty(str) ? value : separator + value;
		}
		return str;
	}

	/**
	 * 反転
	 * @param {String} value 文字列
	 * @returns {String} 反転文字列
	 */
	static reverse(value) {
		if (StringUtils.isEmpty(value)) {
			return value;
		}
		let reverseValue = value;
		for (let i = value.length - 1; i >= 0; i--) {
			reverseValue += value.charAt(i);
		}
		return reverseValue;
	}

	/**
	 * トリミング
	 * @param {String} value 文字列
	 * @returns {String} トリミング後文字列
	 */
	static trim(value, target=null) {
		let str = value;
		str = StringUtils.trimLeft(str, target);
		str = StringUtils.trimRight(str, target);
		return str;
	}

	/**
	 * 左部のみトリミング
	 * @param {String} value 文字列
	 * @returns {String} トリミング後文字列
	 */
	static trimLeft(value, target=null) {
		if (StringUtils.isEmpty(value)) {
			return value;
		}
		let skip = 0;
		for (let i = 0; i < value.length; i++) {
			if (ObjectUtils.isNotNone(target)) {
				if (!StringUtils.equals(value.charAt(i), target)) {
					break;
				}
			} else {
				if (!StringUtils.isWhitespace(value.charAt(i))) {
					break;
				}
			}
			skip++
		}
		return value.substring(skip, value.length);
	}

	/**
	 * 右部のみトリミング
	 * @param {String} value 文字列
	 * @returns {String} トリミング後文字列
	 */
	static trimRight(value, target=null) {
		if (StringUtils.isEmpty(value)) {
			return value;
		}
		let skip = 0;
		for (let i = value.length - 1; i >= 0; i--) {
			if (ObjectUtils.isNotNone(target)) {
				if (!StringUtils.equals(value.charAt(i), target)) {
					break;
				}
			} else {
				if (!StringUtils.isWhitespace(value.charAt(i))) {
					break;
				}
			}
			skip++
		}
		return value.substring(0, value.length - skip);
	}

	/**
	 * 連続する空白文字を１つにまとめる
	 * @param {String} value 文字列
	 * @param {String} rep くぐり文字
	 * @returns {String} 加工後文字列
	 */
	static wrapWhiteSpace(value, rep=' ') {
		if (StringUtils.isEmpty(value)) {
			return value;
		}
		let after = '';
		let skip = false;
		for (let i = 0; i < value.length; i++) {
			const v = value.charAt(i);
			if (StringUtils.isWhitespace(v)) {
				if (skip) {
					continue;
				} else {
					after += rep;
					skip = true;
				}
			} else {
				after += v;
				skip = false;
			}
		}
		return after;
	}

	/**
	 * 文字列を指定の文字で囲う
	 * @param {String} value 文字列
	 * @param {String} c 囲い文字
	 * @returns {String} 囲われた文字列
	 */
	static enclose(value, c) {
		if (StringUtils.isEmpty(value)) {
			return `${c}${c}`;
		}
		return `${c}${value}${c}`;
	}

	/**
	 * 正規表現マッチング
	 * @param {String} value 文字列
	 * @param {String} regex 正規表現
	 * @returns {Boolean} true or false
	 */
	static matchRegex(value, regex) {
		if (StringUtils.isEmpty(value)) {
			return true;
		}
		if (StringUtils.isEmpty(regex)) {
			return true;
		}
		return new RegExp(regex).test(value);
	}

}
export default StringUtils;