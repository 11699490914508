<template>
    <UxMenubar :menuItems="menuItems" :visible="true">
        <template #start>
            <div class="card flex justify-content-center gap-2">
                <Button v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onNavigation" severity="secondary" outlined/>
                <MqResponsive target="md+">
                    <!-- <Button class="bg-surface-0 hover:bg-bluegray-100 border-surface-0" text  @click="onLogo">
                        <UxLogo :src="'/assets/image/logo.png'" :height="'24'" class="cursor-pointer"/>
                    </Button> -->
                    <UxLogo :src="'/assets/image/logo.png'" :height="'30'" class="cursor-pointer mt-1" />
                </MqResponsive>
            </div>
        </template>
        <template #end>
            <div class="card flex justify-content-center gap-2">
                <MqResponsive target="xs-">
                    <SelectButton v-model="menuSelectedMode" :options="menuModeOptionsForSmall" dataKey="value" optionValue="value" optionLabel="name" aria-labelledby="basic" style="display:inline-block !important;" @click="onChangeMenuOption">
                        <template #option="slotProps">
                            <span style="height:24px"><i :class="slotProps.option.icon" v-tooltip.bottom="slotProps.option.name"></i></span>
                        </template>
                    </SelectButton>
                </MqResponsive>
                <MqResponsive target="sm+">
                    <SelectButton v-model="menuSelectedMode" :options="menuModeOptions" dataKey="value" optionValue="value" optionLabel="name" aria-labelledby="basic" style="display:inline-block !important;" @click="onChangeMenuOption">
                        <template #option="slotProps">
                            <span class="w-5rem"><i :class="slotProps.option.icon"></i><span class="px-1"></span>{{slotProps.option.name}}</span>
                        </template>
                    </SelectButton>
                </MqResponsive>
                <!-- <Button target="sm+" v-tooltip.bottom="'住所検索'" icon="pi pi-search" @click="onAddressSearch" severity="secondary" outlined /> -->
                <Button v-tooltip.bottom="'ワークスペース'" icon="pi pi-desktop" @click="onWorkspace" :disabled="menuSelectedMode === null" severity="secondary" outlined />
            </div>
        </template>
    </UxMenubar>

    <!-- ウィンドウ -->
    <UxAddressWindow :visible="addressWindowVisible" v-on:change-visible="onAddressWindowVisible" />
</template>

<script>
    import { MqResponsive } from "vue3-mq";

    import Button from "primevue/button"
    import SelectButton from "primevue/selectbutton"

    import UxMenubar from "@/ux/frame/UxMenubar"
    import UxLogo from "@/ux/field/UxLogo"
    import UxAddressWindow from "@/ux/window/UxAddressWindow"

    export default {
        components: {
            MqResponsive,
            Button,
            SelectButton,
            UxMenubar,
            UxLogo,
            UxAddressWindow
        },
        props: {
        },
        emits: ['logo','navigation','workspace','navigation','changemode'],
        data() {
            return {
                menuSelectedMode: null,
                menuModeOptions: [
                    { name: 'データ', icon: 'pi pi-database', value: 'data' },
                    { name: 'レイヤ', icon: 'pi pi-map', value: 'layer' },
                    { name: '領域', icon: 'pi pi-plus-circle', value: 'zone' },
                    { name: 'メモ', icon: 'pi pi-map-marker', value: 'building' },
                    { name: '計測', icon: 'pi pi-calculator', value: 'measure' },
                    { name: '印刷', icon: 'pi pi-print', value: 'print' }
                ],
                menuModeOptionsForSmall: [
                    { name: '領域', icon: 'pi pi-plus-circle', value: 'zone' },
                    { name: 'メモ', icon: 'pi pi-map-marker', value: 'building' },
                    { name: '印刷', icon: 'pi pi-print', value: 'print' }
                ],
                menuItems: [],
                addressWindowVisible: false
            };
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            /**
             * "ロゴ"ボタン押下時処理
             */
            onLogo() {
                this['$emit']('logo');
            },

            /**
             * "ナビゲーション"ボタン押下時処理
             */
            onNavigation() {
                this['$emit']('navigation');
            },

            /**
             * "ワークスペース"ボタン押下時処理
             */
            onWorkspace() {
                this['$emit']('workspace');
            },

            /**
             * "メニューオプション変更"イベント時処理
             */
            onChangeMenuOption() {
                this['$emit']('changemode', this.menuSelectedMode);
            },

            /**
             * "住所検索"ボタン押下時処理
             */
            onAddressSearch() {
                this.addressWindowVisible = !this.addressWindowVisible;
            },

            /**
             * "住所ウィンドウ表示切替"イベント時処理
             */
            onAddressWindowVisible(visible) {
                this.addressWindowVisible = visible;
            }
        }
    };
</script>

<style scoped>
</style>
