/**
 * データユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
export default class DataUtils {

	/**
	 * ID生成
	 * @returns {String} ID
	 */
	static generateId() {
		const date = new Date();
		const year = DataUtils.padding(date.getFullYear(), 4, 0);
		const month = DataUtils.padding(date.getMonth() + 1, 2, 0);
		const day = DataUtils.padding(date.getDate(), 2, 0);
		const hour = DataUtils.padding(date.getHours(), 2, 0);
		const minute = DataUtils.padding(date.getMinutes(), 2, 0);
		const second = DataUtils.padding(date.getSeconds(), 2, 0);
		const ms = DataUtils.padding(date.getMilliseconds(), 2, 0);
        return `${year}${month}${day}${hour}${minute}${second}${ms}`;
	}

	/**
	 * 指定文字埋め処理
	 * @param {String} value 文字列
	 * @param {Number} length データ長
	 * @param {String} pad 埋め文字
	 * @returns {String} 文字列
	 */
	static padding(value, length, pad=' ') {
		let padding = '';
		for (let i = 0; i < length; i++) {
			padding += pad;
		}
		return `${(padding + String(value)).slice(-length)}`;
	}
}
