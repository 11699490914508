<template>
    <Dialog modal v-bind:visible="visible" header="データソース" :style="{ width: '50vw', height: '80vh' }" :breakpoints="{ '960px': '60vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div style="height:55vh">
            <ExTabView>
                <TabPanel header="データソース">
                    <UxLayerDataSourceEditPanel
                        :createMode="false"
                        v-model:dataSource="dataSource" />
                </TabPanel>
                <TabPanel header="基本情報">
                    <UxLayerBaseEditPanel
                        :createMode="false"
                        v-model:name="name"
                        v-model:desc="desc"
                        v-model:layerType="layerType"
                        v-model:zoomMin="zoomMin"
                        v-model:zoomMax="zoomMax"
                        v-model:geomType="dataSource.geomType" />
                </TabPanel>
                <TabPanel header="オプション">
                    <UxLayerPolygonEditPanel v-if="isPolygonLayer"
                        v-model:fillColor="options.fillColor"
                        v-model:fillOpacity="options.fillOpacity"
                        v-model:outlineColor="options.outlineColor" />
                    <UxLayerPointEditPanel v-if="isPointLayer"
                        v-model:fillColor="options.fillColor"
                        v-model:outlineColor="options.outlineColor" />
                    <UxLayerLineEditPanel v-if="isLineLayer"
                        v-model:lineColor="options.lineColor"
                        v-model:lineWidth="options.lineWidth" />
                    <UxLayerLabelEditPanel v-if="isLabelLayer"
                        v-model:dataSource="dataSource"
                        v-model:property="options.property"
                        v-model:labelColor="options.labelColor"
                        v-model:labelSize="options.labelSize"
                        v-model:labelOutlineColor="options.labelOutlineColor"
                        v-model:labelOutlineWidth="options.labelOutlineWidth"
                        v-model:xOffset="options.xOffset"
                        v-model:yOffset="options.yOffset" />
                    <UxLayerIconEditPanel v-if="isIconLayer"
                        v-model:iconImage="options.iconImage"
                        v-model:iconSize="options.iconSize"
                        v-model:xOffset="options.xOffset"
                        v-model:yOffset="options.yOffset" />
                    <UxLayerChoroplethEditPanel v-if="isChoroplethLayer"
                        v-model:dataSource="dataSource"
                        v-model:property="options.property"
                        v-model:fillOpacity="options.fillOpacity"
                        v-model:ranks="options.ranks" />
                </TabPanel>
            </ExTabView>
        </div>
        <template #footer>
            <UxSeparator />
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="OK" @click="onOK" severity="secondary" outlined />
                <Button type="button" label="キャンセル" @click="onCancel" severity="secondary" outlined />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import Button from 'primevue/button';
    import UxSeparator from '@/ux/field/UxSeparator';

    import UxLayerBaseEditPanel from "@/ux/panel/UxLayerBaseEditPanel";
    import UxLayerDataSourceEditPanel from "@/ux/panel/UxLayerDataSourceEditPanel";
    import UxLayerPolygonEditPanel from "@/ux/panel/UxLayerPolygonEditPanel";
    import UxLayerPointEditPanel from "@/ux/panel/UxLayerPointEditPanel";
    import UxLayerLineEditPanel from "@/ux/panel/UxLayerLineEditPanel";
    import UxLayerLabelEditPanel from "@/ux/panel/UxLayerLabelEditPanel";
    import UxLayerIconEditPanel from "@/ux/panel/UxLayerIconEditPanel";
    import UxLayerChoroplethEditPanel from "@/ux/panel/UxLayerChoroplethEditPanel";

    import LayerService from '@/app/service/LayerService';

    export default {
        components: {
            Dialog,
            TabPanel,
            ExTabView,
            Button,
            UxSeparator,
            UxLayerBaseEditPanel,
            UxLayerDataSourceEditPanel,
            UxLayerPolygonEditPanel,
            UxLayerPointEditPanel,
            UxLayerLineEditPanel,
            UxLayerLabelEditPanel,
            UxLayerIconEditPanel,
            UxLayerChoroplethEditPanel
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },
            selectedLayer: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                name: 'レイヤ',
                desc: '',
                dataSource: null,
                layerType: null,
                zoomMin: null,
                zoomMax: null,
                options: {},
            };
        },
        computed: {
            isPolygonLayer: function() {
                return this.layerType == LayerService.LT_POLYGON;
            },
            isPointLayer: function() {
                return this.layerType == LayerService.LT_POINT;
            },
            isLineLayer: function() {
                return this.layerType == LayerService.LT_LINE;
            },
            isLabelLayer: function() {
                return this.layerType == LayerService.LT_LABEL;
            },
            isIconLayer: function() {
                return this.layerType == LayerService.LT_ICON;
            },
            isChoroplethLayer: function() {
                return this.layerType == LayerService.LT_CHOROPLETH;
            },
        },
        watch: {
            visible(newValue) {
                const me = this;
                if (newValue) {
                    if (me.selectedLayer) {
                        const layer = me.selectedLayer;
                        me.name = layer.name;
                        me.desc = layer.desc;
                        me.dataSource = this.$Service.DataSourceService.getDataSourceById(layer.dataSourceId);
                        me.layerType = layer.layerType;
                        me.zoomMin = layer.zoomMin;
                        me.zoomMax = layer.zoomMax;
                        me.options = layer.options;
                    }
                }
            }
        },
        emits: ['change-visible', 'edited'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * OK時処理
             */
            onOK() {
                // 更新内容通知
                this['$emit']('edited', {
                    id: this.selectedLayer.id,
                    name: this.name,
                    desc: this.desc,
                    dataSourceId: this.dataSource.id,
                    layerType: this.layerType,
                    zoomMin: this.zoomMin,
                    zoomMax: this.zoomMax,
                    options: this.options
                });
                // クローズ
                this.onVisible(false);
            },

            /**
             * キャンセル時処理
             */
            onCancel() {
                // クローズ
                this.onVisible(false);
            }
        }
    };
</script>

<style scoped>
</style>