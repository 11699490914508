<template>
    <div class="card">
        <ExTabView>
            <TabPanel header="レイヤ">
                <div class="card flex justify-content-between">
                    <div class="card flex justify-content-start gap-2 py-2 px-2">
                        <Button type="button" v-tooltip.bottom="'追加'" icon="pi pi-plus" @click="onAdd" severity="secondary" outlined/>
                        <Button type="button" v-tooltip.bottom="'削除'" icon="pi pi-minus" @click="onDelete" severity="secondary" outlined :disabled="!selectedLayer"/>
                        <Button type="button" v-tooltip.bottom="'編集'" icon="pi pi-pencil" @click="onEdit" severity="secondary" outlined :disabled="!selectedLayer"/>
                    </div>
                    <!-- <div class="card flex justify-content-end gap-2 py-2 px-2">
                        <Button type="button" v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onMenu" aria-haspopup="true" aria-controls="overlay_menu" severity="secondary" outlined/>
                        <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
                    </div> -->
                </div>
                <div class="card h-full">
                    <DataTable :key="renderLayers" v-model:selection="selectedLayer" :value="layers" dataKey="id"
                        class="layer-panel-datatable" scrollable scrollHeight="auto">
                        <Column selectionMode="single" headerStyle="width: 40px"></Column>
                        <Column field="name" header="名称"></Column>
                        <Column header="" headerStyle="width: 40px">
                            <template #body="slotProps">
                                <ToggleButton v-model="slotProps.data.options.visibility" onIcon="pi pi-eye" offIcon="pi pi-eye-slash" onLabel="" offLabel=""
                                    @click="onVisibleLayer(slotProps.data)"/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel header="凡例">
                <UxLayerLegendPanel :render="renderLayers"/>
            </TabPanel>
            <TabPanel header="説明">
                <div class="card py-2 px-2">
                    <br>
                    登録した「データ」をもとに「レイヤー」の作成を行えます。<br>
                    <br>
                    また「凡例」タブで作成したレイヤーの凡例を確認できます。<br>
                    <br>
                    <span style="font-weight: bold">＜追加＞</span><br>
                    <br>
                    １．追加ボタンを押下<br>
                    <br>
                    ２．データソース選択<br>
                    <br>
                    ３．レイヤー情報を選択<br>
                    <br>
                    ４．レイヤーを作成します<br>
                    <br>
                    <span style="font-weight: bold">＜編集＞</span><br>
                    <br>
                    リスト上でレイヤーを選択し、編集ボタン押下<br>
                    <br>
                    <span style="font-weight: bold">＜削除＞</span><br>
                    <br>
                    リスト上でレイヤーを選択し、削除ボタン押下<br>
                </div>
            </TabPanel>
        </ExTabView>
    </div>

    <UxFileWindow :visible="importFileWindowVisible" v-on:change-visible="onImportFileWindowVisible" v-on:upload-file="onImportFile" />
    <UxLayerCreateWizard :visible="layerCreateWizardVisible" v-on:change-visible="onLayerCreateWizardVisible" v-on:created="onLayerCreated" />
    <UxLayerEditWindow :visible="layerEditWindowVisible" :selectedLayer="selectedLayer"  v-on:change-visible="onLayerEditWindowVisible" v-on:edited="onLayerEdited" />
</template>

<script>
    import ExTabView from "@/overrides/panel/ExTabView";
    import TabPanel from 'primevue/tabpanel';
    import Button from 'primevue/button';
    // import Menu from 'primevue/menu';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import ToggleButton from 'primevue/togglebutton';

    import UxLayerLegendPanel from '@/ux/panel/UxLayerLegendPanel';
    import UxFileWindow from "@/ux/window/UxFileWindow";
    import UxLayerCreateWizard from '@/ux/window/UxLayerCreateWizard';
    import UxLayerEditWindow from '@/ux/window/UxLayerEditWindow';

    export default {
        components: {
            ExTabView,
            TabPanel,
            Button,
            // Menu,
            DataTable,
            Column,
            ToggleButton,
            UxLayerLegendPanel,
            UxFileWindow,
            UxLayerCreateWizard,
            UxLayerEditWindow
        },
        props: {
        },
        data() {
            return {
                menuItems: [
                    {
                        label: 'インポート',
                        icon: 'pi pi-file-import',
                        command: () => { this.onImport(); }
                    },
                    {
                        label: 'エクスポート',
                        icon: 'pi pi-file-export',
                        command: () => { this.onExport(); }
                    }
                ],
                selectedLayer: null,
                renderLayers: 0,

                importFileWindowVisible: false,
                layerCreateWizardVisible: false,
                layerEditWindowVisible: false,
            }
        },
        created() {
            this.$Service.LayerService.setComponent(this);
        },
        computed: {
            layers() {
                return this.$Service.LayerService.getLayers()||[];
            }
        },
        mounted() {
        },
        unmounted() {
        },
        watch: {
        },
        methods: {

            /**
             * "インポートファイル選択ウィンドウ表示切替"イベント時処理
             */
            onImportFileWindowVisible(visible) {
                this.importFileWindowVisible = visible;
            },

            /**
             * "レイヤー作成ウィンドウ表示切替"イベント時処理
             */
            onLayerCreateWizardVisible(visible) {
                this.layerCreateWizardVisible = visible;
            },

            /**
             * "レイヤー編集ウィンドウ表示切替"イベント時処理
             */
            onLayerEditWindowVisible(visible) {
                this.layerEditWindowVisible = visible;
            },

            /**
             * "ファイルアップロード"イベント時処理
             */
            onImportFile(files) {
                const me = this;

                // データ追加
                this.$Service.LayerService.importFile(this.$MapConfig.map, files[0], function() {
                    // 画面クローズ
                    setTimeout(() => {
                        me.importFileWindowVisible = false;
                    }, 1000);
                    me.redrawLayerComponent();
                });
            },

            /**
             * レイヤー作成時処理
             */
            onLayerCreated(layer) {
                this.$Service.LayerService.addLayer(this.$MapConfig.map, layer);
                this.redrawLayerComponent();
            },

            /**
             * レイヤー編集時処理
             */
            onLayerEdited(layer) {
                if (!this.selectedLayer) {
                    return;
                }
                this.$Service.LayerService.updateLayer(this.$MapConfig.map, this.selectedLayer.id, layer);
                this.redrawLayerComponent();
            },

            /**
             * レイヤー表示切替時処理
             */
            onVisibleLayer(layer) {
                this.$Service.LayerService.setVisibleLayer(this.$MapConfig.map, layer, layer.options.visibility);
                this.redrawLayerComponent();
            },

            /**
             * メニュー選択時処理
             */
            onMenu(event) {
                this.$refs.menu.toggle(event);
            },

            /**
             * データ追加時処理
             */
            onAdd() {
                this.layerCreateWizardVisible = true;
            },

            /**
             * データ削除時処理
             */
            onDelete() {
                if (!this.selectedLayer) {
                    return;
                }
                this.$Service.LayerService.removeLayer(this.$MapConfig.map, this.selectedLayer);
                this.redrawLayerComponent();
            },

            /**
             * データ編集時処理
             */
            onEdit() {
                if (!this.selectedLayer) {
                    return;
                }
                this.layerEditWindowVisible = true;
            },

            /**
             * インポート時処理
             */
            onImport() {
                this.importFileWindowVisible = true;
            },

            /**
             * エクスポート時処理
             */
            onExport() {
                this.$Service.LayerService.exportFile();
            },

            /**
             * レイヤーコンポーネント再描画
             */
            redrawLayerComponent() {
                this.renderLayers = this.renderLayers + 1;
            }
        }
    };
</script>

<style scoped>
</style>
