<template>
    <div>
        <div class="card h-full">
            <DataTable v-model:selection="selectedDataSource" :value="dataSources" dataKey="id" scrollable scrollHeight="auto"
                @rowSelect="this.$emit('update:dataSource', this.selectedDataSource)">
                <Column selectionMode="single" headerStyle="width: 40px" v-if="createMode"></Column>
                <Column field="name" header="名称" bodyStyle="text-align:left"></Column>
                <Column field="geomType" header="ジオメトリ種別" headerStyle="width: 160px;" bodyStyle="text-align:center">
                    <template #body="{ data, field }">
                        {{ this.$Service.DataSourceService.getGeomTypeLabel(data[field]) }}
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    export default {
        components: {
            DataTable,
            Column
        },
        props: {
            dataSource: {
                type: Object,
                default: function() { return {}; }
            },

            createMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dataSources: this.$Service.DataSourceService.getDataSources(),
                selectedDataSource: this.dataSource
            };
        },
        emits: [
            'update:dataSource'
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>