<template>
    <Dialog v-bind:visible="visible" header="エリア" :style="{ width: '15vw' }" :breakpoints="{ '960px': '30vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" position="left" >
        <UxPrefGrid v-if="mode === 'pref'" v-on:select="onSelectPref"/>
        <UxCityGrid v-if="mode === 'city'" v-on:select="onSelectCity" :pref="pref"/>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';

    import UxPrefGrid from "@/ux/grid/UxPrefGrid";
    import UxCityGrid from "@/ux/grid/UxCityGrid";

    export default {
        components: {
            Dialog,
            UxPrefGrid,
            UxCityGrid
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                mode: 'pref',
                pref: null
            };
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "都道府県選択"イベント時処理
             */
            onSelectPref(code) {
                this.pref = code;
                this.mode = 'city';
            },

            /**
             * "市区町村選択"イベント時処理
             */
            onSelectCity(code) {
                console.log(code);
            }
        }
    };
</script>

<style scoped>
</style>