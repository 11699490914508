import ObjectUtils from '@/app/util/object-utils.mjs';
import ArrayUtils from '@/app/util/array-utils.mjs';
import StringUtils from '@/app/util/string-utils.mjs';
import NumberUtils from '@/app/util/number-utils.mjs';
import BinaryUtils from '@/app/util/binary-utils.mjs';
import MatchUtils from '@/app/util/match-utils.mjs';

/**
 * 検証ユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class ValidateUtils {

	/**
	 * オブジェクト検証
	 * @param {Object} value 値
	 */
	static validateObject(value) {
		if (!ObjectUtils.isObject(value)) {
			throw new Error(`Not Object. ${value}`);
		}
	}

	/**
	 * 配列検証
	 * @param {Object} value 値
	 */
	static validateArray(value) {
		if (!ObjectUtils.isArray(value)) {
			throw new Error(`Not Array. ${value}`);
		}
	}

	/**
	 * 文字列検証
	 * @param {Object} value 値
	 */
	static validateString(value) {
		if (!ObjectUtils.isString(value)) {
			throw new Error(`Not String. ${value}`);
		}
	}

	/**
	 * 数値検証
	 * @param {Object} value 値
	 */
	static validateNumber(value) {
		if (!ObjectUtils.isNumber(value)) {
			throw new Error(`Not Number. ${value}`);
		}
	}

	/**
	 * 整数検証
	 * @param {Number} value 数値
	 */
	static validateInteger(value) {
		if (!NumberUtils.isInteger(value)) {
			throw new Error(`Not Integer. ${value}`);
		}
	}

	/**
	 * 実数検証
	 * @param {Number} value 数値
	 */
	static validateReal(value) {
		if (!NumberUtils.isReal(value)) {
			throw new Error(`Not Float. ${value}`);
		}
	}

	/**
	 * 真偽値検証
	 * @param {Object} value 値
	 */
	static validateBool(value) {
		if (!ObjectUtils.isBool(value)) {
			throw new Error(`Not Boolean. ${value}`);
		}
	}

	/**
	 * 関数型検証
	 * @param {Object} value オブジェクト
	 */
	static validateFunction(value) {
		if (!ObjectUtils.isFunction(value)) {
			throw new Error(`Not Function. ${value}`);
		}
	}

	/**
	 * オブジェクトの値がなしか検証
	 * @param {Object} value オブジェクト
	 */
	static validateNone(value) {
		if (!ObjectUtils.isNone(value)) {
			throw new Error(`None Object. ${value}`);
		}
	}

	/**
	 * オブジェクトの値が空か検証
	 * @param {Object} value オブジェクト
	 */
	static validateNotEmptyObject(value) {
		if (!ObjectUtils.isEmpty(value)) {
			throw new Error(`Empty Object. ${value}`);
		}
	}

	/**
	 * 空でないか検証
	 * @param {Array<Object>} value 配列
	 * @returns {Boolean} true or false
	 */
	static validateNotEmptyArray(value) {
		if (ArrayUtils.isEmpty(value)) {
			throw new Error(`Empty Array. ${value}`);
		}
	}

	/**
	 * 文字列が空でないか検証
	 * @param {String} value 文字列
	 */
	static validateNotEmptyString(value) {
		if (StringUtils.isEmpty(value)) {
			throw new Error(`Empty String. ${value}`);
		}
	}

	/**
	 * 空でないか検証
	 * @param {Buffer} value バイナリ
	 * @returns {Boolean} true or false
	 */
	static validNotEmptyBinary(value) {
		if (BinaryUtils.isEmpty(value)) {
			throw new Error(`Empty Binary. ${value}`);
		}
	}

	/**
	 * 計算可能か検証
	 * @param {Number} value 数値
	 */
	static validateCalculable(value) {
		if (NumberUtils.isNotCalculable(value)) {
			throw new Error(`Not Calculation. ${value}`);
		}
	}

	/**
	 * 最小値以上検証
	 * @param {Number} value 値
	 * @param {Number} min 最小値
	 */
	static validateGreaterEqualMin(value, min) {
		if (!NumberUtils.isGreaterEqualMin(value, min)) {
			throw new Error(`Error. ${`( ${value} ) >= ${min}`}`);
		}
	}

	/**
	 * 最大値以下検証
	 * @param {Number} value 値
	 * @param {Number} max 最大値
	 */
	static validateLessEqualMax(value, max) {
		if (!NumberUtils.isLessEqualMax(value, max)) {
			throw new Error(`Error. ( ${value} ) <= ${max}`);
		}
	}

	/**
	 * 範囲内検証
	 * @param {Number} value 値
	 * @param {Number} min 最小値
	 * @param {Number} max 最大値
	 */
	static validateWithinEqualRange(value, min, max) {
		if (!NumberUtils.isWithinEqualRange(value, min, max)) {
			throw new Error(`Error. ${min} <= ( ${value} ) <= ${max}`);
		}
	}

	/**
	 * 数値のみ検証
	 * @param {String} value 値
	 * @returns {Boolean} true or false
	 */
	static validateOnlyNumber(value) {
		if (!MatchUtils.isNumber(value)) {
			throw new Error(`Only Number. ${value}`);
		}
	}

	/**
	 * 英字のみ検証
	 * @param {String} value 値
	 */
	static validateOnlyAlpha(value) {
		if (!MatchUtils.isAlphabet(value)) {
			throw new Error(`Only Alphabet. ${value}`);
		}
	}

	/**
	 * 英数のみ検証
	 * @param {String} value 値
	 */
	static validateOnlyAlphaNumber(value) {
		if (!MatchUtils.isAlphabet(value) || !MatchUtils.isNumber(value)) {
			throw new Error(`Only Alphabet or Number. ${value}`);
		}
	}
}
export default ValidateUtils;