<script>
    import Fieldset from "primevue/fieldset"

    export default {
        extends: Fieldset,
        name: 'ex-fieldset',
        render: Fieldset.render,
        props: {
        }
    };
</script>

<style scoped>
    .p-fieldset .p-fieldset-content {
        margin: 0px !important;
        padding: 0px !important;
    }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        padding: 8px !important;
    }
</style>
