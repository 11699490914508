<template>
    <div class="m-4">
        <div :render="render" v-for="(layer) of layers" :key="layer.id">
            <div class="mb-2 text-lg">
                {{layer.name}}
            </div>
            <div class="pl-3">
                <!-- ポリゴン -->
                <div v-if="isPolygonLayer(layer.layerType)">
                    <div class="card flex justify-content-left">
                        <svg width="64" height="24">
                            <rect X="10" Y="25" width="64" height="24" :fill="layer.options.fillOpacity == 1 ? 'transparent' : '#'+layer.options.fillColor"
                                :stroke="'#'+layer.options.outlineColor" stroke-width="3" />
                        </svg>
                        <span class="ml-3">ポリゴン</span>
                    </div>
                </div>
                <!-- ポイント -->
                <div v-if="isPointLayer(layer.layerType)">
                    <div class="card flex justify-content-left">
                        <svg width="64" height="24">
                            <circle cx="30" cy="12" r="10" :fill="'#'+layer.options.fillColor"
                                :stroke="'#'+layer.options.fillColor" stroke-width="3px" />
                        </svg>
                        <span class="ml-3">ポイント</span>
                    </div>
                </div>
                <!-- ライン -->
                <div v-if="isLineLayer(layer.layerType)">
                    <div class="card flex justify-content-left">
                        <svg width="64" height="24">
                            <line x1="10" y1="15" x2="64" y2="15" :stroke="'#'+layer.options.lineColor" stroke-width="3"/>
                        </svg>
                        <span class="ml-3">ライン</span>
                    </div>
                </div>
                <!-- ラベル -->
                <div v-if="isLabelLayer(layer.layerType)">
                    <div class="card flex justify-content-left">
                        <svg width="64" height="24">
                            <text x="22" y="18" class="text-sm">Ｔ</text>
                        </svg>
                        <span class="ml-3">ラベル</span>
                    </div>
                </div>
                <!-- アイコン -->
                <div v-if="isIconLayer(layer.layerType)">
                    <div class="card flex justify-content-left">
                        <img class="ml-3 mr-5" :src="getIcon(layer.options.iconImage).src" alt="" style="width: 24px; height: 24px;">
                        <span class="ml-2">{{ getIcon(layer.options.iconImage).name }}</span>
                    </div>
                </div>
                <!-- 階級図 -->
                <div v-if="isChoroplethLayer(layer.layerType)">
                    <div v-for="(rank) of layer.options.ranks" :key="rank.id">
                        <div class="card flex justify-content-left mb-1">
                            <svg width="64" height="24">
                                <rect X="10" Y="25" width="64" height="24" :fill="layer.options.fillOpacity == 1 ? 'transparent' : '#'+rank.color"
                                    :stroke="'transparent'" stroke-width="3" />
                            </svg>
                            <span class="ml-3">{{ `${rank.min} ~ ${rank.max}`}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <UxSeparator/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';

    import LayerService from '@/app/service/LayerService';

    export default {
        components: {
            UxSeparator
        },
        props: {
            render: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                layers: this.$Service.LayerService.getLayers()||[]
            };
        },
        computed: {
        },
        emits: [
        ],
        methods: {
            isPolygonLayer: function(layerType) {
                return layerType == LayerService.LT_POLYGON;
            },
            isPointLayer: function(layerType) {
                return layerType == LayerService.LT_POINT;
            },
            isLineLayer: function(layerType) {
                return layerType == LayerService.LT_LINE;
            },
            isLabelLayer: function(layerType) {
                return layerType == LayerService.LT_LABEL;
            },
            isIconLayer: function(layerType) {
                return layerType == LayerService.LT_ICON;
            },
            isChoroplethLayer: function(layerType) {
                return layerType == LayerService.LT_CHOROPLETH;
            },

            getIcon: function(id) {
                for (const icon of this.$MapConfig.icons) {
                    if (icon.id == id) {
                        return icon;
                    }
                }
                return {};
            }
        }
    };
</script>

<style scoped>
</style>