import ObjectUtils from '@/app/util/object-utils.mjs';
import ValidateUtils from '@/app/util/validate-utils.mjs';

/**
 * 数数値ユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class NumberUtils {

	/**
	 * 空判定
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isEmpty(value) {
		ValidateUtils.validateNumber(value);
		return ObjectUtils.isNone(value) || value === 0;
	}

	/**
	 * 空判定（NOT）
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isNotEmpty(value) {
		return !NumberUtils.isEmpty(value);
	}

	/**
	 * NaN判定
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isNaN(value) {
		ValidateUtils.validateNumber(value);
		return value == NaN;
	}

	/**
	 * NaN判定（NOT）
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isNotNaN(value) {
		return !NumberUtils.isNaN(value);
	}

	/**
	 * Infinity判定
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isInfinity(value) {
		ValidateUtils.validateNumber(value);
		return value == Infinity;
	}

	/**
	 * NaInfinity判定（NOT）
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isNotInfinity(value) {
		return !NumberUtils.isInfinity(value);
	}

	/**
	 * 計算可能か判定
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isCalculable(value) {
		ValidateUtils.validateNumber(value);
		return !ObjectUtils.isNone(value) && !NumberUtils.isNaN(value) && !NumberUtils.isInfinity(value);
	}

	/**
	 * 計算可能か判定（NOT）
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isNotCalculable(value) {
		return !NumberUtils.isCalculable(value);
	}

	/**
	 * 整数か判定
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isInteger(value) {
		return NumberUtils.isCalculable(value) && Number.isInteger(value);
	}

	/**
	 * 実数か判定
	 * @param {Number} value 数値
	 * @returns {Boolean} true or false
	 */
	static isReal(value) {
		// return NumberUtils.isCalculable(value) && !NumberUtils.isInteger(value);
		return NumberUtils.isCalculable(value);
	}

	/**
	 * 最小値以上判定
	 * @param {String} value 値
	 * @param {Number} min 最小値
	 * @returns {Boolean} true or false
	 */
	static isGreaterEqualMin(value, min) {
		ValidateUtils.validateCalculable(value);
		ValidateUtils.validateCalculable(min);
		return (value >= min);
	}

	/**
	 * 最大値以下判定
	 * @param {String} value 値
	 * @param {Number} max 最大値
	 * @returns {Boolean} true or false
	 */
	static isLessEqualMax(value, max) {
		ValidateUtils.validateCalculable(value);
		ValidateUtils.validateCalculable(max);
		return (value <= max);
	}

	/**
	 * 範囲内検証
	 * @param {String} value 値
	 * @param {Number} min 最小値
	 * @param {Number} max 最大値
	 * @returns {Boolean} true or false
	 */
	static isWithinEqualRange(value, min, max) {
		ValidateUtils.validateCalculable(value);
		ValidateUtils.validateCalculable(min);
		ValidateUtils.validateCalculable(max);
		return (min <= value || value <= max);
	}

	/**
	 * 文字列⇒整数
	 * @param {String} value 文字列
	 * @returns {Number} 整数
	 */
	static parseInt(value) {
		ValidateUtils.validateString(value);
		return parseInt(value, 10);
	}

	/**
	 * 文字列⇒実数
	 * @param {String} value 文字列
	 * @returns {Number} 実数
	 */
	static parseReal(value) {
		ValidateUtils.validateString(value);
		return parseFloat(value);
	}
}
export default NumberUtils;