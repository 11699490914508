<template>
    <Dialog modal v-bind:visible="visible" header="データソース追加" :style="{ width: '50vw', height: '80vh' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div style="height:55vh">
            <div v-if="mode === 'data-source-base'">
                <UxDataSourceBaseEditPanel
                    :createMode="true"
                    v-model:name="name"
                    v-model:desc="desc"
                    v-model:geomType="geomType"
                    v-model:dataSourceType="dataSourceType" />
            </div>
            <div v-if="mode === 'data-source-property'">
                <UxDataSourcePropertyEditPanel
                    :createMode="true"
                    v-model:attributes="attributes" />
            </div>
            <div v-if="mode === 'data-source-geojson'">
            </div>
            <div v-if="mode === 'data-source-geojson-api'">
                <UxDataSourceWebApiEditPanel
                    :createMode="false"
                    v-model:requestUrl="data.requestUrl"
                    v-model:requestMethod="data.requestMethod"
                    v-model:requestHeader="data.requestHeader"
                    v-model:requestBody="data.requestBody"
                    v-model:responseProperty="data.responseProperty" />
            </div>
            <div v-if="mode === 'data-source-mvt'">
                <UxDataSourceWebApiEditPanel
                    :createMode="false"
                    v-model:requestUrl="data.requestUrl"
                    v-model:requestMethod="data.requestMethod"
                    v-model:requestHeader="data.requestHeader"
                    v-model:requestBody="data.requestBody"
                    v-model:responseProperty="data.responseProperty" />
            </div>
        </div>
        <template #footer>
            <UxSeparator />
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="前へ" @click="onPrev" severity="secondary" outlined :disabled="disabledPrev" />
                <Button type="button" label="次へ" @click="onNext" severity="secondary" outlined :disabled="disabledNext" />
                <Button type="button" label="OK" @click="onOK" severity="secondary" outlined :disabled="disabledOK" />
                <Button type="button" label="キャンセル" @click="onCancel" severity="secondary" outlined />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import UxSeparator from '@/ux/field/UxSeparator';
    import Button from 'primevue/button';

    import UxDataSourceBaseEditPanel from "@/ux/panel/UxDataSourceBaseEditPanel";
    import UxDataSourcePropertyEditPanel from "@/ux/panel/UxDataSourcePropertyEditPanel";
    import UxDataSourceWebApiEditPanel from "@/ux/panel/UxDataSourceWebApiEditPanel";

    import DataUtils from "@/app/util/data-utils.mjs";

    import DataSourceService from '@/app/service/DataSourceService';

    export default {
        components: {
            Dialog,
            UxSeparator,
            Button,
            UxDataSourceBaseEditPanel,
            UxDataSourcePropertyEditPanel,
            UxDataSourceWebApiEditPanel
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                mode: 'data-source-type',

                name: 'データソース',
                desc: '',
                geomType: DataSourceService.GT_POLYGON,
                dataSourceType: DataSourceService.DT_FREE,
                attributes: [],
                data: {}
            };
        },
        watch: {
            visible(newValue) {
                const me = this;
                if (newValue) {
                    me.name = 'データソース';
                    me.desc = '';
                    me.geomType = DataSourceService.GT_POLYGON;
                    me.dataSourceType = DataSourceService.DT_FREE;
                    me.attributes = [{
                        id: `${DataUtils.generateId()}0`,
                        type: 'string',
                        key: 'id',
                        name: 'ID',
                        desc: '※変更不可'
                    },{
                        id: `${DataUtils.generateId()}0`,
                        type: 'string',
                        key: 'name',
                        name: '名称',
                        desc: '※変更不可'
                    }];
                    me.data = {};
                    me.mode = 'data-source-base';
                }
            }
        },
        computed: {
            disabledPrev() {
                return this.mode == 'data-source-base';
            },
            disabledNext() {
                if (this.mode == 'data-source-base' && !this.dataSourceType) {
                    return true;
                }
                return !(this.mode == 'data-source-base' || this.mode == 'data-source-property')
                    || (this.mode == 'data-source-property' && this.dataSourceType == DataSourceService.DT_FREE);
            },
            disabledOK() {
                return (this.mode == 'data-source-base' || this.mode == 'data-source-property')
                    && !(this.mode == 'data-source-property' && this.dataSourceType == DataSourceService.DT_FREE);
            }
        },
        emits: ['change-visible', 'created'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "前へ"ボタン押下時処理
             */
            onPrev() {
                const me = this;
                switch (me.mode) {
                    case 'data-source-property':
                        {
                            me.attributes = [];
                            me.mode = 'data-source-base';
                        }
                        break;
                    default:
                        {
                            me.data = {};
                            me.mode = 'data-source-property';
                        }
                        break;
                }
            },

            /**
             * "次へ"ボタン押下時処理
             */
            onNext() {
                const me = this;
                switch (me.mode) {
                    case 'data-source-base':
                        {
                            if (!me.dataSourceType) {
                                break;
                            }
                            me.mode = 'data-source-property';
                        }
                        break;
                    case 'data-source-property':
                        {
                            switch (me.dataSourceType) {
                                case DataSourceService.DT_FREE:
                                    {
                                        me.data = this.$Service.DataSourceService.createFeatureCollection();
                                        me.mode = 'data-source-free';
                                    }
                                    break;
                                case DataSourceService.DT_GEOJSON:
                                    {
                                        me.data = this.$Service.DataSourceService.createFeatureCollection();
                                        me.mode = 'data-source-geojson';
                                    }
                                    break;
                                case DataSourceService.DT_GEOJSON_API:
                                    {
                                        me.data = {
                                            requestUrl: 'https://maplibre.org/maplibre-gl-js/docs/assets/earthquakes.geojson',
                                            requestMethod: 'GET',
                                            requestHeader: null,
                                            requestBody: null,
                                            responseProperty: null
                                        }
                                        me.mode = 'data-source-geojson-api';
                                    }
                                    break;
                                case DataSourceService.DT_MVT:
                                    {
                                        me.data = {
                                            requestUrl: 'https://maplibre.org/maplibre-gl-js/docs/assets/earthquakes.geojson',
                                            requestMethod: 'GET',
                                            requestHeader: null,
                                            requestBody: null,
                                            responseProperty: null
                                        }
                                        me.mode = 'data-source-mvt';
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        break;
                }
            },

            /**
             * "OK"ボタン押下時処理
             */
            onOK() {
                // データ初期化
                if (this.dataSourceType == DataSourceService.DT_FREE) {
                    this.data = this.$Service.DataSourceService.createFeatureCollection();
                }
                // 作成内容通知
                this['$emit']('created', {
                    name: this.name,
                    desc: this.desc,
                    geomType: this.geomType,
                    dataSourceType: this.dataSourceType,
                    attributes: this.attributes.map(function(value) { delete value.id; return value; }),
                    data: this.data
                });
                // クローズ
                this.onVisible(false);
            },

            /**
             * "キャンセル"ボタン押下時処理
             */
            onCancel() {
                // クローズ
                this.onVisible(false);
            }
        }
    };
</script>

<style scoped>
</style>