<template>
    <div>
        <ExFieldset legend="データ" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">属性：</span>
                <Dropdown v-model="selectedProperty" :options="properties" optionLabel="name" optionValue="key"
                    placeholder="属性" class="w-8rem" @change="this.$emit('update:property', this.selectedProperty)" />
            </div>
        </ExFieldset>
        <ExFieldset legend="文字" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">文字色：</span>
                <ColorPicker class="mt-1" v-model="selectedLabelColor" inputId="color" format="hex" @change="this.$emit('update:labelColor', this.selectedLabelColor)" />
            </div>
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">サイズ：</span>
                <Dropdown v-model="selectedLabelSize" :options="fontSizes" optionLabel="value" optionValue="value"
                    placeholder="文字サイズ" class="w-8rem" @change="this.$emit('update:labelSize', this.selectedLabelSize)" />
            </div>
        </ExFieldset>
        <ExFieldset legend="枠" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">枠色：</span>
                <ColorPicker class="mt-1" v-model="selectedLabelOutlineColor" inputId="color" format="hex" @change="this.$emit('update:labelOutlineColor', this.selectedLabelOutlineColor)" />
            </div>
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">枠幅：</span>
                <Dropdown v-model="selectedLabelOutlineWidth" :options="outlineWidths" optionLabel="value" optionValue="value"
                    placeholder="枠幅" class="w-8rem" @change="this.$emit('update:labelOutlineWidth', this.selectedLabelOutlineWidth)" />
            </div>
        </ExFieldset>
        <ExFieldset legend="位置" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">Ｘ位置：</span>
                <Dropdown v-model="selectedXOffset" :options="offsets" optionLabel="value" optionValue="value"
                    placeholder="Ｘ位置" class="w-8rem" @change="this.$emit('update:xOffset', this.selectedXOffset)" />
            </div>
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">Ｙ位置：</span>
                <Dropdown v-model="selectedYOffset" :options="offsets" optionLabel="value" optionValue="value"
                    placeholder="Ｙ位置" class="w-8rem" @change="this.$emit('update:yOffset', this.selectedYOffset)" />
            </div>
        </ExFieldset>
    </div>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import ColorPicker from 'primevue/colorpicker';
    import Dropdown from 'primevue/dropdown';

    export default {
        components: {
            ExFieldset,
            ColorPicker,
            Dropdown
        },
        props: {
            property: {
                type: String,
                default: 'name'
            },
            labelColor: {
                type: String,
                default: "ff0000"
            },
            labelSize: {
                type: Number,
                default: 10
            },
            labelOutlineColor: {
                type: String,
                default: "ff0000"
            },
            labelOutlineWidth: {
                type: Number,
                default: 1
            },
            xOffset: {
                type: Number,
                default: 0
            },
            yOffset: {
                type: Number,
                default: 0
            },

            dataSource: {
                type: Object,
                default: function() { return {}; }
            },
        },
        data() {
            return {
                selectedProperty: this.property,
                selectedLabelColor: this.labelColor,
                selectedLabelSize: this.labelSize,
                selectedLabelOutlineColor: this.labelOutlineColor,
                selectedLabelOutlineWidth: this.labelOutlineWidth,
                selectedXOffset: this.xOffset,
                selectedYOffset: this.yOffset
            };
        },
        computed: {
            properties() {
                return (this.dataSource||{}).attributes;
            },
            fontSizes() {
                const items = [];
                for (let i = 1; i <= 30; i++) {
                    items.push({ value: i });
                }
                return items;
            },
            outlineWidths() {
                const items = [];
                for (let i = 1; i <= 10; i++) {
                    items.push({ value: i });
                }
                return items;
            },
            offsets() {
                const items = [];
                for (let i = 1; i <= 10; i++) {
                    items.push({ value: i });
                }
                return items;
            }
        },
        emits: [
            'update:property',
            'update:labelColor',
            'update:labelSize',
            'update:labelOutlineColor',
            'update:labelOutlineWidth',
            'update:xOffset',
            'update:yOffset'
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>