import StringUtils from '@/app/util/string-utils.mjs';

/**
 * マッチングユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class MatchUtils {

	/**
	 * IPアドレスにマッチング
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isIpaddress(value) {
		return StringUtils.isNotEmpty(value) &&  /^[0-9a-z.*]*$/i.test(value);
	}

	/**
	 * リファラにマッチング
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isReferer(value) {
		return StringUtils.isNotEmpty(value) &&  /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i.test(value);
	}

	/**
	 * 英字にマッチング
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isAlphabet(value) {
		return StringUtils.isNotEmpty(value) &&  /^[a-zA-Z]*$/i.test(value);
	}

	/**
	 * 数字にマッチング
	 * @param {String} value 文字列
	 * @returns {Boolean} true or false
	 */
	static isNumber(value) {
		return StringUtils.isNotEmpty(value) &&  /^[1-9]*$/i.test(value);
	}
}
export default MatchUtils;