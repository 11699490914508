import AbstractMapAction from "@/app/core/mapaction/AbstractMapAction.js";

import * as turf from '@turf/turf';

/**
 * 距離計測アクション
 * @author t.kimu
 * @since 1.0
 * @version 1.0
 */
export default class MapDistanceAction extends AbstractMapAction {

	_nodes = null;

	// レイヤID
	id = {
		SOURCE_LINE:	'draw-distance-line',
		SOURCE_POINT:	'draw-distance-point',
		SOURCE_LABEL:	'draw-distance-label',
		LAYER_LINE:		'draw-distance-layer-line',
		LAYER_POINT:	'draw-distance-layer-point',
		LAYER_LABEL:	'draw-distance-layer-label'
	};

	// 描画用レイヤ定義
    drawLayers = {
		"draw-distance-layer-line": {
			"type": "line",
			"paint": {
				"line-color": "#fbb03b",
				"line-opacity": 1.0,
				"line-dasharray": [1, 1],
				"line-width": 2
			}
		},
		"draw-distance-layer-point": {
			"type": "circle",
			"paint": {
				"circle-radius": 3,
				"circle-color": "#fbb03b"
			}
		},
		"draw-distance-layer-label": {
			"type": "symbol",
			"layout": {
				"text-field": ["get", "name"],
				"text-size": 14,
				"text-line-height": 1.4,
				"text-overlap": "always"
			},
			"paint": {
				"text-color": "#00f",
				"text-halo-color": "#fff",
				"text-halo-width": 2
			},
		}
	};

	// イベント時処理
	// ※イベント削除時、同一インスタンスの同一処理でないと削除されない。
	_onClick = null;
	_onRClick = null;
	_onMove = null;
	_onDblClick = null;

	/**
	 * コンストラクタ
	 * @param {Object} config コンフィグ
	 */
	constructor (config={}) {
		super(config);
		if (config.drawn !== undefined) {
			this.drawn = config.drawn;
		}
	}

	/**
	 * モードON
	 */
	on() {
		const me = this;
		if (me.equalMode(me.mode.ON)) {
			return;
		}
		me.setDragLineMode(me.mode.ON);
	}

	/**
	 * モードOFF
	 */
	off() {
		const me = this;
		if (me.equalMode(me.mode.OFF)) {
			return;
		}
		me.setDragLineMode(me.mode.OFF);
	}

	/**
	 * ライン描画モード設定
	 * @param {Number} mode ON／OFF
	 */
	setDragLineMode(mode) {
		const me = this,
			map = me.getMap();

		// モード設定
		me._mode = mode;
		me._nodes = null;
		// 描画用ライン
		let dataLine = {
			'type': 'Feature',
			'geometry': {
				'type': 'LineString',
				'coordinates': null
			}
		};
		// 描画用ポイント
		let dataPoint = {
			'type': 'Feature',
			'geometry': {
				'type': 'MultiPoint',
				'coordinates': null
			}
		};
		// 描画用ラベル
		let dataLabel = {
			'type': 'Feature',
			'properties': {
				'name': ''
			},
			'geometry': {
				'type': 'Point',
				'coordinates': null
			}
		};
		// イベント設定
		switch (mode) {
			case me.mode.ON:
				me._onClick = function onClick(e) {
					if (!me._nodes) {
						me._nodes = [];
					}
					let coords = e.lngLat;
					me._nodes.push([coords.lng, coords.lat]);
					me._onMove(e);
				}
				// 右クリック時の処理
				me._onRClick = function onRClick(e) {
					if (me._nodes) {
						me._nodes.pop();
					}
					me._onMove(e);
				}
				// 移動時処理
				me._onMove = function onMove(e) {
					// 座標設定
					let coords = e.lngLat;
					let coordinates = [];
					if (me._nodes) {
						for (let i = 0; i < me._nodes.length; i++) {
							coordinates.push(me._nodes[i]);
						}
					}
					coordinates.push([coords.lng, coords.lat]);
					// 座標更新
					let sourceLine = map.getSource(me.id.SOURCE_LINE);
					if (sourceLine) {
						dataLine.geometry.coordinates = coordinates;
						sourceLine.setData(dataLine);
					}
					let sourcePoint = map.getSource(me.id.SOURCE_POINT);
					if (sourcePoint) {
						dataPoint.geometry.coordinates = coordinates;
						sourcePoint.setData(dataPoint);
					}
					let sourceLabel = map.getSource(me.id.SOURCE_LABEL);
					if (sourceLabel) {
						//ポイントが１個以下の場合は0mを表示
						dataLabel.properties.name = 0 + 'm';
						dataLabel.geometry.coordinates = [coords.lng, coords.lat];
						sourceLabel.setData(dataLabel);
						//距離計測(Turf.jsは初期はkm表記)
						//コンソールにエラーが出る為、ポイントが２個以上でないと処理しない
						if (coordinates.length >= 2) {
							var line = turf.lineString(coordinates);
							var distance = turf.length(line);
							if (distance < 1) {
								distance *= 1000;
								dataLabel.properties.name = distance.toFixed(2) + 'm';
								dataLabel.geometry.coordinates = [coords.lng, coords.lat];
								sourceLabel.setData(dataLabel);
							} else {
								dataLabel.properties.name = distance.toFixed(2) + 'km';
								dataLabel.geometry.coordinates = [coords.lng, coords.lat];
								sourceLabel.setData(dataLabel);
							}
						}
					}
				}
				// ダブルクリック時の処理
				me._onDblClick = function onDblClick(e) {
					// ズーム無効化
					let zoom = map.doubleClickZoom.isEnabled();
					if (zoom) {
						map.doubleClickZoom.disable();
					}
					// 最終ノード追加
					if (!me._nodes || me._nodes.length < 2) {
						return;
					}
					let coords = e.lngLat;
					me._nodes.push([coords.lng, coords.lat]);
					// 処理終了
					var line = turf.lineString(me._nodes);
					var distance = turf.length(line);
					if (me.drawn) {
						me.drawn(distance);
					}
					// ズーム復元
					if (zoom) {
						setTimeout(function() {
							map.doubleClickZoom.enable();
						}, 100);
					}
				}
				// ソース登録
				if (!map.getSource(me.id.SOURCE_LINE)) {
					map.addSource(me.id.SOURCE_LINE, {
						'type': 'geojson',
						'data': null
					});
				}
				if (!map.getSource(me.id.SOURCE_POINT)) {
					map.addSource(me.id.SOURCE_POINT, {
						'type': 'geojson',
						'data': null
					});
				}
				if (!map.getSource(me.id.SOURCE_LABEL)) {
					map.addSource(me.id.SOURCE_LABEL, {
						'type': 'geojson',
						'data': null
					});
				}
				// レイヤ登録
				if (!map.getLayer(me.id.LAYER_LINE)) {
					let layerLine = me.drawLayers[me.id.LAYER_LINE];
					let layerData = {
						'id': me.id.LAYER_LINE,
						'source': me.id.SOURCE_LINE
					};
					for (const key in layerLine) {
						layerData[key] = layerLine[key];
					}
					map.addLayer(layerData);
				}
				if (!map.getLayer(me.id.LAYER_POINT)) {
					let layerPoint = me.drawLayers[me.id.LAYER_POINT];
					let layerData = {
						'id': me.id.LAYER_POINT,
						'source': me.id.SOURCE_POINT
					};
					for (const key in layerPoint) {
						layerData[key] = layerPoint[key];
					}
					map.addLayer(layerData);
				}
				if (!map.getLayer(me.id.LAYER_LABEL)) {
					let layerLabel = me.drawLayers[me.id.LAYER_LABEL];
					let layerData = {
						'id': me.id.LAYER_LABEL,
						'source': me.id.SOURCE_LABEL
					};
					for (const key in layerLabel) {
						layerData[key] = layerLabel[key];
					}
					map.addLayer(layerData);
				}
				// イベント登録
				map.on('click', me._onClick);
				map.on('dblclick', me._onDblClick);
				map.on('contextmenu', me._onRClick);
				map.on('mousemove', me._onMove);
				break;
			case me.mode.OFF:
				// レイヤー削除
				if (map.getLayer(me.id.LAYER_LINE)) {
					map.removeLayer(me.id.LAYER_LINE);
				}
				if (map.getLayer(me.id.LAYER_POINT)) {
					map.removeLayer(me.id.LAYER_POINT);
				}
				if (map.getLayer(me.id.LAYER_LABEL)) {
					map.removeLayer(me.id.LAYER_LABEL);
				}
				if (map.getSource(me.id.SOURCE_LINE)) {
					map.removeSource(me.id.SOURCE_LINE);
				}
				if (map.getSource(me.id.SOURCE_POINT)) {
					map.removeSource(me.id.SOURCE_POINT);
				}
				if (map.getSource(me.id.SOURCE_LABEL)) {
					map.removeSource(me.id.SOURCE_LABEL);
				}
				// イベント削除
				map.off('click', me._onClick);
				map.off('contextmenu', me._onRClick);
				map.off('mousemove', me._onMove);
				map.off('dblclick', me._onDblClick);
				break;
			default:
				break;
		}
	}
}