import ObjectUtils from '@/app/util/object-utils.mjs';
import StringUtils from '@/app/util/string-utils.mjs';
import { unitsFactors, point, lineString, featureCollection } from "@turf/helpers";
import { coordEach } from '@turf/meta';
import clone from '@turf/clone';
import buffer from "@turf/buffer";
import booleanContains from '@turf/boolean-contains';
import booleanCrosses from '@turf/boolean-crosses';
import booleanDisjoint from '@turf/boolean-disjoint';
import booleanIntersects from '@turf/boolean-intersects';
import booleanOverlap from '@turf/boolean-overlap';
import booleanWithin from '@turf/boolean-within';
import cleanCoords from '@turf/clean-coords';
import truncate from '@turf/truncate';
import simplify from '@turf/simplify';
import mask from '@turf/mask';
import union from '@turf/union';
import dissolve from '@turf/dissolve';
import difference from '@turf/difference';
import intersect from '@turf/intersect';
import length from '@turf/length';
import area from '@turf/area';
import distance from '@turf/distance';
import destination from '@turf/destination';
import transformTranslate from '@turf/transform-translate';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import square from '@turf/square';
import centerOfMass from '@turf/center-of-mass';
import centroid from '@turf/centroid';
import pointOnFeature from '@turf/point-on-feature';
import polygonSmooth from '@turf/polygon-smooth';
import bezierSpline from '@turf/bezier-spline';
import circle from '@turf/circle';
import sector from '@turf/sector';
import ellipse from '@turf/ellipse';
import voronoi from '@turf/voronoi';
import concave from '@turf/concave';
import convex from '@turf/convex';
import tin from '@turf/tin';

/**
 * ジオメトリユーティリティ
 * @author nsc
 * @since 1.0
 * @version 1.0
 */
class GeometryUtils {

	/** 空間参照系 */
	static DEFAULT_SRID = 4326;

	/**
	 * ジオメトリ⇒GeoJSON
	 * @param {Feature} geometry ジオメトリ
	 * @returns {String} GeoJSON
	 */
	static geometryToGeojson(geometry) {
		if (geometry.type === "Feature") {
			return ObjectUtils.toJson(geometry.geometry);
		}
		return ObjectUtils.toJson(geometry);
	}

	/**
	 * ジオメトリ⇒GeoJSON
	 * @param {String} geojson GeoJSON
	 * @returns {Feature} ジオメトリ
	 */
	static geojsonToGeometry(geojson) {
		const obj = ObjectUtils.fromJson(geojson);
		if (obj.type === "Feature") {
			return obj.geometry;
		}
		return obj;
	}

	/**
	 * ポリゴン判定
	 * @param {Feature} geometry ジオメトリ
	 * @param {Boolean} multi マルチも含む
	 * @returns {Boolean} true or false
	 */
	static isPolygon(geometry, multi=true) {
		const type = StringUtils.toUpperCase(geometry.type);
		return (multi ? (type === "MULTIPOLYGON") || (type === "POLYGON") : (type === "POLYGON"));
	}

	/**
	 * ポイント判定
	 * @param {Feature} geometry ジオメトリ
	 * @param {Boolean} multi マルチも含む
	 * @returns {Boolean} true or false
	 */
	static isPoint(geometry, multi=true) {
		const type = StringUtils.toUpperCase(geometry.type);
		return (multi ? (type === "MULTIPOINT") || (type === "POINT") : (type === "POINT"));
	}

	/**
	 * ライン判定
	 * @param {Feature} geometry ジオメトリ
	 * @param {Boolean} multi マルチも含む
	 * @returns {Boolean} true or false
	 */
	static isLine(geometry, multi=true) {
		const type = StringUtils.toUpperCase(geometry.type);
		return (multi ? (type === "MULTILINESTRING") || (type === "LINESTRING") : (type === "LINESTRING"));
	}

	/**
	 * クローン
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Feature} ジオメトリ
	 */
	static clone(geometry) {
		return clone(geometry);
	}

	/**
	 * バッファリング
	 * @param {Feature} geometry ジオメトリ
	 * @param {Number} radius 半径（m）
	 * @param {Number} steps ステップ数
	 * @returns {Feature} ジオメトリ
	 */
	static buffer(geometry, radius, steps=8) {
		return buffer(geometry, radius, { units: 'meters', steps: steps });
	}

	/**
	 * 内包判定
	 * 2 番目のジオメトリが最初のジオメトリに完全に含まれている場合に True を返します。
	 * @param {Feature} geometry1 ジオメトリ１
	 * @param {Feature} geometry2 ジオメトリ２
	 * @returns {Boolean} true or false
	 */
	static isContains(geometry1, geometry2) {
		return booleanContains(geometry1, geometry2);
	}

	/**
	 * クロス判定
	 * @param {Feature} geometry1 ジオメトリ１
	 * @param {Feature} geometry2 ジオメトリ２
	 * @returns {Boolean} true or false
	 */
	static isCrosses(geometry1, geometry2) {
		return booleanCrosses(geometry1, geometry2);
	}

	/**
	 * 交差判定
	 * AとBに共通部分が存在する場合にTRUEを返します。
	 * @param {Feature} geometry1 ジオメトリ１
	 * @param {Feature} geometry2 ジオメトリ２
	 * @returns {Boolean} true or false
	 */
	static isIntersects(geometry1, geometry2) {
		return booleanIntersects(geometry1, geometry2);
	}

	/**
	 * 交差なし判定
	 * AとBに共通部分が無い場合にTRUEを返します。（Intersectsの反対）
	 * @param {Feature} geometry1 ジオメトリ１
	 * @param {Feature} geometry2 ジオメトリ２
	 * @returns {Boolean} true or false
	 */
	static isDisjoint(geometry1, geometry2) {
		return booleanDisjoint(geometry1, geometry2);
	}

	/**
	 * オーバーラップ判定
	 * AとBに共通部分が存在し、AまたはBがもう一方に包含さていない場合にTRUEを返します。
	 * @param {Feature} geometry1 ジオメトリ１
	 * @param {Feature} geometry2 ジオメトリ２
	 * @returns {Boolean} true or false
	 */
	static isOverlap(geometry1, geometry2) {
		return booleanOverlap(geometry1, geometry2);
	}

	/**
	 * 内包判定
	 * AがBを完全に内包する場合にTRUEを返します。
	 * @param {Feature} geometry1 ジオメトリ１
	 * @param {Feature} geometry2 ジオメトリ２
	 * @returns {Boolean} true or false
	 */
	static isWithin(geometry1, geometry2) {
		return booleanWithin(geometry1, geometry2);
	}

	/**
	 * 冗長な座標を削除
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Feature} ジオメトリ
	 */
	static cleanCoords(geometry) {
		return cleanCoords(geometry);
	}

	/**
	 * 指定された座標の小数桁数を切り捨て
	 * @param {Feature} geometry ジオメトリ
	 * @param {Number} scale 小数桁数
	 * @returns {Feature} ジオメトリ
	 */
	static truncate(geometry, scale) {
		const options = { precision: 6, coordinates: scale };
		return truncate(geometry, options);
	}

	/**
	 * ポリゴン簡易化
	 * @param {Feature} geometry ジオメトリ
	 * @param {Number} tolerance 許容差
	 * @returns {Feature} ジオメトリ
	 */
	static simplify(geometry, tolerance=0.01) {
		const options = { tolerance: tolerance, highQuality: false };
		return simplify(geometry, options);
	}

	/**
	 * 合成ポリゴン作成
	 * ２つ以上の (Multi)Polygon を受け取り、結合されたポリゴンを返します。
	 * @param {Array<Feature<(Polygon|MultiPolygon)>>} polygons ポリゴン配列
	 * @returns {Feature<(Polygon|MultiPolygon)>} ポリゴン
	 */
	static union(geometries) {
		let geom = null;
		for (const geometry of geometries) {
			if (geom) {
				union(geom, geometry);
			} else {
				geom = geometry;
			}
		}
		return geom;
	}

	/**
	 * 合成ポリゴン作成
	 * 属性が同じ値を持つ地物同士を一つに融合します。
	 * @param {FeatureCollection<Polygon>} polygons ポリゴン
	 * @param {String} propertyName カテゴリキー
	 * @returns {FeatureCollection<(Polygon)>} ポリゴン
	 */
	static dissolve(polygons, propertyName) {
		return dissolve(polygons, { propertyName: propertyName });
	}

	/**
	 * 切取ポリゴン作成
	 * polygon1 の領域から polygon2 の領域を除く Polygon または MultiPolygon を返します。
	 * @param {Feature<(Polygon|MultiPolygon)>} polygon1 ポリゴン１
	 * @param {Feature<(Polygon|MultiPolygon)>} polygon2 ポリゴン２
	 * @returns {Feature<(Polygon|MultiPolygon)>} ポリゴン
	 */
	static difference(polygon1, polygon2) {
		return difference(polygon1, polygon2);
	}

	/**
	 * 共有ポリゴン作成
	 * 共有する領域を表すフィーチャ ( Polygon または MultiPolygon のいずれか) を返します。領域を共有していない場合は、null を返します。
	 * @param {Feature<(Polygon|MultiPolygon)>} polygon1 ポリゴン１
	 * @param {Feature<(Polygon|MultiPolygon)>} polygon2 ポリゴン２
	 * @returns {Feature<(Polygon|MultiPolygon)>} ポリゴン
	 */
	static intersect(polygon1, polygon2) {
		return intersect(polygon1, polygon2);
	}

	/**
	 * 距離算出
	 * @param {Number} x1 経度１
	 * @param {Number} y1 緯度１
	 * @param {Number} x2 経度２
	 * @param {Number} y2 緯度２
	 * @returns {Number} 距離（m）
	 */
	static calcDistance(x1, y1, x2, y2) {
		const from = point([x1, y1]);
		const to = point([x2, y2]);
		const options = { units: 'meters' };
		return distance(from, to, options);
	}

	/**
	 * 長さ算出
	 * @param {Feature<LineString|MultiLineString>} line ラインジオメトリ
	 * @returns {Number} 長さ（m）
	 */
	static calcLength(line) {
		const options = { units: 'meters' };
		return length(line, options);
	}

	/**
	 * 面積算出
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Number} 面積（㎡）
	 */
	static calcArea(geometry) {
		return area(geometry);
	}

	/**
	 * ジオメトリ移動
	 * @param {Feature} geometry ジオメトリ
	 * @param {Number} distance 移動距離（m）
	 * @param {Number} angle 角度（北が０度）
	 * @returns {Feature} ジオメトリ
	 */
	static move(geometry, distance, angle) {
		const options = { units: 'meters' };
		return transformTranslate(geometry, distance, angle, options);
	}

	/**
	 * BBOX取得
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Object} BBOX
	 */
	static getCoordCount(geometry) {
		let pointCount = 0;
		coordEach(geometry, (coord, coordIndex, coordSubIndex, featureIndex, multiFeatureIndex) => {
            pointCount++;
        });
		return pointCount;
	}

	/**
	 * BBOX取得
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Object} BBOX
	 */
	static getBbox(geometry) {
		const result = bbox(geometry);
		return {
			minX: result[0],
			minY: result[1],
			maxX: result[2],
			maxY: result[3]
		};
	}

	/**
	 * BBOXポリゴン変換
	 * @param {Object} BBOX
	 * @returns {Feature<Polygon>} ジオメトリ
	 */
	static bboxToPolygon(bbox) {
		return bboxPolygon(bbox);
	}

	/**
	 * BBOX正方形変換
	 * BBOXを取得し、最小の正方形の境界ボックスを計算します。
	 * @param {Object} BBOX
	 * @returns {Object} BBOX
	 */
	static bboxToSquare(bbox) {
		return square(bbox);
	}

	/**
	 * 中心点取得
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Feature<Point>} 中心点ジオメトリ
	 */
	static getCenter(geometry) {
		return center(geometry);
	}

	/**
	 * 重心点取得
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Feature<Point>} 重心点ジオメトリ
	 */
	static getCenterOfMass(geometry) {
		return centerOfMass(geometry);
	}

	/**
	 * 質量中心点取得
	 * １つ以上のフィーチャを取得し、すべての頂点の平均を使用して重心を計算します。
	 * これにより、一連のポリゴンの重心を計算する際の小さな島やアーティファクトの影響が軽減されます。
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Feature<Point>} 質量中心点ジオメトリ
	 */
	static getCenter(geometry) {
		return centroid(geometry);
	}

	/**
	 * ラベル点取得
	 * Feature または FeatureCollection を受け取り、フィーチャの表面上にあることが保証されている Point を返します
	 * @param {Feature} geometry ジオメトリ
	 * @returns {Feature<Point>} ラベル点ジオメトリ
	 */
	static getLabelPoint(geometry) {
		return pointOnFeature(geometry);
	}

	/**
	 * スムージング（ポリゴン）処理
	 * @param {Object} ジオメトリ
	 * @returns {Object} ジオメトリ
	 */
    static toSmoothPolygon(geometry) {
        const options = { iterations: 1 };
		return polygonSmooth(geometry, options);
	}

	/**
	 * スムージング（線）処理
	 * @param {Feature<LineString>} ジオメトリ
	 * @returns {Object} ジオメトリ
	 */
    static toSmoothLine(geometry) {
        const options = { resolution: 10000, sharpness: 0.85 };
		return bezierSpline(geometry, options);
	}

	/**
	 * 円形ポリゴン作成
	 * @param {Number} longitude 経度
	 * @param {Number} latitude 緯度
	 * @param {Number} radius 半径（m）
	 * @param {Number} steps ポイント数
	 * @returns {Object} ジオメトリ
	 */
	static createCirclePolygon(longitude, latitude, radius, steps=360) {
        const center = [longitude, latitude];
        const options = { steps: steps, units: 'meters' };
        const geometry = circle(center, radius, options);
		return geometry;
	}

	/**
	 * ドーナツポリゴン作成
	 * @param {Number} longitude 経度
	 * @param {Number} latitude 緯度
     * @param {Number} outerRadius 外円半径(m)
     * @param {Number} innerRadius 内円半径(m)
	 * @param {Number} steps ポイント数
	 * @returns {Object} ジオメトリ
	 */
    static createDonutsPolygon(longitude, latitude, outerRadius, innerRadius, steps=360) {
		let outerCircle = GeometryUtils.createCirclePolygon(longitude, latitude, outerRadius, steps);
		let innerCircle = GeometryUtils.createCirclePolygon(longitude, latitude, innerRadius, steps);
		return mask(outerCircle, innerCircle);
	}

	/**
	 * 扇形ポリゴン作成
	 * @param {Number} longitude 経度
	 * @param {Number} latitude 緯度
	 * @param {Number} radius 半径（m）
	 * @param {Number} startRad 開始角度
	 * @param {Number} sizeRad サイズ角度
	 * @param {Number} steps ポイント数
	 * @returns {Object} ジオメトリ
	 */
    static createSectorPolygon(longitude, latitude, radius, startRad, sizeRad, steps=360) {
		const center = point([longitude, latitude]);
        const options = { steps: steps, units: 'meters' };
		return sector(center, radius, startRad, sizeRad, options);
	}

	/**
	 * 楕円ポリゴン作成
	 * @param {Number} longitude 経度
	 * @param {Number} latitude 緯度
	 * @param {Number} xSemiAxis X軸半径（m）
	 * @param {Number} ySemiAxis Y軸半径（m）
	 * @param {Number} angle 回転角度
	 * @param {Number} steps ポイント数
	 * @returns {Object} ジオメトリ
	 */
    static createEllipsePolygon(longitude, latitude, xSemiAxis, ySemiAxis, angle=0, steps=360) {
		const center = point([longitude, latitude]);
        const options = { steps: steps, units: 'meters', angle: angle };
		return ellipse(center, xSemiAxis, ySemiAxis, options);
	}

	/**
	 * 矩形ポリゴン作成
	 * @param {Number} longitude 中心経度
	 * @param {Number} latitude 中心緯度
	 * @param {Number} width 矩形幅（m）
	 * @param {Number} height 矩形高さ（m）
	 * @returns {Object} ジオメトリ
	 */
	static createRectPolygon(longitude, latitude, width, height) {
		const center = point([longitude, latitude]);
		const options = { units: 'meters' };
		const northWest = destination(center,  width / 2, 135 + 180, options);
		const southEast = destination(center, height / 2, 135,       options);
		const features = featureCollection([northWest, southEast]);
		return envelope(features);
	}

	/**
	 * BBOXポリゴン作成
	 * @param {Number} minX 最小経度
	 * @param {Number} minY 最小緯度
	 * @param {Number} maxX 最大経度
	 * @param {Number} maxY 最大緯度
	 * @returns {Object} ジオメトリ
	 */
	static createBBoxPolygon(minX, minY, maxX, maxY) {
		return bboxToPolygon([minX, minY, maxX, maxY]);
	}

	/**
	 * 線側ポリゴン作成
	 * @param {Array<Array<Number>>} points ポイントリスト
	 * @param {Number} width 幅（m）
	 * @param {Boolean} smoothing スムージング処理フラグ
	 * @returns {Object} ジオメトリ
	 */
	static createLinePolygon(points, width, smoothing=false) {
		const line = smoothing ? GeometryUtils.toSmoothLine(lineString(points)) : lineString(points);
		const geometry = GeometryUtils.buffer(line, width / 2);
		return geometry;
	}

	/**
	 * ボロノイポリゴン作成
	 * @param {FeatureCollection<Point>} points ポイントジオメトリ配列
	 * @param {Object} bbox 対象範囲BBOX
	 * @returns {Object} ジオメトリ
	 */
    static createVoronoiPolygons(points, bbox) {
        const options = { bbox: bbox };
		const features = [];
		for (const p of points) {
			features.push(point(p));
		}
		const featureCollection = featureCollection(features);
		return voronoi(featureCollection, options);
	}

	/**
	 * 凹包ポリゴン作成
	 * 一連の点を取得し、凹包 Polygon または MultiPolygon を返します。内部的には、turf-tin を使用してジオメトリを生成します。
	 * @param {FeatureCollection<Point>} points ポイントジオメトリ配列
	 * @returns {Feature} ジオメトリ
	 */
    static createConcavePolygons(points) {
        const options = { maxEdge: Infinity, units: 'meters' };
		return concave(points, options);
	}

	/**
	 * 凸包ポリゴン作成
	 * Feature または FeatureCollection を取得し、凸包 Polygon を返します。
	 * @param {Feature} geometry ポイントジオメトリ配列
	 * @returns {Feature} ジオメトリ
	 */
    static createConvexPolygons(geometry) {
        const options = { concavity: Infinity };
		return convex(geometry, options);
	}

	/**
	 * TINポリゴン作成
	 * 一連の点を取得し、ポリゴンのコレクションとして返される不規則三角形ネットワーク (略して TIN) を作成します。
	 * これらは、標高等高線図や段階的な熱の視覚化の開発によく使用されます。
	 * @param {FeatureCollection<Point>} points ポイントジオメトリ配列
	 * @returns {FeatureCollection<Polygon>} ジオメトリ
	 */
    static createTinPolygons(points) {
		return tin(points, 'z');
	}

}
export default GeometryUtils;