<template>
    <ExFieldset legend="塗りつぶし" :toggleable="false" class="m-2">
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">塗色：</span>
            <ColorPicker class="mt-1" v-model="selectedFillColor" inputId="color" format="hex" @change="this.$emit('update:fillColor', this.selectedFillColor)" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">透過率：</span>
            <Slider v-model.number="selectedFillOpacity" :min="0" :max="1" :step="0.1" orientation="horizontal" class="w-3 mt-3" @change="this.$emit('update:fillOpacity', this.selectedFillOpacity)" />
            <div class="px-2 mt-2">{{ selectedFillOpacity * 100 }} %</div>
        </div>
    </ExFieldset>
    <ExFieldset legend="枠線" :toggleable="false" class="m-2">
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-4rem mt-2">枠色：</span>
            <ColorPicker class="mt-1" v-model="selectedOutlineColor" inputId="color" format="hex" @change="this.$emit('update:outlineColor', this.selectedOutlineColor)" />
        </div>
    </ExFieldset>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import ColorPicker from 'primevue/colorpicker';
    import Slider from 'primevue/slider';

    export default {
        components: {
            ExFieldset,
            ColorPicker,
            Slider
        },
        props: {
            fillColor: {
                type: String,
                default: "ff0000"
            },
            fillOpacity: {
                type: Number,
                default: 0.4
            },
            outlineColor: {
                type: String,
                default: "ff0000"
            },
        },
        data() {
            return {
                selectedFillColor: this.fillColor,
                selectedFillOpacity: this.fillOpacity,
                selectedOutlineColor: this.outlineColor
            };
        },
        emits: [
            'update:fillColor',
            'update:fillOpacity',
            'update:outlineColor'
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>