<template>
    <Dialog modal v-bind:visible="visible" header="バージョン" :style="{ width: '40vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div>バージョン番号：{{ version }}</div>
        <div>{{ copyright }}</div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';

    export default {
        components: {
            Dialog
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                version: this.$Settings.version,
                copyright: this.$Settings.copyright
            }
        },
        mounted() {
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            }
        }
    };
</script>

<style scoped>
</style>