<template>
    <div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">名称：</span>
            <InputText type="text" v-model="selectedName" placeholder="名称"
                @input="this.$emit('update:name', this.selectedName)" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">説明：</span>
            <Textarea v-model="selectedDesc" rows="5" cols="30" maxlength="100" placeholder="最大100文字まで入力できます"
                @input="this.$emit('update:desc', this.selectedDesc)" />
        </div>
        <div class="px-8 mx-5 py-2">{{ (desc||'').length }} / 100</div>
        <div class="card flex justify-content-start gap-2 py-2">
            <span class="w-6rem mt-2">種別：</span>
            <Dropdown v-model="selectedLayerType" :options="layerTypes" optionLabel="name" placeholder="レイヤ種別選択" class="w-full md:w-16rem"
                :disabled="!createMode" @change="this.$emit('update:layerType', this.selectedLayerType.type)" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">最小範囲：</span>
            <InputNumber v-model="selectedZoomMin" inputId="zoomMin" :min="0" :max="20" mode="decimal" showButtons
                placeholder="ズームLV" @input="onInputZoomMin" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">最大範囲：</span>
            <InputNumber v-model="selectedZoomMax" inputId="zoomMax" :min="0" :max="20" mode="decimal" showButtons
                placeholder="ズームLV" @input="onInputZoomMax" />
        </div>
        <div class="card flex justify-content-start gap-2 py-2">
            ※レイヤ種別は作成後に変更できません
        </div>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';
    import Textarea from 'primevue/textarea';
    import Dropdown from 'primevue/dropdown';

    import DataSourceService from '@/app/service/DataSourceService';
    import LayerService from '@/app/service/LayerService';

    export default {
        components: {
            InputText,
            InputNumber,
            Textarea,
            Dropdown
        },
        props: {
            name: {
                type: String,
                default: null
            },
            desc: {
                type: String,
                default: null
            },
            layerType: {
                type: String,
                default: null
            },
            zoomMin: {
                type: Number,
                default: null
            },
            zoomMax: {
                type: Number,
                default: null
            },

            geomType: {
                type: String,
                default: null
            },
            createMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            const me = this;
            const layerTypes = [];
            const geomType = this.geomType;
            switch (geomType) {
                case DataSourceService.GT_POLYGON:
                    layerTypes.push({ type: LayerService.LT_POLYGON, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_POLYGON) });
                    layerTypes.push({ type: LayerService.LT_LABEL, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_LABEL) });
                    layerTypes.push({ type: LayerService.LT_CHOROPLETH, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_CHOROPLETH) });
                    break;
                case DataSourceService.GT_POINT:
                    layerTypes.push({ type: LayerService.LT_POINT, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_POINT) });
                    layerTypes.push({ type: LayerService.LT_LABEL, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_LABEL) });
                    layerTypes.push({ type: LayerService.LT_ICON, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_ICON) });
                    break;
                case DataSourceService.GT_LINE:
                    layerTypes.push({ type: LayerService.LT_LINE, name: this.$Service.LayerService.getLayerTypeLabel(LayerService.LT_LINE) });
                    break;
                default:
                    break;
            }
            return {
                selectedName: this.name,
                selectedDesc: this.desc,
                selectedLayerType: this.layerType ? layerTypes.filter(function(value) { return value['type'] === me.layerType; })[0] : null,
                selectedZoomMin: this.zoomMin,
                selectedZoomMax: this.zoomMax,

                layerTypes: layerTypes
            };
        },
        emits: [
            'update:name',
            'update:desc',
            'update:layerType',
            'update:zoomMin',
            'update:zoomMax'
        ],
        methods: {

            /**
             * "最小ズームLV"イベント時処理
             */
            onInputZoomMin(event) {
                this.selectedZoomMin = event.value;
                this.$emit('update:zoomMin', this.selectedZoomMin);
            },

            /**
             * "最大ズームLV"イベント時処理
             */
            onInputZoomMax(event) {
                this.selectedZoomMax = event.value;
                this.$emit('update:zoomMax', this.selectedZoomMax);
            }
        }
    };
</script>

<style scoped>
</style>