import maplibregl from "maplibre-gl";
import * as turf from '@turf/turf';
import GeometryUtils from "@/app/util/geometry-utils.mjs";

/**
 * マップデータサービス
 */
export default class MapDataService {

    /** 設定 */
    config = {
    };

    /**
     * コンストラクタ
     * @param {Object} config 設定
     */
    constructor(config={}) {
        this.config = config||[];
    }

    /**
     * レイヤー取得
     * @param {String} id ID
     * @returns {Object} レイヤー
     */
    pointsWithinPolygon(polygon, points) {
        // ポリゴン内にあるポイントを取得
        const ptsWithin = turf.pointsWithinPolygon(points, polygon);

        // ポリゴン内のポイント数を取得
        return ptsWithin.features.length;
    }

    /**
     * ポリゴン対ポリゴンで集計
     * @param {Object} polygon 集計用ポリゴン
     * @param {Array<Object>} polygons ポリゴンデータ
     * @param {Array<String>} fields 項目
     * @returns {Object} 集計データ
     */
    aggregatePolygonData(polygon, polygons, fields) {
        // ポリゴン内にあるポイントを取得
        const data = {};
        for (const field of fields) {
            data[field] = 0;
        }

        // 集計
        for (const dataPolygon of polygons.features) {
            // データ面積計算
            const targetArea = turf.area(dataPolygon);

            // 重なり部分取得
            const intersection = turf.intersect(polygon, dataPolygon);
            if (!intersection) {
                continue;
            }

            // 重なり部分の面積計算
            const intersectionArea = turf.area(intersection);
            if (intersectionArea == 0) {
                continue;
            }

            // 重なり部分の割合取得
            const overlapPercentage = (intersectionArea / targetArea);

            // 面積案分して集計
            for (const field of fields) {
                const overlapData = (dataPolygon.properties[field] * overlapPercentage);
                data[field] = data[field] + overlapData;
            }
        }

        // 丸め処理
        for (const field of fields) {
            data[field] = Number(data[field]).toFixed(6);
        }

        return data;
    }

    /**
     * ポリゴン対ポイントで集計
     * @param {Object} polygon 集計用ポリゴン
     * @param {Array<Object>} points ポイントデータ
     * @param {Array<String>} fields 項目
     * @returns {Object} 集計データ
     */
    aggregatePointData(polygon, points, fields) {
        // ポリゴン内にあるポイントを取得
        const data = {};
        for (const field of fields) {
            data[field] = 0;
        }

        // ポリゴン内のポイント取得
        const pointsWithin = turf.pointsWithinPolygon(points, polygon);

        // 集計
        for (const point of pointsWithin.features) {
            for (const field of fields) {
                data[field] = data[field] + point.properties[field];
            }
        }

        // 丸め処理
        for (const field of fields) {
            data[field] = Number(data[field]).toFixed(6);
        }

        return data;
    }

};
