import AbstractMapDrawAction from "@/app/core/mapaction/AbstractMapDrawAction.js";

/**
 * 地図作図（編集）アクションクラスです。
 * @author t.kimu
 * @since 1.0
 * @version 1.0
 * Copyright(c) 2022, Nippon Statistics Center Corp.
 */
export default class MapDrawEditAction extends AbstractMapDrawAction {

	/** 編集対象データ */
	geojson = null;
	/** 編集後コールバック */
	edited = null;

	/**
	 * コンストラクタ
	 * @param {Object} config コンフィグ
	 */
	constructor (config={}) {
		super(config);
		if (config.geojson !== undefined) {
			this.geojson = config.geojson;
		}
		if (config.edited !== undefined) {
			this.edited = config.edited;
		}
	}

	/**
	 * モードON
	 */
	on() {
		const me = this,
			drawCtrl = me.getDrawControl(),
			geojson = me.geojson;
		if (me.equalMode(me.mode.ON)) {
			return;
		}
		drawCtrl.add(geojson);
		me.setDrawMode(me.drawmode.SIMPLE_SELECT, me.mode.ON, me.edited);
	}

	/**
	 * モードOFF
	 */
	off() {
		const me = this,
			drawCtrl = me.getDrawControl();
		if (me.equalMode(me.mode.OFF)) {
			return;
		}
		me.setDrawMode(me.drawmode.SIMPLE_SELECT, me.mode.OFF);
		drawCtrl.deleteAll();
	}
}