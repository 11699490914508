/**
 * 地図アクションクラス
 * @author t.kimu
 * @since 1.0
 * @version 1.0
 * Copyright(c) 2022, Nippon Statistics Center Corp.
 */
export default class AbstractMapAction {

	map = null;

	// モード
	mode = {
		ON: 0,
		OFF: 1
	};

	_mode = null;

	/**
	 * コンストラクタ
	 * @param {Object} config コンフィグ
	 */
	constructor (config={}) {
		if (config.map !== undefined) {
			this.map = config.map;
		}
	}

	/**
	 * マップ取得
	 * @returns マップ
	 */
	getMap() {
		return this.map;
	}

	/**
	 * モード取得
	 * @returns モード
	 */
	getMode() {
		const me = this;
		return me._mode;
	}

	/**
	 * モード比較
	 * @param {Number} mode モード
	 * @returns true or false
	 */
	equalMode(mode) {
		const me = this;
		return me._mode == mode;
	}
}