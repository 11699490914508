<template>
    <div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">名称：</span>
            <InputText type="text" v-model="selectedName" placeholder="名称"
                @input="this.$emit('update:name', this.selectedName)" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">説明：</span>
            <Textarea v-model="selectedDesc" rows="5" cols="30" maxlength="100" placeholder="最大100文字まで入力できます"
                    @input="this.$emit('update:desc', this.selectedDesc)" />
        </div>
        <div class="px-8 mx-5 py-2">{{ (desc||'').length }} / 100</div>
        <div class="card flex justify-content-start gap-2 py-2">
            <span class="w-6rem mt-2">ジオメトリ：</span>
            <Dropdown v-model="selectedGeomType" :options="geomTypes" optionLabel="name" placeholder="ジオメトリ種別選択" class="w-full md:w-16rem"
                :disabled="!createMode" @change="this.$emit('update:geomType', this.selectedGeomType.type)" />
        </div>
        <div class="card flex justify-content-left gap-2 py-2">
            <span class="w-6rem mt-2">データ形式：</span>
            <Dropdown v-model="selectedDataSourceType" :options="dataSourceTypes" optionLabel="name" placeholder="データソース形式選択" class="w-full md:w-16rem"
                :disabled="!createMode" @change="this.$emit('update:dataSourceType', this.selectedDataSourceType.type)" />
        </div>
        <div class="card flex justify-content-start gap-2 py-2">
            ※「ジオメトリ」と「データ形式」は作成後に変更できません
        </div>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import Dropdown from 'primevue/dropdown';

    import DataSourceService from '@/app/service/DataSourceService';

    export default {
        components: {
            InputText,
            Textarea,
            Dropdown
        },
        props: {
            name: {
                type: String,
                default: null
            },
            desc: {
                type: String,
                default: null
            },
            geomType: {
                type: String,
                default: null
            },
            dataSourceType: {
                type: String,
                default: null
            },

            createMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            const me = this;
            const geomTypes = [
                { type: DataSourceService.GT_POLYGON, name: this.$Service.DataSourceService.getGeomTypeLabel(DataSourceService.GT_POLYGON) },
                { type: DataSourceService.GT_POINT, name: this.$Service.DataSourceService.getGeomTypeLabel(DataSourceService.GT_POINT) },
                { type: DataSourceService.GT_LINE, name: this.$Service.DataSourceService.getGeomTypeLabel(DataSourceService.GT_LINE) }
            ];
            const dataSourceTypes = [
                { type: DataSourceService.DT_FREE, name: this.$Service.DataSourceService.getDataSourceTypeLabel(DataSourceService.DT_FREE) },
                // { type: DataSourceService.DT_GEOJSON, name: this.$Service.DataSourceService.getDataSourceTypeLabel(DataSourceService.DT_GEOJSON) },
                // { type: DataSourceService.DT_GEOJSON_API, name: this.$Service.DataSourceService.getDataSourceTypeLabel(DataSourceService.DT_GEOJSON_API) },
                // { type: DataSourceService.DT_MVT, name: this.$Service.DataSourceService.getDataSourceTypeLabel(DataSourceService.DT_MVT) }
            ];
            return {
                selectedName: this.name,
                selectedDesc: this.desc,
                selectedGeomType: this.geomType ? geomTypes.filter(function(value) { return value['type'] === me.geomType; })[0] : geomTypes.filter(function(value) { return value['type'] == DataSourceService.GT_POLYGON; })[0],
                selectedDataSourceType: this.dataSourceType ? dataSourceTypes.filter(function(value) { return value['type'] === me.dataSourceType ; })[0] : dataSourceTypes.filter(function(value) { return value['type'] ===  'empty-data'; })[0],

                geomTypes: geomTypes,
                dataSourceTypes: dataSourceTypes
            };
        },
        emits: [
            'update:name',
            'update:desc',
            'update:geomType',
            'update:dataSourceType'
        ],
        methods: {
        }
    };
</script>

<style scoped>
</style>