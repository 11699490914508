<template>
    <div>
        <div class="card flex justify-content-between" v-if="createMode">
            <div class="card flex justify-content-start gap-2 py-2 px-2">
                <Button type="button" v-tooltip.bottom="'追加'" icon="pi pi-plus" @click="onAddAttribute" severity="secondary" outlined/>
                <Button type="button" v-tooltip.bottom="'削除'" icon="pi pi-minus" @click="onDeleteAttribute" severity="secondary" outlined/>
            </div>
            <div class="card flex justify-content-end gap-2 py-2">
                ※属性は作成後に変更できません
            </div>
        </div>
        <div class="card h-full">
            <DataTable :key="renderProperties" v-model:selection="selectedAttributes" :value="values" dataKey="id" editMode="cell"
                @cell-edit-complete="onAttributeCellEditComplete" class="attributes-panel-datatable" tableClass="editable-cells-table"
                scrollable scrollHeight="auto">
                <Column selectionMode="multiple" headerStyle="width: 40px" v-if="createMode"></Column>
                <Column field="key" header="属性名">
                    <template v-if="createMode" #editor="{ data, field }">
                        <InputText v-model="data[field]" :disabled="data['key'] == 'id' || data['key'] == 'name' || !createMode" />
                    </template>
                </Column>
                <Column field="type" header="データ型">
                    <template #body="{ data, field }">
                        {{ this.dataTypes.filter(function(value) { return value['type'] == data[field]; })[0].name }}
                    </template>
                    <template v-if="createMode" #editor="{ data, field }">
                        <Dropdown v-model="data[field]" :options="dataTypes" optionLabel="name" optionValue="type" placeholder="データ型選択"
                            :disabled="data['key'] == 'id' || data['key'] == 'name' || !createMode">
                            <template #option="slotProps">
                                {{ slotProps.option.name }}
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="name" header="名称">
                    <template v-if="createMode" #editor="{ data, field }">
                        <InputText v-model="data[field]" :disabled="data['key'] == 'id' || data['key'] == 'name' || !createMode" />
                    </template>
                </Column>
                <Column field="desc" header="説明">
                    <template v-if="createMode" #editor="{ data, field }">
                        <InputText v-model="data[field]" :disabled="data['key'] == 'id' || data['key'] == 'name' || !createMode" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';
    import Dropdown from 'primevue/dropdown';
    import Button from 'primevue/button';

    import DataUtils from "@/app/util/data-utils.mjs";

    export default {
        components: {
            DataTable,
            Column,
            InputText,
            Dropdown,
            Button
        },
        props: {
            attributes: {
                type: Array,
                default: function() { return []; }
            },

            createMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                values: (this.attributes && this.attributes.length > 0) ? this.attributes : [{
                    id: `${DataUtils.generateId()}0`,
                    type: 'string',
                    key: 'id',
                    name: 'ID',
                    desc: '※変更不可'
                },{
                    id: `${DataUtils.generateId()}1`,
                    type: 'string',
                    key: 'name',
                    name: '名称',
                    desc: '※変更不可'
                }],

                selectedAttributes: null,
                renderProperties: 0
            };
        },
        computed: {
            dataTypes() {
                return [
                    { name: '文字列', type: 'string' },
                    { name: '数値', type: 'number' }
                ];
            }
        },
        emits: [
            'update:attributes'
        ],
        methods: {

            /**
             * 属性追加時処理
             */
            onAddAttribute() {
                // 追加判定
                if (this.values.length >= this.$Settings.dataPropLimit) {
                    return;
                }

                // 属性名取得
                let key = null;
                for (let i = 1; i <= 1000; i++) {
                    if (!this.isDuplicate(`x${i}`)) {
                        key = `x${i}`;
                        break;
                    }
                }

                // 属性追加
                this.values.push({
                    id: DataUtils.generateId(),
                    type: 'number',
                    key: key,
                    name: key,
                    desc: ''
                });
                this.$emit('update:attributes', this.values);
                this.redrawPropertiesComponent();
            },

            /**
             * 属性削除時処理
             */
            onDeleteAttribute() {
                // 属性削除
                for (let i = this.values.length - 1; i >= 0; i--) {
                    const attribute = this.values[i];

                    // 「id」と「name」属性は消せない
                    if (attribute.key == 'id' || attribute.key == 'name') {
                        continue;
                    }

                    // 削除
                    for (const selectedAttribute of this.selectedAttributes) {
                        if (selectedAttribute.id == attribute.id) {
                            this.values.splice(i, 1);
                            break;
                        }
                    }
                }
                this.$emit('update:attributes', this.values);
                this.redrawPropertiesComponent();
            },

            /**
             * セル編集時処理
             */
            onAttributeCellEditComplete(event) {
                const { data, newValue, field } = event;
                switch (field) {
                case 'key':
                    // 属性名変更
                    {
                        if (this.isDuplicate(newValue)) {
                            event.preventDefault();
                            return;
                        }
                        data[field] = newValue;
                    }
                    break;
                case 'type':
                    // データ型変更
                    {
                        data[field] = newValue;
                    }
                    break;
                case 'name':
                    // 名称変更
                    {
                        data[field] = newValue;
                    }
                    break;
                case 'desc':
                    // 説明変更
                    {
                        data[field] = newValue;
                    }
                    break;
                default:
                    // その他
                    event.preventDefault();
                    break;
                }
                this.$emit('update:attributes', this.values);
                this.redrawPropertiesComponent();
            },

            /**
             * 属性コンポーネント再描画
             */
            redrawPropertiesComponent() {
                this.renderProperties = this.renderProperties + 1;
            },

            /**
             * @private
             * 重複判定
             */
            isDuplicate(key) {
                for (let i = 0; i < this.values.length; i++) {
                    const value = this.values[i];
                    if (key == value.key) {
                        return true;
                    }
                }
                return false;
            }
        }
    };
</script>

<style scoped>
</style>