<template>
    <Dialog modal v-bind:visible="visible" header="メモ編集" :style="{ width: '50vw', height: '80vh' }" :breakpoints="{ '960px': '60vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div style="height:54vh">
            <ExFieldset legend="中心点" :toggleable="false" class="m-2">
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">名称：</span>
                    <InputText type="text" v-model="name" placeholder="名称" />
                </div>
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">点色：</span>
                    <ColorPicker class="mt-1" v-model="color" inputId="color" format="hex" />
                </div>
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">説明：</span>
                    <Textarea v-model="desc" rows="5" cols="30" maxlength="100" placeholder="最大100文字まで入力できます" />
                </div>
                <div class="px-8 py-2">{{ (desc||'').length }} / 100</div>
            </ExFieldset>
        </div>
        <template #footer>
            <UxSeparator />
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="OK" @click="onOK" severity="secondary" outlined />
                <Button type="button" label="キャンセル" @click="onCancel" severity="secondary" outlined />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import UxSeparator from '@/ux/field/UxSeparator';
    import ExFieldset from "@/overrides/field/ExFieldset";
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import ColorPicker from 'primevue/colorpicker';
    import Button from 'primevue/button';

    export default {
        components: {
            Dialog,
            UxSeparator,
            ExFieldset,
            InputText,
            Textarea,
            ColorPicker,
            Button
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },
            selectedBuildings: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                name: 'メモ',
                desc: '',
                color: 'ff0000'
            };
        },
        watch: {
            visible(newValue) {
                if (newValue) {
                    if (this.selectedBuildings && this.selectedBuildings.length > 0) {
                        this.name = this.selectedBuildings[0].name;
                        this.desc = this.selectedBuildings[0].desc;
                        this.color = this.selectedBuildings[0].color;
                    }
                }
            }
        },
        emits: ['change-visible', 'edited'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * OK時処理
             */
            onOK() {
                // 更新内容通知
                this['$emit']('edited', {
                    name: this.name,
                    desc: this.desc,
                    color: this.color
                });
                // クローズ
                this.onVisible(false);
            },

            /**
             * キャンセル時処理
             */
            onCancel() {
                // クローズ
                this.onVisible(false);
            }
        }
    };
</script>

<style scoped>
</style>