<template>
    <div>
        <ExFieldset legend="データ" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">属性：</span>
                <Dropdown v-model="selectedProperty" :options="properties" optionLabel="name" optionValue="key"
                    placeholder="属性" class="w-8rem" @change="this.$emit('update:property', this.selectedProperty)" />
            </div>
        </ExFieldset>
        <ExFieldset legend="スタイル" :toggleable="false" class="m-2">
            <div class="card flex justify-content-left gap-2 py-2">
                <span class="w-4rem mt-2">透過率：</span>
                <Slider v-model.number="selectedFillOpacity" :min="0" :max="1" :step="0.1" orientation="horizontal" class="w-3 mt-3"
                    @change="this.$emit('update:fillOpacity', this.selectedFillOpacity)" />
                <div class="px-2 mt-2">{{ selectedFillOpacity * 100 }} %</div>
            </div>
        </ExFieldset>
        <div class="card flex justify-content-between">
            <div class="card flex justify-content-start gap-2 py-2 px-2">
                <!-- <span class="w-4rem mt-2">区分法：</span> -->
                <!-- <Dropdown v-model="selectedRankType" :options="rankTypes" optionLabel="name" placeholder="区分法" class="w-8rem" /> -->
                <span class="w-4rem mt-2">区分数：</span>
                <Dropdown v-model="selectedRankCount" :options="rankCounts" optionLabel="id" placeholder="区分数" class="w-8rem" />
                <span class="w-4rem mt-2">値間隔：</span>
                <InputNumber v-model="selectedRankInterval" placeholder="間隔" />
                <Button type="button" v-tooltip.bottom="'計算'" icon="pi pi-calculator" @click="onCalcRank" severity="secondary" outlined/>
            </div>
        </div>
        <DataTable :key="renderRanks" :value="selectedRanks" dataKey="id" editMode="cell"
            @cell-edit-complete="onRankCellEditComplete" class="choropleth-panel-datatable" tableClass="editable-cells-table"
            scrollable scrollHeight="auto">
            <Column field="color" header="色">
                <template #body="{ data, field }">
                    <ColorPicker class="mt-1" v-model="data[field]" inputId="color" format="hex" />
                </template>
                <template #editor="{ data, field }">
                    <ColorPicker class="mt-1" v-model="data[field]" inputId="color" format="hex" @change="this.$emit('update:ranks', this.selectedRanks)" />
                </template>
            </Column>
            <Column field="min" header="開始値">
                <template #editor="{ data, field }">
                    <InputNumber v-model="data[field]" @input="this.$emit('update:ranks', this.selectedRanks)" />
                </template>
            </Column>
            <Column field="max" header="終了値">
                <template #editor="{ data, field }">
                    <InputNumber v-model="data[field]" @input="this.$emit('update:ranks', this.selectedRanks)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import ExFieldset from "@/overrides/field/ExFieldset";
    import Slider from 'primevue/slider';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import ColorPicker from 'primevue/colorpicker';
    import InputNumber from 'primevue/inputnumber';
    import Dropdown from 'primevue/dropdown';
    import Button from 'primevue/button';

    export default {
        components: {
            ExFieldset,
            Slider,
            DataTable,
            Column,
            ColorPicker,
            InputNumber,
            Dropdown,
            Button
        },
        props: {
            property: {
                type: String,
                default: 'name'
            },
            fillOpacity: {
                type: Number,
                default: 0.4
            },
            ranks: {
                type: Array,
                default: function() { return []; }
            },

            dataSource: {
                type: Object,
                default: function() { return {}; }
            },
        },
        data() {
            return {
                selectedProperty: this.property,
                selectedFillOpacity: this.fillOpacity,
                selectedRanks: this.ranks,

                // selectedRankType: null,
                selectedRankCount: { id: 1 },
                selectedRankInterval: 10,

                renderRanks: 0
            };
        },
        computed: {
            properties() {
                const attrs = [];
                for (const attribute of (this.dataSource||{}).attributes) {
                    if (attribute.type != 'number') {
                        continue;
                    }
                    attrs.push(attribute);
                }
                return attrs;
            },
            // rankTypes() {
            //     return [
            //         { id: 'AVG', name: '平均均等' },
            //         { id: 'FRQ', name: '度数均等' }
            //     ];
            // },
            rankCounts() {
                const items = [];
                for (let i = 1; i <= 10; i++) {
                    items.push({ id: i });
                }
                return items;
            }
        },
        emits: [
            'update:property',
            'update:fillOpacity',
            'update:ranks'
        ],
        methods: {
            /**
             * ランク計算時処理
             */
            onCalcRank: function() {
                if (!this.selectedRankCount) {
                    return;
                }
                const ranks = [];
                const colors = this.gradient('FFFF00', 'FF0000', this.selectedRankCount.id);
                const interval = this.selectedRankInterval||0;
                for (let i = 1; i <= this.selectedRankCount.id; i++) {
                    ranks.push({ id: i, color: colors[i], min: (i - 1) * interval, max: (i) * interval });
                }
                this.selectedRanks = ranks;
                this.$emit('update:ranks', this.selectedRanks);
                this.redrawRanksComponent();
            },

            /**
             * セル編集時処理
             */
            onRankCellEditComplete(event) {
                const { data, newValue, field } = event;
                switch (field) {
                case 'color':
                    // 名
                    {
                        data[field] = newValue;
                    }
                    break;
                case 'min':
                    // 最小値
                    {
                        data[field] = newValue;
                    }
                    break;
                case 'max':
                    // 最大値
                    {
                        data[field] = newValue;
                    }
                    break;
                default:
                    // その他
                    event.preventDefault();
                    break;
                }
                this.$emit('update:ranks', this.selectedRanks);
                this.redrawRanksComponent();
            },

            /**
             * 区分コンポーネント再描画
             */
            redrawRanksComponent() {
                this.renderRanks = this.renderRanks + 1;
            },

            /**
             * グラデーション生成
             */
            gradient(startColor, endColor, steps) {
                let start = {
                    'Hex'   : startColor,
                    'R'     : parseInt(startColor.slice(0, 2), 16),
                    'G'     : parseInt(startColor.slice(2, 4), 16),
                    'B'     : parseInt(startColor.slice(4, 6), 16)
                }
                let end = {
                    'Hex'   : endColor,
                    'R'     : parseInt(endColor.slice(0, 2), 16),
                    'G'     : parseInt(endColor.slice(2, 4), 16),
                    'B'     : parseInt(endColor.slice(4, 6), 16)
                }
                let diffR = end['R'] - start['R'];
                let diffG = end['G'] - start['G'];
                let diffB = end['B'] - start['B'];

                let stepsHex  = new Array();
                let stepsR    = new Array();
                let stepsG    = new Array();
                let stepsB    = new Array();

                for(var i = 0; i <= steps; i++) {
                    stepsR[i] = start['R'] + ((diffR / steps) * i);
                    stepsG[i] = start['G'] + ((diffG / steps) * i);
                    stepsB[i] = start['B'] + ((diffB / steps) * i);
                    stepsHex[i] = '';
                    stepsHex[i] += Math.round(stepsR[i]).toString(16).padStart(2, '0');
                    stepsHex[i] += Math.round(stepsG[i]).toString(16).padStart(2, '0');
                    stepsHex[i] += Math.round(stepsB[i]).toString(16).padStart(2, '0');
                }
                return stepsHex;
            }

        }
    };
</script>

<style scoped>
</style>