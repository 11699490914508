<template>
    <Dialog modal v-bind:visible="visible" header="領域編集" :style="{ width: '50vw', height: '80vh' }" :breakpoints="{ '960px': '60vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div style="height:54vh">
            <ExFieldset legend="中心点" :toggleable="false" class="m-2">
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">名称：</span>
                    <InputText type="text" v-model="name" placeholder="名称" />
                </div>
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">点色：</span>
                    <ColorPicker class="mt-1" v-model="color" inputId="color" format="hex" />
                </div>
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">説明：</span>
                    <Textarea v-model="desc" rows="5" cols="30" maxlength="100" placeholder="最大100文字まで入力できます" />
                </div>
                <div class="px-8 py-2">{{ (desc||'').length }} / 100</div>
            </ExFieldset>
            <ExFieldset legend="領域" :toggleable="false" class="m-2">
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">領域１：</span>
                    <Dropdown v-model="zone1" :options="zoneTypes" optionLabel="name" optionValue="code" placeholder="領域１" class="w-full md:w-8rem" />
                    <ColorPicker class="mt-1" v-model="color1" inputId="color1" format="hex" />
                </div>
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">領域２：</span>
                    <Dropdown v-model="zone2" :options="zoneTypes" optionLabel="name" optionValue="code" placeholder="領域２" class="w-full md:w-8rem" />
                    <ColorPicker class="mt-1" v-model="color2" inputId="color2" format="hex" />
                </div>
                <div class="card flex justify-content-left gap-2 py-2">
                    <span class="w-4rem mt-2">領域３：</span>
                    <Dropdown v-model="zone3" :options="zoneTypes" optionLabel="name" optionValue="code" placeholder="領域３" class="w-full md:w-8rem" />
                    <ColorPicker class="mt-1" v-model="color3" inputId="color3" format="hex" />
                </div>
            </ExFieldset>
        </div>
        <template #footer>
            <UxSeparator />
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="OK" @click="onOK" severity="secondary" outlined />
                <Button type="button" label="キャンセル" @click="onCancel" severity="secondary" outlined />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import UxSeparator from '@/ux/field/UxSeparator';
    import ExFieldset from "@/overrides/field/ExFieldset";
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import ColorPicker from 'primevue/colorpicker';
    import Dropdown from 'primevue/dropdown';
    import Button from 'primevue/button';

    export default {
        components: {
            Dialog,
            UxSeparator,
            ExFieldset,
            InputText,
            Textarea,
            ColorPicker,
            Dropdown,
            Button
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },
            selectedZones: {
                type: Array,
                default: null
            }
        },
        data() {
            const zoneTypes = [];
            for (let i = 0; i <= 30; i++) {
                zoneTypes.push({
                    name: `${i}km`,
                    code: `${i}`
                });
            }
            return {
                name: '領域',
                desc: '',
                color: '0000ff',
                color1: 'ff0000',
                color2: '00ff00',
                color3: '0000ff',
                zone1: '1',
                zone2: '3',
                zone3: '5',
                zoneTypes: zoneTypes
            };
        },
        watch: {
            visible(newValue) {
                if (newValue) {
                    if (this.selectedZones && this.selectedZones.length > 0) {
                        this.name = this.selectedZones[0].name;
                        this.desc = this.selectedZones[0].desc;
                        this.color1 = this.selectedZones[0].zoneParams[0].color;
                        this.color2 = this.selectedZones[0].zoneParams[1].color;
                        this.color3 = this.selectedZones[0].zoneParams[2].color;
                        this.zone1 = this.selectedZones[0].zoneParams[0].radius;
                        this.zone2 = this.selectedZones[0].zoneParams[1].radius;
                        this.zone3 = this.selectedZones[0].zoneParams[2].radius;
                    }
                }
            }
        },
        emits: ['change-visible', 'edited'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * OK時処理
             */
            onOK() {
                // 更新内容通知
                this['$emit']('edited', {
                    name: this.name,
                    desc: this.desc,
                    color: this.color,
                    zoneParams: [
                        { color: this.color1, radius: this.zone1 },
                        { color: this.color2, radius: this.zone2 },
                        { color: this.color3, radius: this.zone3 }
                    ]
                });
                // クローズ
                this.onVisible(false);
            },

            /**
             * キャンセル時処理
             */
            onCancel() {
                // クローズ
                this.onVisible(false);
            }
        }
    };
</script>

<style scoped>
</style>