import maplibregl from "maplibre-gl";
import * as turf from '@turf/turf';
import FileUtils from "@/app/util/file-utils.mjs";
import DataUtils from "@/app/util/data-utils.mjs";
import DataSourceService from "@/app/service/DataSourceService.mjs"

/**
 * レイヤーサービス
 */
export default class LayerService {


    /** レイヤープレフィックス */
    static LAYER_PREFIX = 'layer';

    /**
     * レイヤー種別
     */
    /** ポリゴン */
    static LT_POLYGON = 'polygon';
    /** ポイント */
    static LT_POINT = 'point';
    /** ライン */
    static LT_LINE = 'line';
    /** ラベル */
    static LT_LABEL = 'label';
    /** アイコン */
    static LT_ICON = 'icon';
    /** 区分図 */
    static LT_CHOROPLETH = 'choropleth';

    /** 設定 */
    config = {
        limit: -1
    };
    /** コンポーネント */
    component = null;
    /** イベント */
    events = {};

    /** レイヤーリスト */
    /**
     * ＜属性＞
     * id: （ID）,
     * name: （名称）,
     * desc: （説明）,
     * layerType: （レイヤー種別：choropleth, plot, :ine）,
     * zoomMin: （最小ズームレベル）,
     * zoomMax: （最大ズームレベル）,
     * options: （オプション）,
     * dataSourceId: （データソースID）
     */
    layers = [];

    /**
     * コンストラクタ
     * @param {Object} config 設定
     */
    constructor(config={}) {
        this.config = config||[];
    }

    /**
     * コンポーネント取得
     * @returns {Object} コンポーネント
     */
    getComponent() {
        return this.component;
    }

    /**
     * コンポーネント設定
     * @param {Object} component コンポーネント
     */
    setComponent(component) {
        this.component = component;
    }

    /**
     * レイヤー取得
     * @param {String} id ID
     * @returns {Object} レイヤー
     */
    getLayerById(id) {
        for (const layer of this.layers||[]) {
            if (layer.id === id) {
                return layer;
            }
        }
        return null;
    }

    /**
     * レイヤー取得
     * @param {String} dataSourceId データソースID
     * @returns {Array<Object>} レイヤー
     */
    getLayersByDataSourceId(dataSourceId) {
        const layers = [];
        for (const layer of this.layers||[]) {
            if (layer.dataSourceId === dataSourceId) {
                layers.push(layer);
            }
        }
        return layers;
    }

    /**
     * レイヤーリスト取得
     * @returns {Array<Object>} レイヤーリスト
     */
    getLayers() {
        return this.layers;
    }

    /**
     * レイヤーリスト（エクスポート用）取得
     * @returns {Array<Object>} レイヤーリスト
     */
    getLayersForExport() {
        const me = this;
        const layers = me.layers;
        const datas = [];
        for (const layer of layers) {
            const data = {};
            for (const key in layer) {
                data[key] = layer[key];
            }
            datas.push(data);
        }
        return datas;
    }

    /**
     * レイヤー設定
     * @param {Object} map マップ
     * @param {Array<Object>} layers レイヤーリスト
     */
    setLayers(map, layers) {
        const me = this;
        me.removeLayers(map, me.layers);
        me.addLayers(map, layers);
    }

    /**
     * レイヤー追加
     * @param {Object} map マップ
     * @param {Object} layer レイヤー
     */
    addLayer(map, layer) {
        // サイズ制限制御
        if (this.config.limit >= 0 && this.layers.length >= this.config.limit) {
            return;
        }

        // パラメータ補正
        layer.id = layer.id||DataUtils.generateId();

        // データ追加
        this.layers.push(layer);

        // 地図レイヤ設定
        this.setMapLayer(map, layer);
    }

    /**
     * レイヤー追加
     * @param {Object} map マップ
     * @param {Array<Object>} layers レイヤーリスト
     */
    addLayers(map, layers) {
        // 空判定
        if (!layers || layers.length == 0) {
            return;
        }

        // レイヤー追加
        for (const layer of layers) {
            this.addLayer(map, layer);
        }
    }

    /**
     * レイヤー削除
     * @param {Object} map マップ
     * @param {Object} layer レイヤー
     */
    removeLayer(map, layer) {
        const records = this.layers;

        // 空判定
        if (!layer) {
            return;
        }

        // レイヤーレイヤー削除
        // this.removeLayerLayer(map, layer);

        // レイヤー削除
        for (let row = records.length - 1; row >= 0; row--) {
            const record = records[row];
            if (record.id == layer.id) {
                records.splice(row, 1);
                break;
            }
        }

        // 地図レイヤ削除
        this.removeMapLayer(map, layer);
    }

    /**
     * レイヤー削除
     * @param {Object} map マップ
     * @param {Array<Object>} layers レイヤーリスト
     */
    removeLayers(map, layers) {
        // 空判定
        if (!layers || layers.length == 0) {
            return;
        }

        // レイヤー削除
        for (let i = layers.length - 1; i >= 0; i--) {
            const layer = layers[i];
            this.removeLayer(map, layer);
        }
    }

    /**
     * データ更新
     * @param {Object} map マップ
     * @param {String} id ID
     * @param {Object} layer レイヤー
     */
    updateLayer(map, id, layer) {
        // 空判定
        if (!id || !layer) {
            return;
        }

        // レイヤー更新
        const data = this.getLayerById(id);
        for (const key in layer) {
            data[key] = layer[key];
        }

        // 地図レイヤ設定
        this.setMapLayer(map, layer);
    }

    /**
     * インポート処理
     * @param {Object} map マップ
     * @param {Object} file ファイル
     * @param {Function} handler ハンドラ
     */
    importFile(map, file, handler) {
        const me = this;

        FileUtils.importFileText(file, function(content) {
            const datas = JSON.parse(content);
            for (const data of datas) {
                me.addLayer(map, data);
            }

            // ハンドラ処理
            if (handler) {
                handler(me.layers);
            }
        });
    }

    /**
     * エクスポート処理
     */
    exportFile() {
        const datas = this.getLayersForExport();
        FileUtils.exportFileText(JSON.stringify(datas), 'layers.json');
    }

    /**
     * レイヤー種別ラベル取得
     * @param {String} layerType レイヤー種別
     * @returns {String} レイヤー種別ラベル
     */
    getLayerTypeLabel(layerType) {
        switch (layerType) {
            case LayerService.LT_POLYGON: return 'ポリゴン';
            case LayerService.LT_POINT: return 'ポイント';
            case LayerService.LT_LINE: return 'ライン';
            case LayerService.LT_LABEL: return 'ラベル';
            case LayerService.LT_ICON: return 'アイコン';
            case LayerService.LT_CHOROPLETH: return '区分図';
            default: break;
        }
        return null;
    }

    /**
     * 地図レイヤ取得
     * @param {Object} map マップ
     * @param {String} prefix レイヤIDプレフィックス
     * @returns {Array<Object>} レイヤ
     */
    getMapLayerByPrefix(map, prefix) {
        const mapLayers = map.getStyle().layers;
        let results = [];
        for (const mapLayer of mapLayers) {
            if (mapLayer.id.startsWith(prefix)) {
                results.push(mapLayer);
            }
        }
        return results;
    }

    /**
     * 地図レイヤ設定
     * @param {Object} map マップ
     * @param {Object} layer レイヤ
     */
    setMapLayer(map, layer) {
        const me = this;
        const id = layer.id;
        const sourceId = `${DataSourceService.LAYER_PREFIX}-${layer.dataSourceId}`;
        const layerType = layer.layerType;
        const options = layer.options;
        const layerIdPrefix = `${LayerService.LAYER_PREFIX}-${layerType}-${id}`;

        switch (layerType) {
            case LayerService.LT_POLYGON:
                {
                    const fillLayerId = `${layerIdPrefix}-fill`;
                    if (!map.getLayer(fillLayerId)) {
                        map.addLayer({
                            'id': fillLayerId,
                            'source': sourceId,
                            'minzoom': layer.zoomMin||0,
                            'maxzoom': layer.zoomMax||0,
                            "type": "fill",
                            "paint": {
                                "fill-color": `#${options.fillColor||'000'}`,
                                "fill-outline-color": `#${options.outlineColor||'000'}`,
                                "fill-opacity": options.fillOpacity||0.0
                            },
                            "layout": {
                                "visibility": options.visibility ? 'visible' : 'none'
                            }
                        });
                    } else {
                        map.setLayerZoomRange(fillLayerId, layer.zoomMin||0, layer.zoomMax||0);
                        map.setPaintProperty(fillLayerId, "fill-color", `#${options.fillColor||'000'}`);
                        map.setPaintProperty(fillLayerId, "fill-outline-color", `#${options.outlineColor||'000'}`);
                        map.setPaintProperty(fillLayerId, "fill-opacity", options.fillOpacity||0.0);
                        map.setLayoutProperty(fillLayerId, "visibility", options.visibility ? 'visible' : 'none');
                    }
                }
                break;
            case LayerService.LT_POINT:
                {
                    const circleLayerId = `${layerIdPrefix}-circle`;
                    if (!map.getLayer(circleLayerId)) {
                        map.addLayer({
                            'id': circleLayerId,
                            'source': sourceId,
                            'minzoom': layer.zoomMin||0,
                            'maxzoom': layer.zoomMax||0,
                            "type": "circle",
                            "paint": {
                                "circle-radius": options.size||3,
                                "circle-color": `#${options.fillColor||'000'}`,
                                "circle-stroke-width": options.outlineWidth||1,
                                "circle-stroke-color": `#${options.outlineColor||'000'}`
                            },
                            "layout": {
                                "visibility": options.visibility ? 'visible' : 'none'
                            }
                        });
                    } else {
                        map.setLayerZoomRange(circleLayerId, layer.zoomMin||0, layer.zoomMax||0);
                        map.setPaintProperty(circleLayerId, "circle-radius", options.size||3);
                        map.setPaintProperty(circleLayerId, "circle-color", `#${options.fillColor||'000'}`);
                        map.setPaintProperty(circleLayerId, "circle-stroke-width", options.outlineWidth||1);
                        map.setPaintProperty(circleLayerId, "circle-stroke-color", `#${options.outlineColor||'000'}`);
                        map.setLayoutProperty(circleLayerId, "visibility", options.visibility ? 'visible' : 'none');
                    }
                }
                break;
            case LayerService.LT_LINE:
                {
                    const lieLayerId = `${layerIdPrefix}-line`;
                    if (!map.getLayer(lieLayerId)) {
                        map.addLayer({
                            'id': lieLayerId,
                            'source': sourceId,
                            'minzoom': layer.zoomMin||0,
                            'maxzoom': layer.zoomMax||0,
                            "type": "line",
                            "paint": {
                                "line-color": `#${options.lineColor||'000'}`,
                                "line-width": options.lineWidth||1
                            },
                            "layout": {
                                "visibility": options.visibility ? 'visible' : 'none'
                            }
                        });
                    } else {
                        map.setLayerZoomRange(lieLayerId, layer.zoomMin||0, layer.zoomMax||0);
                        map.setPaintProperty(lieLayerId, "line-color", `#${options.lineColor||'000'}`);
                        map.setPaintProperty(lieLayerId, "line-width", options.lineWidth||1);
                        map.setLayoutProperty(lieLayerId, "visibility", options.visibility ? 'visible' : 'none');
                    }
                }
                break;
            case LayerService.LT_LABEL:
                {
                    const labelLayerId = `${layerIdPrefix}-label`;
                    if (!map.getLayer(labelLayerId)) {
                        map.addLayer({
                            'id': labelLayerId,
                            'source': sourceId,
                            'minzoom': layer.zoomMin||0,
                            'maxzoom': layer.zoomMax||0,
                            'type': 'symbol',
                            'paint': {
                                'text-color': `#${options.labelColor||'000'}`,
                                'text-halo-color': `#${options.labelOutlineColor||'fff'}`,
                                'text-halo-width': options.labelOutlineWidth||2
                            },
                            'layout': {
                                'text-field': ['get', options.property],
                                'text-size': options.labelSize||16,
                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                'text-justify': 'center',
                                'text-overlap': 'always',
                                'text-allow-overlap': true,
                                "text-offset": [options.xOffset||0, options.yOffset||0],
                                "visibility": options.visibility ? 'visible' : 'none'
                            }
                        });
                    } else {
                        map.setLayerZoomRange(labelLayerId, layer.zoomMin||0, layer.zoomMax||0);
                        map.setLayoutProperty(labelLayerId, "text-field", ['get', options.property]);
                        map.setPaintProperty(labelLayerId, "text-color", `#${options.labelColor||'000'}`);
                        map.setLayoutProperty(labelLayerId, "text-size", options.labelSize||16);
                        map.setPaintProperty(labelLayerId, "text-halo-color", `#${options.labelOutlineColor||'fff'}`);
                        map.setPaintProperty(labelLayerId, "text-halo-width", options.labelOutlineWidth||2);
                        map.setLayoutProperty(labelLayerId, "text-offset", [options.xOffset||0, options.yOffset||0]);
                        map.setLayoutProperty(labelLayerId, "visibility", options.visibility ? 'visible' : 'none');
                    }
                }
                break;
            case LayerService.LT_ICON:
                {
                    const iconLayerId = `${layerIdPrefix}-icon`;
                    if (!map.getLayer(iconLayerId)) {
                        map.addLayer({
                            'id': iconLayerId,
                            'source': sourceId,
                            'minzoom': layer.zoomMin||0,
                            'maxzoom': layer.zoomMax||0,
                            'type': 'symbol',
                            'layout': {
                                'icon-image': options.iconImage,
                                'icon-size': options.iconSize||1,
                                'icon-overlap': 'always',
                                'icon-allow-overlap': true,
                                "icon-offset": [options.xOffset||0, options.yOffset||0],
                                "visibility": options.visibility ? 'visible' : 'none'
                            }
                        });
                    } else {
                        map.setLayerZoomRange(iconLayerId, layer.zoomMin||0, layer.zoomMax||0);
                        map.setLayoutProperty(iconLayerId, "icon-image", options.iconImage);
                        map.setLayoutProperty(iconLayerId, "icon-size", options.iconSize||1);
                        map.setLayoutProperty(iconLayerId, "icon-offset",[options.xOffset||0, options.yOffset||0]);
                        map.setLayoutProperty(iconLayerId, "visibility", options.visibility ? 'visible' : 'none');
                    }
                }
                break;
            case LayerService.LT_CHOROPLETH:
                {
                    const fillLayerId = `${layerIdPrefix}-fill`;
                    const rankColors = [];
                    const rankOpacities = [];
                    rankColors.push('interpolate');
                    rankOpacities.push('interpolate');
                    rankColors.push(['linear']);
                    rankOpacities.push(['linear']);
                    rankColors.push(['get', options.property]);
                    rankOpacities.push(['get', options.property]);
                    if (options.ranks.length > 0) {
                        for (let i = 0; i < options.ranks.length; i++) {
                            const rank = options.ranks[i];
                            rankColors.push(Number(rank.min));
                            rankOpacities.push(Number(rank.min));
                            rankColors.push(`#${rank.color}`);
                            rankOpacities.push(options.fillOpacity||0.0);
                        }
                        rankColors.push(Number(options.ranks[options.ranks.length - 1].max));
                        rankOpacities.push(Number(options.ranks[options.ranks.length - 1].max));
                    }
                    rankColors.push('#fff');
                    rankOpacities.push(1.0);
                    if (!map.getLayer(fillLayerId)) {
                        map.addLayer({
                            'id': fillLayerId,
                            'source': sourceId,
                            'minzoom': layer.zoomMin||0,
                            'maxzoom': layer.zoomMax||0,
                            "type": "fill",
                            "paint": {
                                'fill-color': rankColors,
                                "fill-opacity": rankOpacities
                            },
                            "layout": {
                                "visibility": options.visibility ? 'visible' : 'none'
                            }
                        });
                    } else {
                        map.setLayerZoomRange(fillLayerId, layer.zoomMin||0, layer.zoomMax||0);
                        map.setPaintProperty(fillLayerId, "fill-color", rankColors);
                        map.setPaintProperty(fillLayerId, "fill-opacity", rankOpacities);
                        map.setLayoutProperty(fillLayerId, "visibility", options.visibility ? 'visible' : 'none');
                    }
                }
                break;
            default: break;
        }
    }

    /**
     * 地図レイヤ削除
     * @param {Object} map マップ
     * @param {Object} layer レイヤ
     */
    removeMapLayer(map, layer) {
        const id = layer.id;
        const layerType = layer.layerType;
        const layerIdPrefix = `${LayerService.LAYER_PREFIX}-${layerType}-${id}`;

        // レイヤーリストを取得
        const mapLayers = map.getStyle().layers;

        // レイヤー削除
        for (let i = mapLayers.length - 1; i >= 0; i--) {
            const mapLayer = mapLayers[i];
            if (mapLayer.id.startsWith(layerIdPrefix)) {
                map.removeLayer(mapLayer.id);
            }
        }
    }

    /**
     * レイヤ表示変更
     * @param {Object} map マップ
     * @param {Object} layer レイヤ
     * @param {Boolean} visible 表示フラグ
     */
    setVisibleLayer(map, layer, visible) {
        const id = layer.id;
        const layerType = layer.layerType;
        const layerIdPrefix = `${LayerService.LAYER_PREFIX}-${layerType}-${id}`;

        // レイヤーリストを取得
        const mapLayers = map.getStyle().layers;

        // レイヤー表示変更
        for (let i = mapLayers.length - 1; i >= 0; i--) {
            const mapLayer = mapLayers[i];
            if (mapLayer.id.startsWith(layerIdPrefix)) {
                map.setLayoutProperty(mapLayer.id, 'visibility', visible ? 'visible' : 'none');
            }
        }

        // レイヤー更新
        layer.visible = visible;
    }

    /**
     * 地図レイヤ削除
     * @param {Object} map マップ
     * @param {Object} layer レイヤ
     */
    refreshMapLayer(map) {
        const layers = this.layers;

        // レイヤ順序再設定
        let beforeLayerId = null;
        for (let i = layers.length - 1; i >= 0; i--) {
            const layer = layers[i];
            const layerIdPrefix = `${LayerService.LAYER_PREFIX}-${layer.layerType}-${layer.id}`;
            const mapLayers = this.getMapLayerByPrefix(map, layerIdPrefix);
            const mapLayerId = mapLayers[0].id;
            if (!beforeLayerId) {
                beforeLayerId = mapLayerId;
                continue;
            }
            map.moveLayer(mapLayerId, beforeLayerId);
        }
    }
};
