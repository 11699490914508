<template>
    <Dialog modal v-bind:visible="visible" header="地図表示" :style="{ width: '40vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <DataTable v-model:selection="selectedData" :value="data" dataKey="id"
            @rowSelect="onRowSelect" scrollable scrollHeight="auto" tableStyle="">
            <Column selectionMode="single" headerStyle="width: 3rem"></Column>
            <Column field="title" header="地図"></Column>
        </DataTable>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    import DataSourceService from '@/app/service/DataSourceService';
    import LayerService from '@/app/service/LayerService';
    import ZoneService from '@/app/service/ZoneService';
    import BuildingService from '@/app/service/BuildingService';

    export default {
        components: {
            Dialog,
            DataTable,
            Column
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedData: null,
                data: this.$MapConfig.baseLayers
            }
        },
        mounted() {
            for (const baseLayer of this.$MapConfig.baseLayers) {
                if (baseLayer.id === this.$MapConfig.selectBaseLayerId) {
                    this.selectedData = baseLayer;
                    break;
                }
            }
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * 行選択時処理
             */
            onRowSelect(event) {
                const map = this.$MapConfig.map,
                    style = map.getStyle(),
                    sources = style.sources,
                    layers = style.layers;
                function startsWithLayerPrefix(id) {
                    const layerPrefixes = [
                        DataSourceService.LAYER_PREFIX,
                        LayerService.LAYER_PREFIX,
                        ZoneService.LAYER_PREFIX,
                        BuildingService.LAYER_PREFIX,
                    ];
                    for (const layerPrefix of layerPrefixes) {
                        if (id.startsWith(layerPrefix)) {
                            return true;
                        }
                    }
                    return false;
                }
                for (const layer of layers) {
                    if (!startsWithLayerPrefix(layer.id)) {
                        continue;
                    }
                    if (map.getLayer(layer.id)) {
                        map.removeLayer(layer.id);
                    }
                }
                for (const key in sources) {
                    if (!startsWithLayerPrefix(key)) {
                        continue;
                    }
                    if (map.getSource(key)) {
                        map.removeSource(key);
                    }
                }
                map.on('styledata', function(e) {
                    const map = e.target;
                    for (const key in sources) {
                        const source = sources[key];
                        if (!startsWithLayerPrefix(key)) {
                            continue;
                        }
                        if (map.getSource(key)) {
                            continue;
                        }
                        map.addSource(key, {
                            type: source.type,
                            data: source.data
                        });
                    }
                    for (const layer of layers) {
                        if (!startsWithLayerPrefix(layer.id)) {
                            continue;
                        }
                        if (map.getLayer(layer.id)) {
                            continue;
                        }
                        map.addLayer(layer);
                    }
                });
                map.setStyle(event.data.style);
                this.$MapConfig.selectBaseLayerId = event.data.id;
            }
        }
    };
</script>

<style scoped>
</style>