import AbstractMapAction from "@/app/core/mapaction/AbstractMapAction.js";

/**
 * 地図作図アクションクラス
 * @author t.kimu
 * @since 1.0
 * @version 1.0
 * Copyright(c) 2022, Nippon Statistics Center Corp.
 */
export default class AbstractMapDrawAction extends AbstractMapAction {

	// 描画コントロール
	drawControl = null;

	// 作図モード
	drawmode = {
		DIRECT_SELECT:'direct_select',
		DRAW_LINE_STRING:'draw_line_string',
		DRAW_POINT:'draw_point',
		DRAW_POLYGON:'draw_polygon',
		DRAW_RECTANGLE:'draw_rectangle',
		DRAW_CIRCLE:'draw_circle',
		SIMPLE_SELECT:'simple_select',
		STATIC:'static'
	};

	/**
	 * コンストラクタ
	 * @param {Object} config コンフィグ
	 */
	constructor (config={}) {
		super(config);
		if (config.drawControl !== undefined) {
			this.drawControl = config.drawControl;
		}
	}

	/**
	 * 描画コントロール取得
	 * @returns 描画コントロール
	 */
	getDrawControl() {
		return this.drawControl;
	}

	/**
	 * 作図モード設定
	 * @param {String} drawmode 作図モード
	 * @param {Number} mode ON／OFF
	 * @param {Function} callback コールバック
	 */
	setDrawMode(drawmode, mode, callback) {
		const me = this,
			map = me.getMap(),
			drawCtrl = me.getDrawControl();

		// モード設定
		me._mode = mode;

		// 作図処理
		function drawHandler(e) {
			if (me.mode.OFF == me._mode) {
				return;
			}
			if (callback) {
				callback.call(me, e);
			}
		}

		// イベント設定
		switch (mode) {
			case me.mode.ON:
				{
					drawCtrl.changeMode(drawmode);
					(map._listeners['draw.create']||[]).splice(0);
					(map._listeners['draw.delete']||[]).splice(0);
					(map._listeners['draw.update']||[]).splice(0);
					map.on('draw.create', drawHandler);
					map.on('draw.delete', drawHandler);
					map.on('draw.update', drawHandler);
					// 作図用レイヤーを最上面に移動
					const layers = map.getStyle().layers||[];
					for (let i = 0; i < layers.length; i++) {
						const layer = layers[i];
						if (layer.id.startsWith('gl-draw-polygon')) {
							map.moveLayer(layer.id);
						}
					}
				}
				break;
			case me.mode.OFF:
				{
					drawCtrl.deleteAll();
					drawCtrl.trash();
					drawCtrl.changeMode(me.drawmode.SIMPLE_SELECT);
					(map._listeners['draw.create']||[]).splice(0);
					(map._listeners['draw.delete']||[]).splice(0);
					(map._listeners['draw.update']||[]).splice(0);
				}
				break;
			default:
				break;
		}
	}

	/**
	 * 作図モード判定
	 * @return {Boolean} true or false
	 */
	isDrawMode() {
		const me = this,
			drawCtrl = me.getDrawControl();
		return drawCtrl.getMode() != me.drawmode.SIMPLE_SELECT;
	}
}